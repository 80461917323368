import React, { useState, useEffect, useRef } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// const noteByTextSize = {
//   80: { title: "Weak", className: "bad" },
//   160: { title: "Average", className: "not_bad" },
//   240: { title: "Good", className: "good" },
//   320: { title: "Very Good", className: "very_good" },
//   400: { title: "Excellent", className: "excellent" },
// };

const textSizeByNote = {
  BAD: 80,
  NOT_BAD: 160,
  GOOD: 240,
  VERY_GOOD: 320,
  EXCELLENT: 400,
};

function CreateContentReview({
  textAreaId,
  onTitleChange,
  onContentChange,
  resetInput,
}) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const content = useRef(null);
  const [noteByTextSizeInternationalize, setNoteByTextSizeInternationalize] =
    useState({
      80: {
        title: "1",
        className: "bad",
      },
      160: {
        title: "2",
        className: "not_bad",
      },
      240: {
        title: "3",
        className: "good",
      },
      320: {
        title: "4",
        className: "very_good",
      },
      400: {
        title: "5",
        className: "excellent",
      },
    });

  const [noteReview, setNoteReview] = useState(null);
  const [text, setText] = useState("");
  const [note, setNote] = useState(
    noteByTextSizeInternationalize[textSizeByNote.BAD]
  );

  const [previousClassName, setPreviousClassName] = useState(
    noteByTextSizeInternationalize[textSizeByNote.BAD].className
  );

  const handleInput = (e) => {
    const text = e.target.value.trim();
    setText(text);

    const textLength = text.length;
    const currentNote = getNoteByTextSize(textLength);
    setNote(currentNote);

    if (noteReview) {
      // Vérifiez si noteReview est défini avant d'accéder à ses propriétés
      if (previousClassName === currentNote.className) return;

      noteReview.classList.remove(previousClassName);
      noteReview.classList.add(currentNote.className);
      setPreviousClassName(currentNote.className);
    }
  };

  const getNoteByTextSize = (textLength) => {
    if (textLength < textSizeByNote.BAD) {
      return noteByTextSizeInternationalize[textSizeByNote.BAD];
    }

    for (let key in textSizeByNote) {
      if (textLength < textSizeByNote[key]) {
        return noteByTextSizeInternationalize[textSizeByNote[key]];
      }
    }

    return noteByTextSizeInternationalize[textSizeByNote.EXCELLENT];
  };
  useEffect(() => {
    const textArea = document.getElementById(textAreaId);
    if (!textArea) {
      //.log(textArea);
      throw new Error(
        `The textarea element with id ${textAreaId} does not exist`
      );
    }

    const ReviewNote = document.getElementById("noteReview");

    setNoteReview(ReviewNote);

    textArea.addEventListener("input", handleInput);
    return () => textArea.removeEventListener("input", handleInput);
  }, []);
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  useEffect(() => {
    setNote(noteByTextSizeInternationalize[textSizeByNote.BAD]);
  }, [resetInput]);

  return (
    <>
      {" "}
      <p className="lead mt-3 mb-0">
        {t("AddReviewForBetaTesterWriteTitle")}
        <sup>*</sup>
      </p>
      <input
        className="form-control"
        type="text"
        id="title"
        name="title"
        required=""
        onChange={(e) => onTitleChange(e.target.value)}
      />
      <p className="lead mb-0">
        {t("AddReviewForBetaTesterWriteContent")}
        <sup>*</sup>
      </p>
      <p id="note_review" className="">
        {t("AddReviewForBetaTesterMsg3")}{" "}
        <span id="noteReview" className={note.className}>
          {t(`AddreviewMerchantContentScoreReview${note.title}`)}
        </span>{" "}
      </p>
      <textarea
        className="form-control"
        name="content"
        rows="10"
        placeholder={t("AddReviewForBetaTesterMsg4")}
        id="content"
        ref={content}
        onChange={(e) => onContentChange(e.target.value)}
      ></textarea>
    </>
  );
}

export default CreateContentReview;
