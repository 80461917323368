import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect, useRef } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import avatar from "../../assets/lorem-portrait.jpg";
import { ToastContainer, toast } from "react-toastify";

function BrandModerationComponent({ brandData, onApproveChild }) {
  const axios = useAxios();
  const [Brand_name, setBrandName] = useState(brandData.Brand_name);
  const [BrandLogo, setBrandLogo] = useState("");
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, brand_id } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    let fetchReviews = async () => {
      const response = await axios.get(`/brands/${brandData.id}`);
      setBrandName(response.data.Brand_name);
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);

    let fetchReviews = async () => {
      const response = await axios.get(`/brands/${brandData.id}`);
      setBrandName(response.data.Brand_name);
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, [brandData.id]);

  function handleApprove() {
    let fetchReviews = async () => {
      await axios.put(`/brands/${brandData.id}`, {
        status: "1",
        Brand_name: Brand_name,
        BrandLogo: BrandLogo,
      });
      toast.success("Brand updated successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };
    fetchReviews();
    onApproveChild();
    //window.location.reload();
  }

  const handleChangeBrandName = (e) => {
    setBrandName(e.target.value);
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "VeritaTrust_x2023Upload_preset_name");

    fetch(`https://api.cloudinary.com/v1_1/drbhco8al/image/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error uploading image to Cloudinary");
        }
        return response.json();
      })
      .then((data) => {
        setBrandLogo(data.secure_url);
      })
      .catch((error) => {
        console.error("Error uploading image to Cloudinary:", error);
      });
  };

  return (
    <>
      {" "}
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + brandData.id}
            aria-expanded="true"
            aria-controls={brandData.id}
          >
            {brandData.status === "0" ? (
              <i className="flaticon-time-left me-3 text-warning"></i>
            ) : (
              <i className="flaticon-check me-3 text-success"></i>
            )}
            {brandData.Brand_name}
          </button>
        </h2>
        <div
          id={brandData.id}
          className={
            brand_id
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          data-bs-parent="#accordionbrands"
        >
          <div className="accordion-body personal-data moderator">
            <div className="row">
              <div className="col-md-2 mb-3">
                <div className="userpicture">
                  <img
                    className="user-profil-avatar"
                    src={BrandLogo || brandData.BrandLogo || avatar}
                    alt="user-avatar"
                  />
                  <label className="add-visual" id="userpicture">
                    <input
                      name="userpicture"
                      accept="image/jpeg, image/webp, image/png"
                      type="file"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-7 mb-3">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="Modele"
                  onChange={handleChangeBrandName}
                  value={Brand_name}
                />
              </div>
              <div className="col-md-3 mb-3">
                <button
                  className="btn btn-success w-100 mb-2"
                  onClick={handleApprove}
                >
                  Approuve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default BrandModerationComponent;
