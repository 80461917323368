import React, { useState, useEffect } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useAxios } from "../../utils/hook/useAxios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import avatar from "../../assets/lorem-portrait.jpg";
import CreateSelectSubCategoryInput from "../CreateSelectSubCategoryInput";
import { ToastContainer, toast } from "react-toastify";

function WebshopModerationComponent({
  webshopData,
  onApproveChild,
  parentValue,
}) {
  const axios = useAxios();
  const [webshop, setWebshop] = useState(webshopData.website);
  const [productImageUrl, setProductImageUrl] = useState(webshopData.logo);
  const [categories, setCategories] = useState([]);
  const [displayApprouve, setDisplayApprouve] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([
    webshopData.category_1,
  ]);

  const [selectedCategoriesMsg, setSelectedCategoriesMsg] = useState([
    webshopData.category_1,
    webshopData.category_2,
    webshopData.category_3,
  ]);
  const [categoryCount, setCategoryCount] = useState(1);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, merchant_id } = useParams();

  useEffect(() => {
    setWebshop(webshopData.website);
    setProductImageUrl(webshopData.logo);
    setSelectedCategoriesMsg([
      webshopData.category_1,
      webshopData.category_2,
      webshopData.category_3,
    ]);
  }, [parentValue]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    window.scrollTo(0, 0);

    // Fetch category information for all selected categories
    // const fetchCategories = async () => {
    //   const categoriesInfo = await Promise.all(
    //     selectedCategoriesMsg.map((categoryId) =>
    //       axios.get(`/data/categories/get-category-id/${categoryId}`)
    //     )
    //   );
    //   setCategories(
    //     categoriesInfo.map((response) => response.data.vt_category)
    //   );
    // };
    // fetchCategories();
    const fetchCategories = async () => {
      const categoriesInfo = await Promise.all(
        selectedCategoriesMsg.map((categoryId) => {
          if (categoryId) {
            return axios.get(`/data/categories/get-category-id/${categoryId}`);
          } else {
            return Promise.resolve(null);
          }
        })
      );

      // Filtrer les résultats pour éliminer les réponses null
      const filteredCategoriesInfo = categoriesInfo.filter(
        (categoryInfo) => categoryInfo !== null
      );

      setCategories(
        filteredCategoriesInfo.map((response) => response.data.vt_category)
      );
    };

    fetchCategories();
  }, [lang, selectedCategoriesMsg]);

  const handleApprove = () => {
    const data = {
      status: "1",
      logo: productImageUrl,
    };

    // Add selected categories to the data object
    selectedCategories.forEach((category, index) => {
      data[`category_${index + 1}`] = category;
    });

    // Perform the update
    axios
      .put(`/merchant_profiles/update/${webshopData.id}`, data)
      .then(() => {
        toast.success("Webshop updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        onApproveChild();
      })
      .catch((error) => {
        console.error("Error updating webshop:", error);
      });
  };

  const handleSelectedCategory = (index, category) => {
    setSelectedCategories((prevSelectedCategories) => {
      const newSelectedCategories = [...prevSelectedCategories];
      newSelectedCategories[index] = category;
      return newSelectedCategories;
    });
    setSelectedCategoriesMsg((prevSelectedCategories) => {
      const newSelectedCategories = [...prevSelectedCategories];
      newSelectedCategories[index] = category;
      return newSelectedCategories;
    });
  };

  const addCategory = () => {
    if (categoryCount < 3) {
      setCategoryCount(categoryCount + 1);
      setSelectedCategories((prevSelectedCategories) => [
        ...prevSelectedCategories,
        "",
      ]);
      setSelectedCategoriesMsg((prevSelectedCategories) => [
        ...prevSelectedCategories,
        "",
      ]);
    }
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "VeritaTrust_x2023Upload_preset_name");

    fetch(`https://api.cloudinary.com/v1_1/drbhco8al/image/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error uploading image to Cloudinary");
        }
        return response.json();
      })
      .then((data) => {
        setProductImageUrl(data.secure_url);
      })
      .catch((error) => {
        console.error("Error uploading image to Cloudinary:", error);
      });
  };

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + webshopData.id}
            aria-expanded="true"
            aria-controls={webshopData.id}
          >
            {webshopData.status === "0" ? (
              <i className="flaticon-time-left me-3 text-warning"></i>
            ) : (
              <i className="flaticon-check me-3 text-success"></i>
            )}
            {webshopData.website}
          </button>
        </h2>
        <div
          id={webshopData.id}
          className={
            merchant_id
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          data-bs-parent="#accordionbrands"
        >
          <div className="accordion-body personal-data moderator">
            <div className="row">
              <div className="col-md-2 mb-3">
                <div className="userpicture">
                  <img
                    className="user-profil-avatar"
                    src={productImageUrl || webshopData.logo || avatar}
                    alt="user-avatar"
                  />
                  <label className="add-visual" id="userpicture">
                    <input
                      name="userpicture"
                      accept="image/jpeg, image/webp, image/png"
                      type="file"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-7 mb-3">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="Modele"
                  onChange={(e) => setWebshop(e.target.value)}
                  value={webshop}
                />

                {displayApprouve === true &&
                  selectedCategories.map((category, index) => (
                    <div key={index}>
                      <p className="mb-1">Select Category {index + 1}</p>
                      <select
                        className="form-select"
                        aria-label={`Category ${index + 1}`}
                        value={category}
                        onChange={(e) =>
                          handleSelectedCategory(index, e.target.value)
                        }
                      >
                        <option value="1" data-category-id="1">
                          {t("AddReviewForBetaTesterCategoryAnimals")}
                        </option>
                        <option value="412" data-category-id="412">
                          {t("AddReviewForBetaTesterCategoryFoodsAndDrunk")}
                        </option>
                        <option value="141" data-category-id="141,222,2092">
                          {t("AddReviewForBetaTesterCategoryHighTech")}
                        </option>
                        <option value="436" data-category-id="436,536,642">
                          {t("AddReviewForBetaTesterCategoryHomeDIY")}
                        </option>
                        <option value="783" data-category-id="783">
                          {t("AddReviewForBetaTesterCategoryCulture")}
                        </option>
                        <option value="537" data-category-id="537">
                          {t("AddReviewForBetaTesterCategoryChilcare")}
                        </option>
                        <option value="111" data-category-id="111,922">
                          {t("AddReviewForBetaTesterCategoryCompanies")}
                        </option>
                        <option value="8" data-category-id="8,772,988">
                          {t("AddReviewForBetaTesterCategorySportLeisure")}
                        </option>
                        <option value="1239" data-category-id="1239">
                          {t("AddReviewForBetaTesterCategoryVideogames")}
                        </option>
                        <option value="888" data-category-id="888">
                          {t("AddReviewForBetaTesterCategoryVehicles")}
                        </option>
                        <option value="469" data-category-id="469">
                          {t("AddReviewForBetaTesterCategoryHealthBeauty")}
                        </option>
                        <option value="166" data-category-id="166,5181">
                          {t("AddReviewForBetaTesterCategoryFashionAccess")}
                        </option>
                      </select>
                      <CreateSelectSubCategoryInput
                        handleSelectSubCategory={(subCategory) => {
                          // Handle sub-category selection for the corresponding category
                        }}
                        category_parent_idSelected={category}
                      />
                    </div>
                  ))}

                {displayApprouve === true && categoryCount < 3 && (
                  <button
                    className="btn btn-success mb-2"
                    onClick={addCategory}
                  >
                    Add Category {categoryCount + 1}
                  </button>
                )}
                {displayApprouve === false && (
                  <div>
                    <p>
                      <span className="badge text-bg-primary me-2">
                        {categories[0]}
                      </span>

                      <span className="badge text-bg-primary me-2">
                        {categories[1]}
                      </span>

                      <span className="badge text-bg-primary">
                        {categories[2]}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="col-md-3 mb-3">
                {displayApprouve === false && (
                  <button
                    className="btn btn-primary w-100 mb-2"
                    onClick={() => {
                      setDisplayApprouve(true);
                    }}
                  >
                    Edit
                  </button>
                )}
                {displayApprouve === true && (
                  <>
                    <button
                      className="btn btn-danger w-100 mb-2"
                      onClick={() => {
                        setDisplayApprouve(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success w-100 mb-2"
                      onClick={handleApprove}
                    >
                      Approuve
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default WebshopModerationComponent;
