import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { useAxios } from "../../utils/hook/useAxios";

function NotificationItem({ data }) {
  const axios = useAxios();

  const handleClick = async () => {
    // Supprimer l'entrée correspondante dans la table Follow
    await axios.put(`/notification/view/${data.id}`, {
      isViewed: "1",
    });
  };

  return (
    <>
      <div
        className={
          data.isViewed === "0"
            ? "d-flex flex-column border-top py-3 position-relative"
            : "d-flex flex-column border-top py-3 position-relative text-secondary"
        }
      >
        <span>
          <strong>{data.notification_type}</strong> -{" "}
          <Link
            to={
              data.notification_type === "following"
                ? "/account/community/"
                : "/account/reviews/"
            }
            className="lien_global"
            onClick={handleClick}
          >
            {data.message}
          </Link>
        </span>
        <span>{moment(data.createdAt).startOf("hour").fromNow()}</span>
        {data.isViewed === "0" && (
          <span className="position-absolute top-50 end-0 p-2 bg-success rounded-circle translate-middle">
            <span className="visually-hidden">New alerts</span>
          </span>
        )}
      </div>
    </>
  );
}

export default NotificationItem;
