import moment from "moment";
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function SupportMessageItem({ propsData, ...props }) {
  const axios = useAxios();
  const [user, setUser] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [responseReport, setResponseReport] = useState("");

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const levels = ["Newbie", "Bronze", "Gold", "Platinum"];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Empêche la soumission par défaut du formulaire

    const VeritaTrustUserData = await axios.get("/admin");

    try {
      const messages = await axios.post("/supportmessages", {
        issueType: "other",
        subject: "Reporting with support",
        status: "0",
        message: responseReport,
        reportReviewId: propsData.reportReviewId,
        userId: propsData.userId,
        isWritingBy: "user",
      });

      const response = await axios.get(
        "/reportreview/responses/" + propsData.id
      );

      if (response) setResponseData(response.data);
    } catch (error) {
      // Gérer les erreurs éventuelles
      console.error(error);
    }
  };
  const handleChange = (event) => {
    setResponseReport(event.target.value);
    console.log(event.target.value); // Mettre à jour la valeur de la réponse dans le state
  };

  return (
    <>
      <tr
        class={
          propsData.status == "0"
            ? "aos animated pending aos-init aos-animate"
            : "aos animated finish aos-init aos-animate"
        }
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        variant="primary"
        onClick={handleShow}
      >
        <td data-label="Date" class="info-table" scope="row">
          {moment(propsData.createdAt).calendar()}
        </td>
        <td
          data-label="Type"
          class="info-table support__openner"
          data-bs-toggle="collapse"
          data-bs-target="#lorem"
          aria-expanded="false"
          aria-controls="lorem"
        >
          {propsData.subject}
        </td>
        <td data-label="Amount" class="info-table claim-mount">
          {propsData.message}
        </td>
        <td data-label="Statut" class="info-table claim-statut">
          <span class="state"></span>
        </td>
      </tr>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Reporting message</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form action="" onSubmit={handleSubmit}>
            <div class="d-md-flex gap-3 mb-3">
              <textarea
                class="form-control"
                id="message"
                rows="2"
                placeholder="Your response"
                value={responseReport}
                onChange={handleChange}
              ></textarea>
              <button type="submit" class="btn btn-primary mt-2 mt-md-0">
                Submit
              </button>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SupportMessageItem;
