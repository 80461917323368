import { useEffect, useState } from "react";
import "../../styles/style.css";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function UserBadge({ data, dataLenght }) {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const NEWBIE = 5;
  const BRONZE = 20;
  const SILVER = 50;
  const GOLD = 99;
  //const PLATINUM = 150;
  const [reviewNumberNextLevel, setReviewNumberNextLevel] = useState();

  useEffect(() => {
    switch (data.levelAccount) {
      case 0:
        setReviewNumberNextLevel(NEWBIE - data.approuvedRaward);
        break;
      case 1:
        setReviewNumberNextLevel(BRONZE - data.approuvedRaward);
        break;
      case 2:
        setReviewNumberNextLevel(SILVER - data.approuvedRaward);
        break;
      case 3:
        setReviewNumberNextLevel(GOLD - data.approuvedRaward);
        break; // il te reste reviewNumberNexLevel pour atteindre le niveau supérieur
      default:
        setReviewNumberNextLevel(0);
    }
  });
  let levelname = "Newbie";
  let levelValueClass = "value level level" + data.levelAccount.toString();
  let levelClass = "";

  switch (data.levelAccount) {
    case 0:
      levelname = "Newbie";
      break;
    case 1:
      levelname = "Bronze";
      break;
    case 2:
      levelname = "Silver";
      break;
    case 3:
      levelname = "Gold";
      break;
    case 4:
      levelname = "Platinum";
      break;
    default:
  }

  switch (data.levelAccount) {
    case 0:
      levelClass = "level0";
      break;
    case 1:
      levelClass = "level1";
      break;
    case 2:
      levelClass = "level2";
      break;
    case 3:
      levelClass = "level3";
      break;
    case 4:
      levelClass = "level4";
      break;
    default:
  }

  let levelnames = ["Newbie", "Bronze", "Silver", "Gold", "Platinum"];
  let levelClasses = ["level0", "level1", "level2", "level3", "level4"];

  return (
    <>
      <div
        className="badges mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="d-flex justify-content-between">
          <div className="title">
            <p className="lead me-auto">{t("AccountBadgesTitle")}</p>
            {/* <p>
              Congratulations, you're a{" "}
              <strong>
                <span
                  className={data.totalReward == 0 ? "level0" : levelClass}
                  id="level-name"
                >
                  {data.totalReward == 0 ? "Newbie" : levelname} Influencer
                </span>
              </strong>
              !
            </p> */}
            <p>
              <Trans
                i18nKey="AccountBadgesMessageTop"
                values={{
                  LevelInfluencer: data.totalReward == 0 ? "Newbie" : levelname,
                }}
                components={{
                  1: (
                    <strong>
                      <span
                        className={
                          data.totalReward === 0 ? "level0" : levelClass
                        }
                        id="level-name"
                      ></span>
                    </strong>
                  ),
                }}
              />
            </p>
          </div>
          <div className="check-challenge">
            <a className="btn btn-success mb-3" href="#challenge">
              {t("AccountBadgesButton")}
            </a>
          </div>
        </div>
        <div className="badges row">
          <div className="col-6 col-md mb-3">
            <div
              className={
                data.levelAccount === 0 && dataLenght > 0
                  ? "card text-center d-flex flex-column level0 actual"
                  : "card text-center d-flex flex-column level0"
              }
            >
              <div className="icone">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="h5 card__title">Newbie</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                1 {t("AccountBadgesReview")}
              </p>
            </div>
          </div>
          <div className="col-6 col-md mb-3">
            <div
              className={
                data.levelAccount == 1
                  ? "card text-center d-flex flex-column level1 actual"
                  : "card text-center d-flex flex-column level1"
              }
            >
              <div className="icone ">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="h5 card__title">Bronze</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                15 {t("AccountBadgesReview")}
              </p>
            </div>
          </div>
          <div className="col-6 col-md mb-3">
            <div
              className={
                data.levelAccount == 2
                  ? "card text-center d-flex flex-column level2 actual"
                  : "card text-center d-flex flex-column level2"
              }
            >
              <div className="icone ">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="h5 card__title">Silver</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                20 {t("AccountBadgesReview")}
              </p>
            </div>
          </div>
          <div className="col-6 col-md mb-3">
            <div
              className={
                data.levelAccount == 3
                  ? "card text-center d-flex flex-column level3 actual"
                  : "card text-center d-flex flex-column level3"
              }
            >
              <div className="icone ">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="h5 card__title">Gold</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                50 {t("AccountBadgesReview")}
              </p>
            </div>
          </div>
          <div className="col-6 col-md mb-3">
            <div
              className={
                data.levelAccount == 4
                  ? "card text-center d-flex flex-column level4 actual"
                  : "card text-center d-flex flex-column level4"
              }
            >
              <div className="icone ">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="h5 card__title">Platinum</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                100 {t("AccountBadgesReview")}
              </p>
            </div>
          </div>
        </div>
        {/* <p>
          Every month, we airdrop bonuses to our{" "}
          <span className="text-success">best influencers</span>.{" "}
          {data.levelAccount < 4 && (
            <>
              Want to level up to{" "}
              <span
                className={
                  data.totalReward == 0
                    ? "level1"
                    : levelClasses[data.levelAccount + 1]
                }
                id="level-next"
              >
                {data.totalReward == 0
                  ? "Bronze"
                  : levelnames[data.levelAccount + 1]}{" "}
                Influencer
              </span>
              ? Just write{" "}
              <strong className="text-success" id="review-privilege">
                {reviewNumberNextLevel}
              </strong>{" "}
              more reviews and get new privileges!
            </>
          )}
        </p> */}
        <p>
          {" "}
          <Trans
            i18nKey="AccountBadgesMessageBottom"
            values={{
              LevelUpInfluencer:
                data.totalReward === 0
                  ? "Bronze"
                  : levelnames[data.levelAccount + 1],
              LevelUpNumberReviews: reviewNumberNextLevel,
            }}
            components={{
              1: <span className="text-success"></span>,
              2: (
                <span
                  className={
                    data.totalReward == 0
                      ? "level1"
                      : levelClasses[data.levelAccount + 1]
                  }
                  id="level-next"
                ></span>
              ),
              3: (
                <strong className="text-success" id="review-privilege"></strong>
              ),
            }}
          />
        </p>
      </div>
    </>
  );
}

export default UserBadge;
