import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
import NotificationComponent from "../NotificationComponent";

import io from "socket.io-client";

function NavList() {
  const location = useLocation();
  const [notif, setNotif] = useState(false);
  const axios = useAxios();

  const [notifData, setNotifData] = useState([]);
  const [followersNb, setFollowersNb] = useState(0);
  const [lastDate, setLastDate] = useState(null);

  const [reviewsOnchainNumber, setReviewsOnchainNumber] = useState(0);
  const [lastDateOnchain, setLastDateOnchain] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  let socket;

  useEffect(() => {
    i18n.changeLanguage(lang);

    let fetchReviews = async () => {
      const response = await axios.get("/");

      if (
        response.data.nickname &&
        response.data.first_name &&
        response.data.last_name &&
        response.data.dateNaissance &&
        response.data.localAdress &&
        response.data.phoneNumber &&
        response.data.zip &&
        response.data.city &&
        response.data.country &&
        response.data.currency
      ) {
        setNotif(false);
      }
      socket = io("https://api.veritatrust.com"); // Remplacez par l'URL correcte

      // Vérifier si la connexion WebSocket est établie
      socket.on("connect", () => {
        console.log("WebSocket Client Connected successfully lorem ipsum ");
        console.log("Socket ID:", socket.id);
        socket.emit("register", response.data["id"]);
        // Écouter l'événement "notification" pour recevoir les notifications
      });

      socket.on("notification", (notification) => {
        setNotifData((prevNotifications) => [
          notification[0],
          ...prevNotifications,
        ]);
        console.log(notification);
      });

      const responseNotif = await axios.get(
        "/notification/" + response.data.id
      );

      if (responseNotif) {
        setNotifData(responseNotif.data);
        // Filter the data based on the notiftype
        const filteredData = responseNotif.data
          .filter((item) => item.notification_type === "following")
          .filter((item) => item.isViewed === "0");

        // Vérifiez si filteredData a au moins un élément avant d'accéder au dernier élément
        if (filteredData.length > 0) {
          // Mettez à jour le nombre de followers avec la longueur des données filtrées
          setFollowersNb(filteredData.length);

          // Mettez à jour la dernière date avec la date du dernier élément
          setLastDate(filteredData[filteredData.length - 1].createdAt);
        } else {
          // Si filteredData est vide, vous pouvez choisir de traiter cela d'une manière particulière
          // Par exemple, réinitialisez le nombre de followers et la dernière date
          setFollowersNb(0);
          setLastDate(null); // Ou toute autre valeur que vous considérez comme appropriée
        }

        const filteredDataOnchain = responseNotif.data
          .filter((item) => item.notification_type === "emailing")
          .filter((item) => item.isViewed === "0");

        if (filteredDataOnchain.length > 0) {
          setReviewsOnchainNumber(filteredDataOnchain.length);
          setLastDateOnchain(filteredData[filteredData.length - 1].createdAt);
        }
      }
    };
    fetchReviews();
    // Nettoyer les événements WebSocket lors du démontage du composant
    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("notification");
        socket.disconnect();
      }
    };
  }, []);

  return (
    <div
      className="sticky-top bg-white p-3 mb-3 rounded-5 shadow widget enterprise aos animated aos-init aos-animate"
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
    >
      {/* {notif === true && (
        <>
          <div className="notifications">
            <h3 className="lead">Notifications</h3>
            <div className="notification bg-primary text-white">
              <span className="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span className="visually-hidden">New alerts</span>
              </span>
              <div className="d-flex justify-content-between">
                <p>
                  <Link to="/account/community/" className="text-white">
                    <strong>Complete your profile</strong>
                  </Link>
                </p>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  aria-label="Close"
                ></button>
              </div>
              <p className="text-muted">You must complete your profile</p>
            </div>
          </div>
        </>
      )} */}
      {
        <div class="notifications mb-3">
          <div class="dropdown-center">
            <button
              className="btn btn-primary dropdown-toggle w-100 position-relative"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Notification
              <span class="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span class="visually-hidden">New notification</span>
              </span>
            </button>
            <ul className="dropdown-menu w-100 p-0 shadow">
              {" "}
              {followersNb > 0 && (
                <NotificationComponent
                  propsNotifDate={lastDate}
                  propsNumberFollower={followersNb}
                  propsTypeNotif="Following"
                  propsContent="You have news followers"
                />
              )}
              {reviewsOnchainNumber > 0 && (
                <NotificationComponent
                  propsNotifDate={lastDateOnchain}
                  propsNumberFollower={reviewsOnchainNumber}
                  propsTypeNotif="Reviews on blockchain"
                  propsContent="You have news reviews in blockchain"
                />
              )}
            </ul>
          </div>
        </div>
      }
      <nav>
        <h3 className="lead">Navigation</h3>
        <ul className="list-unstyled user-menu">
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account` ? "active" : ""
              }
              to={`/${t("lang")}/account`}
            >
              {t("AccountDashboard")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/settings`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/settings`}
            >
              {t("AccountProfile")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/rewards`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/rewards`}
            >
              {t("AccountRewards")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/reviews`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/reviews`}
            >
              {t("AccountReviews")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/community`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/community`}
            >
              {t("AccountCommunity")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/badges`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/badges`}
            >
              {t("AccountBadges")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/support`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/support`}
            >
              {t("AccountSupport")}
            </Link>
          </li>

          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/notifications`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/notifications`}
            >
              {t("AccountNotifications")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/account/faq`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/faq`}
            >
              {t("AccountFAQ")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname.includes(`/${t("lang")}/account/vouchers`)
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/account/vouchers`}
            >
              {t("AccountVouchers")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default NavList;
