import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";

function FaqItem({ propsItem, propsIndex, propsId }) {
  return (
    <>
      <button
        className="accordion-button p-3 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + propsId + propsIndex}
        aria-expanded="false"
        aria-controls={propsId + propsIndex}
      >
        <header>
          <h5 className="mb-1">{propsItem.question}</h5>
          <p className="mb-1">{propsItem.answer.slice(0, 20)}.</p>
          <p className="mb-0 btn btn-primary btn-sm">Learn more</p>
        </header>
      </button>
      <div
        id={propsId + propsIndex}
        className="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent={"#" + propsId}
      >
        <div className="accordion-body">
          <p>{propsItem.answer}</p>
        </div>
      </div>
    </>
  );
}

export default FaqItem;
