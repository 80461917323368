import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CreateWebshopInput from "../../components/CreateWebshopInput";
import DashboardDivAdmin from "../../components/DashboardDivAdmin";
import NavListAdmin from "../../components/NavlistAdmin";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

function SubscriptionsModal(props) {
  const [webshop, setWebshop] = useState(null);
  const [merchantID, setmerchantID] = useState(null);

  const handleWebshopSelection = (webshop) => {
    setWebshop(webshop.website);
    setmerchantID(webshop.id);
  };

  const [paymentLink, setPaymentLink] = useState("");
  const [amount, setAmount] = useState(0);

  const createProductAndPrice = async () => {
    const stripeSecretKey =
      "sk_test_51NkmmiEcnL0rILcw6efLdctPVKtl7LIyweqzpfXJvwZQpcLU1E1IPlXNvtz5IETOoob7Wm0YHGxk6bmHZZOkpu5G00V1LDfuua"; // Remplacez par votre clé secrète Stripe

    try {
      // Créer le produit
      const productResponse = await axios.post(
        "https://api.stripe.com/v1/products",
        new URLSearchParams({ name: "Customed Plan" }),
        {
          headers: {
            Authorization: `Bearer ${stripeSecretKey}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(productResponse);

      const productId = productResponse.data.id;

      // Créer le prix associé au produit
      const priceResponse = await axios.post(
        "https://api.stripe.com/v1/prices",
        new URLSearchParams({
          currency: "usd",
          unit_amount: amount * 100, // le prix en cents (1000 = $10.00)
          product: productId,
        }),
        {
          headers: {
            Authorization: `Bearer ${stripeSecretKey}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log("prices ", priceResponse);

      const priceId = priceResponse.data.id;

      // Créer le lien de paiement
      const paymentLinkResponse = await axios.post(
        "https://api.stripe.com/v1/payment_links",
        new URLSearchParams({
          "line_items[0][price]": priceId,
          "line_items[0][quantity]": 1,
        }),
        {
          headers: {
            Authorization: `Bearer ${stripeSecretKey}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const paymentLinkUrl = paymentLinkResponse.data.url;
      console.log("url data paiement ", paymentLinkResponse);

      console.log("url ", paymentLinkUrl);

      // Mettre à jour l'état avec le lien de paiement
      setPaymentLink(paymentLinkUrl);
    } catch (error) {
      console.log(
        "Error creating product and price:",
        error.response ? error.response.data : error.message
      );
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="h3">
            <strong> Add a new subscription plan</strong>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
        <CreateWebshopInput handleSelectWebshop={handleWebshopSelection} />
        <input
          class="form-control mb-2"
          type="text"
          placeholder="Amount"
          disabled=""
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
        />
        <div class="input-group">
          <button
            class="btn btn-outline-secondary"
            type="button"
            id="button-copy"
          >
            Copy
          </button>
          <input
            type="text"
            class="form-control"
            id="paymentUrl"
            placeholder="Payment url"
            readonly=""
            value={paymentLink}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-success float-end"
          onClick={createProductAndPrice}
        >
          Save subscription
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function AdminSubscriptions() {
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [allTransactions, setAllTransactions] = useState([]);
  const [Message, setMessage] = useState(["Moderated", "To moderate"]);

  const [visibleTransactions, setVisibleTransactions] = useState(20); // Nombre de transactions initialement visibles
  const [searchTerm, setSearchTerm] = useState(""); // État pour stocker l'ID de transaction recherché
  const loadMore = () => {
    setVisibleTransactions(
      (prevVisibleTransactions) => prevVisibleTransactions + 5
    ); // Charger plus de transactions (par exemple, 5 de plus)
  };

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <DashboardDivAdmin titlepage={t("AccountDashboard")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavListAdmin></NavListAdmin>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <button
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => setModalShow(true)}
                    >
                      Add new subscription plan
                    </button>
                    <SubscriptionsModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminSubscriptions;
