import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import DashboardDivAdmin from "../../components/DashboardDivAdmin";
import NavListAdmin from "../../components/NavlistAdmin";
import ReportedReviewItem from "../../components/ReportedReviewItem";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ReportedReviewPage() {
  const axios = useAxios();

  const [parentState, setParentState] = useState(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [allTransactions, setAllTransactions] = useState([]);
  const [allTransactionsFiltred, setAllTransactionsFiltred] = useState([]);
  let socket;

  const [isModerateChecked, setIsModerateChecked] = useState(false);

  const [visibleTransactions, setVisibleTransactions] = useState(2); // Nombre de transactions initialement visibles
  const [searchTerm, setSearchTerm] = useState("");
  const data = {
    id: 3,
    issueType: "other",
    subject: "Reporting with support",
    message: "Cher client, Merci pour votre signalement",
    image: null,
    status: "0",
    reportReviewId: 9,
    userId: 51,
    createdAt: "2024-01-22T10:31:11.000Z",
    updatedAt: "2024-01-22T10:31:11.000Z",
  };
  // État pour stocker l'ID de transaction recherché

  const loadMore = () => {
    setVisibleTransactions(
      (prevVisibleTransactions) => prevVisibleTransactions + 5
    ); // Charger plus de transactions (par exemple, 5 de plus)
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
    let fetchReviews = async () => {
      socket = io("https://api.veritatrust.com"); // Remplacez par l'URL correcte

      const resp = await axios.get("/reportreview");
      setAllTransactions(resp.data.sort((a, b) => b.id - a.id));
      setAllTransactionsFiltred(resp.data.sort((a, b) => b.id - a.id));
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DashboardDivAdmin titlepage={t("AccountDashboard")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavListAdmin></NavListAdmin>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div>
                    <div
                      id="accordionbrands"
                      className="accordion accordion-flush"
                    >
                      {allTransactions
                        .slice(0, visibleTransactions)
                        .map((report) => (
                          <ReportedReviewItem
                            propsData={report}
                          ></ReportedReviewItem>
                        ))}
                    </div>

                    {visibleTransactions < allTransactions.length && (
                      <div className="d-grid gap-2 col-md-4 mx-auto">
                        <button
                          className="btn btn-primary text-white"
                          onClick={loadMore}
                        >
                          Load More reviews
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReportedReviewPage;
