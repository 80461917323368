import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AboutMerchant from "../../components/AboutMerchant";
import Filtering from "../../components/Filtering";
import MerchantDiv from "../../components/MerchantDiv";
import ReviewDiv from "../../components/ReviewDiv";
import WriteReviewDiv from "../../components/WriteReviewDiv";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ReviewsPage() {
  const axios = useAxios();

  let { website } = useParams();
  const [stars, setStars] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setStars(params.get("stars"));
  }, []);
  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState("");
  useEffect(() => {
    let fetchReviews = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ratings = urlParams.get("stars");
      //urlParams+= `?stars=${ratings}`;

      axios
        .get(
          "/merchant-review/4f6750685-6ee7-49dd-b9e8-1f204b13db6a" +
            `?stars=${ratings}`
        )
        .then((response) => {
          setReviews(response.data);
        })
        .catch((error) => {
          // console.error(error);
        });
    };
    fetchReviews();
  }, [ratings]);

  return (
    <>
      <MerchantDiv rm="3.8" />

      <section className="bg-success profil-main">
        <div className="container py-2 py-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <WriteReviewDiv></WriteReviewDiv>
              <Filtering onFilterChange={(ratings) => setRatings(ratings)} />

              <div className="reviews-list">
                <div>
                  {reviews &&
                    Array.from(reviews).map((review, index) => (
                      <ReviewDiv key={index} data={review} />
                    ))}
                </div>
              </div>
            </div>

            <div className="col-lg-4 text-end">
              <AboutMerchant
                title="About www.fatasoft-consulting.com"
                description="We offer all the shoes and accessories you want, a wide range of fashionable products, and the best brands of the moment. A gigantic choice: Guess, Tommy Hilfiger, Ecco, Geox, Pepe Jeans,
                                 Clarks, Gino Rossi and many more... Shoes, bags, wallets and belts – all available to you 24/7. We are always here for you!"
                country="France"
                category="Services"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewsPage;
