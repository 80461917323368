import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";
import NavCategorieItem from "../NavCategoriesItem";

function NavCategories({
  proposId,
  categoryParentId,
  nameCat,
  ParentClicked,
  setParentClicked,
}) {
  const axios = useAxios();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const categorydata = location.pathname;
  const { category } = useParams();

  let categoryId = 0;

  if (category) categoryId = categorydata.split("/")[2].split("-")[0];

  const findParent = (categories, child) => {
    return categories.some((category) => {
      if (category.name === child.direct_parent_name) {
        category.children.push(child);
        return true;
      }
      if (category.children) {
        return findParent(category.children, child);
      }
      return false;
    });
  };

  useEffect(() => {
    axios.get("/data/categories/" + categoryParentId).then((res) => {
      // Create a tree of categories
      const categories = res.data.reduce((acc, category) => {
        category.children = [];
        if (category.level === 0) {
          acc.push(category);
        } else if (category.level <= 2) {
          findParent(acc, category);
        }
        return acc;
      }, []);
      setCategories(categories);
      setIsLoading(false);
    });
  }, []);

  const handleClick = (e) => {
    setParentClicked(categoryParentId);
  };
  return (
    <li>
      <a
        class=""
        data-bs-toggle="collapse"
        href={"#" + proposId}
        role="button"
        aria-expanded="true"
        aria-controls={proposId}
        onClick={handleClick}
      >
        {nameCat}
      </a>

      <ul
        className={
          ParentClicked == categoryParentId
            ? "content collapse sub-category list-unstyled"
            : "d-none"
        }
        id={proposId}
      >
        <>
          {categories.map((cat, index) => {
            return (
              <NavCategorieItem
                {...cat}
                key={`${index}-${cat.name}`}
                isActive={category?.split("-")[0] === `${cat.id}`}
              />
            );
          })}
        </>
      </ul>
    </li>
  );
}

export default NavCategories;
