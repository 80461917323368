import React from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import add_image from "../../assets/add_image.webp";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
//import SibApiV3Sdk  from 'sib-api-v3-sdk';
//import {  SibApiV3Sdk } from 'sendinblue-api';
import NoPicture from "../../assets/no-picture.png";

import { Trans, useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

import { useEffect, useRef, useState } from "react";

const noteByTextSize = {
  80: { title: "Weak", titleFr: "Passable", className: "bad" },
  160: { title: "Average", titleFr: "Moyen", className: "not_bad" },
  240: { title: "Good", titleFr: "Bien", className: "good" },
  320: { title: "Very Good", titleFr: "Très bien", className: "very_good" },
  400: { title: "Excellent", titleFr: "Excellent", className: "excellent" },
};

const textSizeByNote = {
  BAD: 80,
  NOT_BAD: 160,
  GOOD: 240,
  VERY_GOOD: 320,
  EXCELLENT: 400,
};

function ProductReviewForm({ dataindex, textAreaId }) {
  const { t, i18n } = useTranslation();

  const [text, setText] = useState("");
  const [note, setNote] = useState(noteByTextSize[textSizeByNote.BAD]);
  const [previousClassName, setPreviousClassName] = useState(
    noteByTextSize[textSizeByNote.BAD].className
  );
  const [noteReview, setNoteReview] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [ratingReview, setRatingReview] = useState(1);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [proof, setProof] = useState();
  const [fileNameProof, setFileNameProof] = useState("");
  const [invitation, setInvitation] = useState(null);
  const [image_url, setImage_url] = useState(null);
  const [product_name, setProduct_name] = useState(null);
  const [productNumber, setProductNumber] = useState(null);

  const [noteTitles, setNoteTitles] = useState([
    "Very Bad",
    "Bad",
    "Average",
    "Good",
    "Excellent",
  ]);
  const [noteTitlesFr, setNoteTitlesFr] = useState([
    "Très mauvais",
    "Mauvais",
    "Moyen",
    "Bien",
    "Excellent",
  ]);
  const [a, setA] = useState("");
  const [jobId, setJobId] = useState("");
  const [userId, setUserId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [productId, setProductId] = useState([]);
  //const [img_url, setImg_url] = useState("");
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const endpoint = window.location.href.split("/")[4];
  const [date, setDate] = useState(new Date());

  const navigate = useNavigate();

  useEffect(() => {
    const invitationUrl = window.location.href
      .replace("https://", "")
      .replace("http://", "");
    axios
      .get("/data/invitationdata/?invitationUrl=" + invitationUrl)
      .then((res) => {
        if (res == null) navigate("/404", { replace: true });

        axios
          .get(
            "/data/transaction-get/" +
              res.data.invitation_url_complete
                .split("?")[1]
                .split("&")[0]
                .split("=")[1]
          )
          .then((response) => {
            if (response.data.transaction_state_2 == "completed")
              navigate("/transaction_completed/" + endpoint, { replace: true });
          })
          .catch((err) => {
            //.log(err);
          });

        //.log(res);
        setInvitation(res);
        setA(res.data.invitation_url_complete);
        setJobId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[0]
            .split("=")[1]
        );
        setUserId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[1]
            .split("=")[1]
        );
        setOrderId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[3]
            .split("=")[1]
        );
        setMerchantId(res.data.profile_id);
        setProductId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[4]
            .split("=")[1]
            .split(",")
        );
        setImage_url(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[5]
            .split("=")[1]
            .split(",")[dataindex]
        );
        setProduct_name(invitation.data.name.split(",")[dataindex]);
        setProductNumber(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[5]
            .split("=")[1]
            .split(",").length
        );
      })
      .catch((err) => {
        //.log(err);
      });
  }, [productNumber]);

  useEffect(() => {
    const textArea = document.getElementById(textAreaId);
    if (!textArea) {
      //.log(textArea);
      throw new Error(
        `The textarea element with id ${textAreaId} does not exist`
      );
    }

    const ReviewNote = document.getElementById("noteReview");

    setNoteReview(ReviewNote);

    //.log("noteReview");
    //.log(ReviewNote);

    /*  if (!noteReview) {
      //.log(noteReview); 
      throw new Error(`The noteReview element with id "note-review" does not exist`);   
    }  

    noteReview.classList.add(previousClassName);  */
    //.log("previousClassName");
    //.log(previousClassName);
    textArea.addEventListener("input", handleInput);
    return () => textArea.removeEventListener("input", handleInput);
  }, []);

  const handleInput = (e) => {
    const text = e.target.value.trim();
    setText(text);

    const textLength = text.length;
    const currentNote = getNoteByTextSize(textLength);
    setNote(currentNote);

    if (previousClassName === currentNote.className) return;

    noteReview.classList.remove(previousClassName);
    noteReview.classList.add(currentNote.className);
    setPreviousClassName(currentNote.className);
  };

  const getNoteByTextSize = (textLength) => {
    if (textLength < textSizeByNote.BAD) {
      return noteByTextSize[textSizeByNote.BAD];
    }

    for (let key in textSizeByNote) {
      if (textLength < textSizeByNote[key]) {
        return noteByTextSize[textSizeByNote[key]];
      }
    }

    return noteByTextSize[textSizeByNote.EXCELLENT];
  };

  // Review stars

  const merchandRef = useRef(null);
  const reviewValueRef = useRef(null);
  const reviewValueTextRef = useRef(null);

  useEffect(() => {
    const merchand = merchandRef.current;
    const stars = merchand.querySelectorAll(".star-review");
    const reviewValue = reviewValueRef.current;
    const reviewValueText = reviewValueTextRef.current;
    const starsArray = Array.from(stars);
    starsArray.forEach((star, index) => {
      fillStarsWithSelectedValue(index + 1);
      handleClickOnReview(star);
      handleHoverOnReview(star);
    });

    function handleHoverOnReview(star) {
      star.addEventListener("mouseover", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
      });

      star.addEventListener("mouseout", () => {
        const rating = star?.dataset?.index;
        emptyStars(rating);
        fillStarsWithSelectedValue(merchand.dataset.selected);
      });
    }

    function handleClickOnReview(star) {
      star.addEventListener("click", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
        merchand.dataset.selected = rating;
        setRatingReview(rating);

        reviewValue.innerHTML = rating;
        if (rating === 1)
          reviewValueText.innerHTML = " <strong> star: </strong>Very bad";
        if (rating === 2)
          reviewValueText.innerHTML = " <strong> stars: </strong>Bad";
        if (rating === 3)
          reviewValueText.innerHTML = " <strong> stars: </strong>Average";
        if (rating === 4)
          reviewValueText.innerHTML = " <strong> stars: </strong>Good";
        if (rating === 5)
          reviewValueText.innerHTML = " <strong> stars: </strong>Excellent";
      });
    }

    function fillStarsWithSelectedValue(index) {
      if (Number(index) !== Number(merchand.dataset.selected)) return;
      fillStars(Number(merchand.dataset.selected));
      emptyStars(Number(merchand.dataset.selected));
    }

    function emptyStars(rating) {
      const afterStar = starsArray.filter(
        (s) => Number(s.dataset.index) > Number(rating)
      );
      afterStar.forEach((s) => s.classList.remove("star-filled"));
    }

    function fillStars(rating) {
      const beforeStar = starsArray.filter(
        (s) => Number(s.dataset.index) <= Number(rating)
      );

      beforeStar.forEach((s) => s.classList.add("star-filled"));
    }
  }, []);

  //review form for product code method using useRef

  const title = useRef(null);
  const content = useRef(null);
  const experienceDate = useRef(null);
  const image = useRef();
  const fileInputRef = useRef();
  const [reviewObj, setReviewObj] = useState({
    title: "",
    content: "",
    rating: "0",
    experienceDate: "",
    image: "",
  });
  const [imagereview, setImagereview] = useState([]);

  //handle change image and create miniatures
  const createMiniatureForImage = (e) => {
    /*   const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          const imageUrl = reader.result;
          const state = [...imagereview];
          state.push(imageUrl);
          setImagereview(state);
      }
*/

    const stateImg = [];

    Array.from(e.target.files).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageUrl = reader.result;
        const state = [...imagereview];
        state.push(imageUrl);
        setImagereview(state);
      };
    });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    const newFiles = [...file]; // Créez une copie du tableau existant
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]); // Ajoutez chaque fichier à la copie
    }

    setFile(newFiles);
    setFileName(e.target.files[0].name);
    setProof(e.target.files[0]);
    setFileNameProof(e.target.files[0].name);
    createMiniatureForImage(e);
    //.log(imagereview);
  };

  const [values, setValues] = useState({
    title: "",
    content: "",
    experienceDate: date.toISOString().slice(0, 10),
    image: "",
  });

  const axios = useAxios();
  //comportement
  //const {product_name} = useParams();
  const handleSubmit = (e) => {
    e.preventDefault();

    //.log(fileInputRef.current.files[0]);
    //.log(image.current.files[0]);
    title.current.value = "";
    content.current.value = "";
    experienceDate.current.value = "";
    image.current.value = "";
    setValues({ title: "", content: "", experienceDate: "", image: "" });
    setReviewObj({
      title: values.title,
      content: values.content,
      rating: ratingReview,
      experienceDate: values.experienceDate,
      image: values.image,
    });

    const review = {
      product_id: "",
      product_name: product_name,
      title: values.title,
      content: text,
      rating: ratingReview,
      experienceDate: values.experienceDate,
      image: values.image,
    };
    //.log(review);

    const formData = new FormData();
    //formData.append("file", file);
    for (let i = 0; i < file.length; i++) {
      formData.append(`files${i}`, file[i]); // Ajoutez chaque fichier à la copie
    }
    formData.append("fileName", fileName);
    formData.append("product_name", productId[dataindex]);
    formData.append("title", values.title);
    formData.append("content", text);
    formData.append("rating", ratingReview);
    formData.append("experienceDate", values.experienceDate);
    formData.append("merchant_id", merchantId);
    formData.append("user_id", userId);
    formData.append("job_id", jobId);
    formData.append("product_id", productId[dataindex]);
    formData.append("order_id", orderId);
    formData.append("isOrganic", false);

    // if(token)
    // eslint-disable-next-line no-lone-blocks
    {
      axios
        .post("/data/product-review", formData, false, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          //.log(res);

          /************************************************  Update transacyion to completed */
          axios
            .put(
              "/data/transaction-update2/" + jobId,
              {},
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              //.log(res);
            })
            .catch((err) => {
              //.log(err);
            });
          /***************** End */
          //.log("productnumber");
          //.log(productNumber);
          if (productId.length == dataindex + 1)
            navigate("/review-validated/" + endpoint, { replace: true });
          else
            toast.success("product review registered successfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
        })
        .catch((err) => {
          //.log(err);
        });
    }
    /*   else
   {
    toast.error('hcaptcha must be verified', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
   }
*/
  };
  const onLoad = () => {
    captchaRef.current.execute();
  };

  useEffect(() => {
    if (token) {
    } //.log(`hCaptcha Token: ${token}`);
  }, [token]);

  return (
    <>
      <section className="py-5 form product">
        <form id="reviewform" onSubmit={handleSubmit}>
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
                <h3>
                  {t("productformTitle")} <span>{product_name}</span>
                </h3>
                <p>{t("productformDescr")}</p>
              </div>
              <div className="col-11 col-lg-8 py-3 mx-auto position-relative">
                {/* {i18n.language == "fr-FR" ? ( */}

                {/* ) : ( */}
                <ul className="step-reviews nav text-center">
                  <li className="nav-item w-33 step-1 done">
                    <span className="badge rounded-circle">1</span>
                    <p className="mb-0">{t("arianeMerchant")}</p>
                  </li>
                  <li className="nav-item w-33 step-2 active">
                    <span className="badge rounded-circle ">2</span>
                    <p className="mb-0" aria-current="page">
                      {t("arianeProduct")}
                    </p>
                  </li>
                  <li className="nav-item w-33 step-3 ">
                    <span className="badge rounded-circle">3</span>
                    <p className="mb-0">{t("arianeReview")}</p>
                  </li>
                </ul>
                {/* )} */}
                <div id="line">
                  <div id="line-progress"></div>
                </div>
              </div>
              <div className="col-11 col-lg-8 py-4 px-lg-4 mx-auto bg-light rounded-5">
                <div className="form__header">
                  <div className="row d-flex align-items-center">
                    <div className="col-6 col-md-4 col-xl-3 d-flex rounded-4 border bg-white visuel-mark g-0 mx-auto">
                      <img
                        className="align-self-center"
                        src={image_url === "null" ? NoPicture : image_url}
                        alt="Product name"
                      />
                    </div>
                    <div className="col-12 col-md-8 col-xl-9">
                      <div className="form__header__note">
                        <p className="lead mb-0">{product_name}</p>

                        <p className="mb-0">{t("productformActionTitle")}</p>

                        <div
                          className="form__star mb-3 d-flex justify-content-start"
                          id="review"
                          data-review="merchand"
                          ref={merchandRef}
                          data-selected="1"
                        >
                          <div
                            className="star-review star-default star-empty star-filled"
                            data-index="1"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="2"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="3"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="4"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="5"
                          ></div>
                        </div>

                        <p>
                          <span id="review-value" ref={reviewValueRef}>
                            <strong>1</strong>
                          </span>{" "}
                          <span ref={reviewValueTextRef}>
                            {ratingReview == 1 ? (
                              <Trans
                                i18nKey="AddreviewMerchant1star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 2 ? (
                              <Trans
                                i18nKey="AddreviewMerchant2star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 3 ? (
                              <Trans
                                i18nKey="AddreviewMerchant3star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 4 ? (
                              <Trans
                                i18nKey="AddreviewMerchant4star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 5 ? (
                              <Trans
                                i18nKey="AddreviewMerchant5star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="form__content">
                    <p className="lead mt-3 mb-0">
                      {t("productFormWriteTitle")}
                    </p>
                    <input
                      className="form-control"
                      type="text"
                      id="title"
                      name="title"
                      required=""
                      ref={title}
                      value={values.title}
                      onChange={handleChange}
                    />
                    <p className="lead mb-0"> {t("productFormWriteContent")}</p>
                    <p>{t("productFormDescriptionContent")}</p>
                    <p id="note_review" className="">
                      {t("productFormWriteLengthContent")}:{" "}
                      <span id="noteReview" className={note.className}>
                        {i18n.language == "fr-FR" ? note.title : note.title}
                      </span>{" "}
                    </p>
                    <textarea
                      className="form-control"
                      name="content"
                      rows="10"
                      placeholder={t("productFormPlaceholderContent")}
                      id="content"
                      onChange={handleInput}
                      ref={content}
                    ></textarea>
                    <p className="">
                      {t("productFormAddPhotomsg")}{" "}
                      <span className="text-muted">
                        ({t("productFormOptional")})
                      </span>
                    </p>
                    <div className="row mb-4">
                      {imagereview.length > 0 ? (
                        // (imagereview[length-1].map((img) => {
                        <div className="col-6 col-lg">
                          <div className="d-flex rounded-4 border bg-white visuel-mark">
                            <img
                              className="align-self-center"
                              src={imagereview[imagereview.length - 1]}
                              alt="Product name"
                              id="img2"
                            />
                          </div>
                        </div>
                      ) : (
                        // }))
                        <div className="col-6 col-lg">
                          <div className="d-flex rounded-4 border bg-white visuel-mark">
                            <img
                              className="align-self-center"
                              src={add_image}
                              alt="Product name"
                              id="img2"
                            />
                          </div>
                        </div>
                      )}

                      {imagereview.length > 1 ? (
                        // (imagereview[length-1].map((img) => {
                        <div className="col-6 col-lg">
                          <div className="d-flex rounded-4 border bg-white visuel-mark">
                            <img
                              className="align-self-center"
                              src={imagereview[imagereview.length - 2]}
                              alt="Product name"
                              id="img2"
                            />
                          </div>
                        </div>
                      ) : (
                        // }))
                        <div className="col-6 col-lg">
                          <div className="d-flex rounded-4 border bg-white visuel-mark">
                            <img
                              className="align-self-center"
                              src={add_image}
                              alt="Product name"
                              id="img2"
                            />
                          </div>
                        </div>
                      )}

                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxWidth: "100%" }}
                        />
                      )}
                      <div className="col-6 col-lg">
                        <label className="add__photo rounded-3 border w-100 text-center d-flex">
                          <div className="m-auto">
                            <i className="flaticon-galerie"></i>
                            <div className="">+ {t("productFormAddPhoto")}</div>
                            <input
                              accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                              type="file"
                              multiple
                              id="image"
                              className="d-none"
                              ref={image}
                              onChange={handleChange}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <p className="lead d-none">
                    Proof of purchase{" "}
                    <span className="text-muted">(optional)</span>
                  </p>
                  <div className="mb-3 d-none">
                    <input
                      type="file"
                      accept="image/jpeg, image/tiff, image/webp, image/png"
                      className="form-control"
                      placeholder="Proof of purchase"
                      aria-label="Proof of purchase"
                      ref={fileInputRef}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="text-muted d-none">
                    Can be a photo of the product with your VeritaTrust display
                    name next to it, an invoice, a contract or an email exchange
                    with the company.
                  </p>

                  <div className="d-none">Message d'erreur</div>
                  <p className="lead mt-3 mbnput cla-0">
                    {t("productFormExperienceDate")}
                  </p>
                  <input
                    className="form-control"
                    type="date"
                    max={date.toISOString()}
                    data-date-inline-picker="true"
                    name="experienceDate"
                    id="date"
                    required=""
                    ref={experienceDate}
                    value={values.experienceDate}
                    onChange={handleChange}
                  />

                  <p className="text-muted">{t("productFormPolicy")}</p>

                  <div className="d-none" id="root-submit">
                    <div className="row d-flex align-items-center">
                      <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
                        <h3>{t("productFormPublish")}</h3>
                      </div>
                      <div className="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
                        <div className="form__account">
                          <div className="row d-flex flex-column align-items-center">
                            <div
                              className="col-12 col-md-6 d-flex flex-column mx-auto gap-2"
                              id="module-connect"
                            >
                              <button className="btn btn-primary">
                                Sign in with Facebook
                              </button>
                              <button className="btn btn-primary">
                                Sign in with Google
                              </button>
                              <button
                                className="btn btn-primary"
                                id="metaloginbtn"
                              >
                                Sign in with Metamask
                              </button>
                              <a href="#" className="d-none">
                                Sign in with Apple
                              </a>
                            </div>
                            <div className="d-none">
                              <div className="orbymail text-center">
                                <div className="separator">
                                  Use your email address
                                </div>
                              </div>
                            </div>
                            <div
                              className="row d-flex align-items-center"
                              id="sumbit-email"
                            ></div>
                            <div className="d-none" id="message-confirm">
                              <div className="me-3">
                                <i className="flaticon-check"></i>
                              </div>
                              <div>Success Message</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                {/*  <HCaptcha
                        sitekey="1be3a25e-95cb-441d-a951-f140b9e09428"
                        onLoad={onLoad}
                        onVerify={setToken}
                        ref={captchaRef}
                            />  */}

                <div className="d-grid gap-2" id="publish">
                  <button
                    type="submit"
                    className="btn-block btn btn-primary btn-lg text-uppercase text-right"
                  >
                    {t("productFormPublish")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ProductReviewForm;
