import React, { useEffect, useState } from "react";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import VoucherItem from "../../components/VoucherItem";
import { useAxios } from "../../utils/hook/useAxios";
function VoucherPage() {
  const axios = useAxios();
  const [vouchersList, setVouchersList] = useState([]);
  const [Cashflow, setCashFlow] = useState({});

  useEffect(() => {
    let meUserdata = JSON.parse(window.localStorage.getItem("userdata"));

    axios
      .get(`/VoucherGifts`)
      .then((response) => {
        setVouchersList(response.data);
      })
      .catch((error) => {
        //.error(error);
      });

    axios
      .get(`/CashFlowRewardsRoutes/${meUserdata.id}`)
      .then((response) => {
        setCashFlow(response.data);
        window.localStorage.setItem("me", JSON.stringify(response.data));
      })
      .catch((error) => {
        //.error(error);
      });
  }, []);

  return (
    <>
      {" "}
      <DashboardDiv titlepage="Vouchers" />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div class="dashboard col-12 col-lg-9">
                  <div
                    class="personal-data mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div class="title align-items-center">
                      <h4>
                        Your balance:
                        <br />
                        <span class="text-green">
                          {Cashflow.TotalCashflow ? Cashflow.TotalCashflow : 0}
                        </span>{" "}
                        <span class="text-primary">VeritaCoins</span> (≃{" "}
                        <span class="text-primary">€</span>{" "}
                        <span class="text-green">
                          {Cashflow.TotalCashflow
                            ? Cashflow.TotalCashflow / 100
                            : 0}
                        </span>{" "}
                        )
                      </h4>

                      <form class="search" action="">
                        <input
                          type="text"
                          class="form-control "
                          placeholder="Search a merchant"
                          aria-label="Search"
                          aria-describedby="search_merchants"
                        />
                      </form>
                      <a
                        class="mb-0 collapsed"
                        data-bs-toggle="collapse"
                        href="#filter_merchants"
                        role="button"
                        aria-expanded="false"
                        aria-controls="filter_merchants"
                      >
                        Advanced filters
                      </a>
                    </div>
                    <div class="py-2">
                      <div class="collapse" id="filter_merchants">
                        <div class="row">
                          <div class="col-12 col-md country mb-3">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="online_voucher"
                                checked="checked"
                              />
                              <label
                                class="form-check-label"
                                for="online_voucher"
                              >
                                Online voucher
                              </label>
                            </div>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="stroe_voucher"
                                disabled="disabled"
                              />
                              <label
                                class="form-check-label"
                                for="stroe_voucher"
                              >
                                In-store voucher
                              </label>
                            </div>
                          </div>
                          <div class="col-12 col-md country mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected="selected">
                                In all countries
                              </option>
                              <option value="1">France</option>
                              <option value="2">English</option>
                              <option value="3">Italian</option>
                            </select>
                          </div>
                          <div class="col-12 col-md country mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected="selected">
                                In all currencies
                              </option>
                              <option value="2">EUR</option>
                              <option value="3">GBP</option>
                              <option value="1">USD</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="rewards merchants row mt-3">
                      {vouchersList &&
                        vouchersList.map((voucher, index) => (
                          <VoucherItem
                            key={index}
                            urlImage={voucher.VoucherImage}
                            website={voucher.VoucherName}
                            voucherId={voucher.id}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VoucherPage;
