import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function ConfirmAccount() {
  const axios = useAxios();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    actualpassword: "",
    confirmpassword: "",
  });

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const invitationUrl =
      window.location.host +
      "/mreview/" +
      window.location.pathname
        .split("/")[2]
        .replace("https://", "")
        .replace("http://", "");

    axios
      .get("/data/invitationdata/?invitationUrl=" + invitationUrl)
      .then((res) => {
        if (res == null) navigate("/404", { replace: true });

        setUserId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[1]
            .split("=")[1]
        );
      })
      .catch((err) => {
        //.log(err);
      });

    //.log(invitationUrl);
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      userId: userId,
      actualpassword: values.actualpassword,
    };

    if (values.actualpassword === values.confirmpassword) {
      axios
        .put("/automatic-flow/resetpassword", dataUser)
        .then((res) => {
          //.log(res);

          axios
            .post("/auto-login", {
              userId: userId,
              password: values.actualpassword,
            })
            .then((res) => {
              if (res.data["success"] === true) {
                //.log(res.data);
                window.location.href = "/account";
              } else {
                //.log("Error to connect");
              }
            })
            .catch((err) => {
              //.log(err);
              toast.error("Invalid password or username", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
          // window.location.href = "/Create";
        })
        .catch((err) => {
          //.log(err);
          toast.error("server url don't not exist", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      toast.error("Password must be the same", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <section class="py-5 form">
        <form action="" onSubmit={handleSubmit}>
          <div class="container">
            <div class="row d-flex align-items-center">
              <div class="col-11 col-lg-8 pt-3 mx-auto text-center">
                <h3>Register: last step to get your reward!</h3>
              </div>
              <div class="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
                <div class="form__account">
                  <div class="row d-flex flex-column align-items-center">
                    <div class="col-12 col-md-6 g-0 mx-auto">
                      <div class="orbymail text-center">
                        <div class="separator">Register your password</div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="input-group mb-3">
                        <input
                          type="password"
                          class="form-control editable"
                          name="actualpassword"
                          value={values.actualpassword}
                          onChange={handleChange}
                          id="actualpassword"
                        />
                        <span class="input-group-text bg-success toggle-password text-white border-success">
                          <i class="flaticon-hide"></i>
                        </span>
                      </div>

                      <hr />
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="input-group mb-3">
                        <input
                          type="password"
                          class="form-control editable"
                          name="confirmpassword"
                          value={values.confirmpassword}
                          onChange={handleChange}
                          id="confirmpassword"
                        />
                        <span class="input-group-text bg-success toggle-password text-white border-success">
                          <i class="flaticon-hide"></i>
                        </span>
                      </div>

                      <hr />
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="form__footer">
                        <div class="d-grid gap-2">
                          <button
                            type="submit"
                            class="btn-block btn btn-success text-white btn-lg"
                            id="signinbutton"
                          >
                            Confirm password
                          </button>
                        </div>
                        <p> or you can login by clicking here</p>
                        <div class="d-grid gap-2">
                          <Link
                            to="/signin"
                            class="btn-block btn btn-success text-white btn-lg"
                            id="signinbutton"
                          >
                            To sign in
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </form>
      </section>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ConfirmAccount;
