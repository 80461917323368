import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAxios } from "../../utils/hook/useAxios";

function ProofofPurchaseModal(props) {
  const axios = useAxios();
  const { src, reviewData, onUpdate } = props;

  const [orderId, setOrderId] = useState(reviewData.order_id);

  function handleApprove() {
    let fetchReviews = () => {
      axios
        .put(
          `/support/productreviews/productreviewbyjobid/${reviewData.job_id}`,
          { order_id: orderId }
        )
        .then(() => {
          // Traitement après le premier appel axios
          return axios.put(
            `/support/merchantreviews/merchantreviewbyjobid/${reviewData.job_id}`,
            { order_id: orderId }
          );
        })
        .then(() => {
          // Traitement après le deuxième appel axios
          // Appeler onUpdate() ici si nécessaire
          onUpdate();
          window.location.reload();
        })
        .catch((error) => {
          console.error("Erreur lors de la mise à jour des avis :", error);
        });
    };

    fetchReviews();
  }

  // Extract merchant from props
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proof of purchase
          </Modal.Title>
        </Modal.Header>{" "}
        <Modal.Body>
          <img src={src?.replace(".pdf", ".jpg")} alt="proofPurchase" />
          <div class="order mb-3">
            <label class="text-primary" for="id_order">
              ID Order
            </label>
            <input
              id="id_order"
              class="form-control"
              type="text"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              placeholder="Insert the order number for this purchase"
            />
          </div>
          <button
            className="btn btn-success"
            onClick={() => {
              handleApprove();

              props.onHide();
            }}
          >
            Save OrderID
          </button>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProofofPurchaseModal;
