import React, { useState, useEffect, useRef } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CreateStarsComponent({ updateRating, resetInput }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const merchandRef = useRef(null);
  const reviewValueRef = useRef(null);
  const reviewValueTextRef = useRef(null);

  const [ratingReview, setRatingReview] = useState(0);
  const [dataSelected, setDataSelected] = useState("0");

  const [noteTitles, setNoteTitles] = useState([
    { en: "Very Bad", fr: "Très mauvais", it: "Malto male" },
    { en: "Bad", fr: "Mauvais", it: "Male" },
    { en: "Average", fr: "Moyen", it: "Medio" },
    { en: "Good", fr: "Bon", it: "Bueno" },
    { en: "Excellent", fr: "Excellent", it: "Excellente" },
  ]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  useEffect(() => {
    setRatingReview(0);
    setDataSelected("0");
  }, [resetInput]);

  useEffect(() => {
    const merchand = merchandRef.current;
    const stars = merchand.querySelectorAll(".star-review");
    const starsArray = Array.from(stars);

    function handleHoverOnReview(star) {
      star.addEventListener("mouseover", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
      });

      star.addEventListener("mouseout", () => {
        const rating = star?.dataset?.index;
        emptyStars(rating);
        fillStarsWithSelectedValue(merchand.dataset.selected);
      });
    }

    function handleClickOnReview(star) {
      star.addEventListener("click", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
        merchand.dataset.selected = rating;
        setRatingReview(rating);
        updateRating(rating);
        if (reviewValueRef.current) {
          reviewValueRef.current.innerHTML = rating;
          if (rating === 1)
            reviewValueTextRef.current.innerHTML =
              " <strong> star: </strong>Very bad";
          if (rating === 2)
            reviewValueTextRef.current.innerHTML =
              " <strong> stars: </strong>Bad";
          if (rating === 3)
            reviewValueTextRef.current.innerHTML =
              " <strong> stars: </strong>Average";
          if (rating === 4)
            reviewValueTextRef.current.innerHTML =
              " <strong> stars: </strong>Good";
          if (rating === 5)
            reviewValueTextRef.current.innerHTML =
              " <strong> stars: </strong>Excellent";
        }
      });
    }

    starsArray.forEach((star, index) => {
      fillStarsWithSelectedValue(index + 1);
      handleClickOnReview(star);
      handleHoverOnReview(star);
    });

    function fillStarsWithSelectedValue(index) {
      if (Number(index) !== Number(merchand.dataset.selected)) return;
      fillStars(Number(merchand.dataset.selected));
      emptyStars(Number(merchand.dataset.selected));
    }

    function emptyStars(rating) {
      const afterStar = starsArray.filter(
        (s) => Number(s.dataset.index) > Number(rating)
      );
      afterStar.forEach((s) => s.classList.remove("star-filled"));
    }

    function fillStars(rating) {
      const beforeStar = starsArray.filter(
        (s) => Number(s.dataset.index) <= Number(rating)
      );

      beforeStar.forEach((s) => s.classList.add("star-filled"));
    }
    emptyStars(0);
  }, [resetInput]);

  return (
    <>
      {" "}
      <div
        className="form__star mb-3 d-flex justify-content-start"
        id="review"
        data-review="merchand"
        ref={merchandRef}
        data-selected={dataSelected}
      >
        <div
          className="star-review star-default star-empty"
          data-index="1"
        ></div>
        <div
          className="star-review star-default star-empty"
          data-index="2"
        ></div>
        <div
          className="star-review star-default star-empty"
          data-index="3"
        ></div>
        <div
          className="star-review star-default star-empty"
          data-index="4"
        ></div>
        <div
          className="star-review star-default star-empty"
          data-index="5"
        ></div>
      </div>
      <p>
        <span id="review-value" ref={reviewValueRef}>
          {ratingReview}
        </span>{" "}
        <span ref={reviewValueTextRef}>
          {" "}
          <strong> {t("AddReviewForBetaTesterStars")}: </strong>
          {ratingReview === 0
            ? t("AddReviewForBetaTesterClickToRate")
            : noteTitles[ratingReview - 1][t("lang")]}
        </span>
      </p>
    </>
  );
}

export default CreateStarsComponent;
