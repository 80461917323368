import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect, useRef } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import Modal from "react-modal";
function MerchantUserModerationComponent({
  merchantUserData,
  onApproveChild,
  parentValue,
}) {
  const axios = useAxios();
  const [website, setWebsite] = useState(merchantUserData.website);
  const [firstName, setFirstName] = useState(merchantUserData.first_name);
  const [lastName, setLastName] = useState(merchantUserData.last_name);
  const [email, setEmail] = useState(merchantUserData.email);
  const [displayApprouve, setDisplayApprouve] = useState(false);
  const [NotificationMessage, setNotificationMessage] = useState("");
  const [merchantData, setMerchantData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, product_id } = useParams();

  useEffect(() => {
    setWebsite(merchantUserData.website || "");
    setFirstName(merchantUserData.first_name || "");
    setLastName(merchantUserData.last_name || "");
    setEmail(merchantUserData.email);
    setPhoneNumber(merchantUserData.phoneNumber || "");
  }, [parentValue]);

  useEffect(() => {
    i18n.changeLanguage(lang);

    let fetchReviews = async () => {
      const resp = await axios.get(
        `/merchant_profiles/findmerchant/${merchantUserData.merchant_id}`
      );
      setMerchantData(resp.data);
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, [, merchantUserData.website]);

  function handleApprove() {
    let fetchReviews = async () => {
      await axios.put(`/merchantUsers/${merchantUserData.id}`, {
        id: merchantUserData.id,
        status: "connected",
      });
      toast.success("Merchant user updated successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };
    fetchReviews();
    onApproveChild();
    //window.location.reload();
  }

  const [isModalOpen, setModalOpen] = useState(false);

  const handleDeleteMerchantUser = () => {
    setModalOpen(true);
  };

  const confirmDelete = () => {
    // Le code pour supprimer l'élément ici
    let fetchReviews = async () => {
      await axios.get(`/products/deleteone/${merchantUserData.id}`);

      toast.success("Product deleted successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };

    fetchReviews();

    onApproveChild();
    setModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {" "}
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + merchantUserData.id}
            aria-expanded="true"
            aria-controls={merchantUserData.id}
          >
            {merchantUserData.status === "connected" ? (
              <i className="flaticon-check me-3 text-success"></i>
            ) : (
              <i className="flaticon-time-left me-3 text-warning"></i>
            )}
            {merchantUserData.first_name} {merchantUserData.last_name}{" "}
            {!merchantUserData.first_name && !merchantUserData.last_name
              ? merchantUserData.email
              : ""}{" "}
            {merchantUserData.corporate_name ? "- " : " "}
            {merchantUserData.corporate_name}
          </button>
        </h2>
        <div
          id={merchantUserData.id}
          className={
            product_id
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          data-bs-parent="#accordionbrands"
        >
          <div className="accordion-body personal-data moderator">
            <div className="row">
              <div className="col-md-7 mb-3">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="firstname"
                  value={firstName}
                  disabled={true}
                />
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="lastname"
                  value={lastName}
                  disabled={true}
                />
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="email"
                  value={email}
                  disabled={true}
                />

                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="phone number"
                  value={phoneNumber}
                  disabled={true}
                />
                <dl className="row">
                  <dt className="col-sm-3">Enterprise</dt>
                  <dd className="col-sm-9">
                    <span className="badge text-bg-primary me-2">
                      {merchantUserData.corporate_name}
                    </span>
                  </dd>

                  <dt className="col-sm-3">Website</dt>

                  <dd className="col-sm-9">
                    {merchantData.website && (
                      <>
                        <span className="badge text-bg-primary me-2">
                          {website}
                        </span>
                        {merchantData.status === "1" ? (
                          <i class="flaticon-check me-3 text-success"></i>
                        ) : (
                          <i class="flaticon-time-left me-3 text-warning"></i>
                        )}
                      </>
                    )}
                  </dd>
                  <dt className="col-sm-3">Job Title</dt>
                  <dd className="col-sm-9">
                    <span className="badge text-white bg-success me-2">
                      {merchantUserData.jobTitle}
                    </span>
                  </dd>
                  <dt className="col-sm-3">Status</dt>
                  <dd className="col-sm-9">
                    <span className="badge text-white bg-success me-2">
                      {merchantUserData.status}
                    </span>
                  </dd>
                </dl>
                {displayApprouve === true && (
                  <div class="reject mb-3">
                    <label class="text-primary" for="Content">
                      Reject message explication
                    </label>
                    <textarea
                      id="Notification"
                      class="form-control"
                      type="text"
                      value={NotificationMessage}
                      onChange={(e) => setNotificationMessage(e.target.value)}
                    ></textarea>
                  </div>
                )}
              </div>
              <div className="col-md-3 mb-3">
                <>
                  <button
                    className="btn btn-success w-100 mb-2"
                    onClick={handleApprove}
                  >
                    Approuve
                  </button>
                  <button
                    className="btn btn-outline-danger w-100"
                    type="button"
                    onClick={handleDeleteMerchantUser}
                  >
                    Delete
                  </button>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Delete Merchant user Confirmation"
                  >
                    <p>Are you sure you want to delete this merchant user?</p>
                    <div className="d-flex justify-content-evenly g-2">
                      <button
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={confirmDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </Modal>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default MerchantUserModerationComponent;
