import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LogoWg from "../../assets/logo-wg.png";
//import "../../styles/style.css";

function Footer() {
  const [typeheader, setTypeheader] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { selectedLang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(selectedLang);

    const Url = window.location.href;
    setTypeheader(Url.split("/")[3]);
  });

  return typeheader !== "preview" &&
    typeheader !== "mreview" &&
    typeheader !== "review-valided" &&
    typeheader !== "review-validated" &&
    typeheader !== "confirmaaccount" ? (
    <>
      <footer className="bg-primary pt-5 pb-2 text-white">
        <div className="container">
          <div className="row py-3">
            <div className="col-md-6">
              <h3>{t("FooterMailingTitle")}</h3>
              <p>{t("FooterMailingMessage")} </p>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("FooterMailingField")}
                  aria-label="Notre newsletter"
                  aria-describedby="button-newsletter"
                />
                <button
                  className="btn btn-outline-light"
                  type="button"
                  id="button-newsletter"
                >
                  {t("FooterMailingField")}
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <h3>{t("FooterFollow")} </h3>
              <ul className="list-inline">
                <li class="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/veritatrust/"
                    target="_blank"
                    aria-label="Linkedin link"
                    rel="noreferrer"
                  >
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="https://twitter.com/veritatrust"
                    target="_blank"
                    aria-label="Twitter link"
                    rel="noreferrer"
                  >
                    <i class="flaticon-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <hr />
          <div className="row py-3">
            <div className="col-md-4">
              <img
                className="mb-3"
                src={LogoWg}
                alt="Veritatrust"
                width="200"
              />
              <p>{t("FooterVeritaTrustBaseline")}</p>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-lg-4">
                  <h4>{t("FooterCorporateTitle")} </h4>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">{t("FooterCorporateMenuListAbout")} </a>
                    </li>
                    <li>{t("FooterCorporateMenuListBlog")} </li>
                    <li>{t("FooterCorporateMenuListJobs")} </li>
                    <li>{t("FooterCorporateMenuListContact")} </li>
                    <li>{t("FooterCorporateMenuListPress")} </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h4>{t("FooterMyAccountTitle")} </h4>
                  <ul className="list-unstyled">
                    <li>{t("FooterMyAccountMenuListSignup")} </li>
                    <li>{t("FooterMyAccountMenuListLogin")} </li>
                    <li>{t("FooterMyAccountMenuListFaq")} </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h4>{t("FooterEnterprisesTitle")} </h4>
                  <ul className="list-unstyled">
                    <li>{t("FooterEnterprisesMenuListSignuppro")} </li>
                    <li>{t("FooterEnterprisesMenuListLogin")} </li>
                    <li>{t("FooterEnterprisesMenuListLogin")} </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <hr />
          <div className="d-flex justify-content-between">
            <p>
              © VeritaTrust {new Date().getFullYear()} - {t("FooterAllright")}
            </p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="#">{t("FooterPrivacyPolicy")} </a>
              </li>
              <li className="list-inline-item">
                <a href="#">{t("FooterLegalInfo")} </a>
              </li>
              <li className="list-inline-item">
                <a href="#">{t("FooterTandCs")} </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  ) : (
    <>
      <footer className="bg-primary pt-3 pb-1 text-white">
        <div className="container">
          <div className="text-center">
            <p>
              © VeritaTrust {new Date().getFullYear()} - {t("footerMessage")}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
