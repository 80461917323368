import React, { useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";
//import AOS from "aos";
//import "aos/dist/aos.css";
import AddReviewDiv from "../../components/AddReviewDiv";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AddNewReviewCreatePage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [values, setValues] = useState({
    categoryProduct: "",
  });

  const [valuesMerchant, setValuesMerchant] = useState({
    categoryMerchant: "",
  });

  const axios = useAxios();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  const handleSelectCategoryInput = (event) => {
    setValues({
      ...values,
      categoryProduct: event.target.innerText,
    });
    setSearchResults([]);
  };

  const handleSelectCategoryMerchantInput = (event) => {
    setValuesMerchant({
      ...values,
      categoryMerchant: event.target.innerText,
    });
    setSearchResults([]);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setSearchTerm(e.target.value);
  };

  const handleChangeMerchant = (e) => {
    setValuesMerchant({ ...values, [e.target.name]: e.target.value });
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm.length >= 3) {
      axios
        .get(`/search-categories-item?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    } else {
      setSearchResults([]);
    }
    //.log(searchResults);
  }, [searchTerm]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <AddReviewDiv titlepage={t("AddreviewTitle")} />
      <section className="page_content bg-success pb-5 form">
        <div className="container">
          <div className="row">
            <div className="col-11 col-md-12 col-lg-10 py-2 py-lg-4 mx-auto">
              <div
                className="Content mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4"
                // data-aos="fade-up"
                // data-aos-anchor-placement="top-bottom"
              >
                <h2>What are you reviewing?</h2>
                <ul
                  className="nav nav-pills nav-fill bg-light rounded mb-3"
                  id="add-reviews"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="product-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#product"
                      type="button"
                      role="tab"
                      aria-controls="product"
                      aria-selected="true"
                    >
                      Product
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="merchant-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#merchant"
                      type="button"
                      role="tab"
                      aria-controls="merchant"
                      aria-selected="false"
                    >
                      Merchant
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-1" id="add-reviews-product">
                  <div
                    className="tab-pane fade show active"
                    id="product"
                    role="tabpanel"
                    aria-labelledby="product-tab"
                    tabIndex="0"
                  >
                    <form>
                      <div className="row mb-md-3">
                        <label
                          for="productName"
                          className="col-md-3 col-form-label"
                        >
                          Product name
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="productName"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <label
                          for="categoryProduct"
                          className="col-md-3 col-form-label"
                        >
                          Category
                        </label>
                        <div className="col-md-9 dropdown">
                          <input
                            type="text"
                            className="form-control"
                            id="categoryProduct"
                            required
                            value={values.categoryProduct}
                            name="categoryProduct"
                            onChange={handleChange}
                            data-bs-toggle="dropdown"
                            autoComplete="off"
                          />
                          <ul class="dropdown-menu">
                            {searchResults &&
                              searchResults.map((suggestion, index) => (
                                <li key={index}>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={handleSelectCategoryInput}
                                  >
                                    {suggestion.category_name}
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>

                      <div className="row mb-md-3">
                        <label
                          for="productBrand"
                          className="col-md-3 col-form-label"
                        >
                          Product brand{" "}
                          <span className="text-muted">
                            <small>(Optional)</small>
                          </span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="productBrand"
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <label
                          for="productmodel"
                          className="col-md-3 col-form-label"
                        >
                          Product Model Number{" "}
                          <span className="text-muted">
                            <small>(Optional)</small>
                          </span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="productmodel"
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <label
                          for="productlink"
                          className="col-md-3 col-form-label"
                        >
                          Weblink{" "}
                          <span className="text-muted">
                            <small>(Optional)</small>
                          </span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="url"
                            className="form-control"
                            id="productlink"
                          />
                        </div>
                      </div>
                      <button
                        className="btn btn-primary w-100 mt-3"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#reviewProduct"
                        aria-expanded="false"
                        aria-controls="reviewProduct"
                      >
                        Start your review
                      </button>
                      <div className="collapse mt-3" id="reviewProduct">
                        <div className="form__header container">
                          <div className="row d-flex align-items-center">
                            <div className="col-6 col-md-4 col-xl-3 d-flex rounded-4 border bg-white visuel-mark g-0 mx-auto form__content">
                              <label className="add__photo rounded-3 border w-100 text-center d-flex">
                                <div className="m-auto">
                                  <i className="flaticon-galerie"></i>
                                  <div className="">
                                    + Ajouter photo ou vidéo
                                  </div>
                                  <input
                                    accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                                    type="file"
                                    className="d-none"
                                  />
                                </div>
                              </label>
                            </div>
                            <div className="col-12 col-md-8 col-xl-9">
                              <div className="form__header__note">
                                <p className="mb-0">How would you rate it?</p>
                                <div
                                  className="form__star mb-3 d-flex justify-content-start"
                                  id="review"
                                  data-review="product"
                                  data-selected="0"
                                >
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="1"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="2"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="3"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="4"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="5"
                                  ></div>
                                </div>
                                <p>
                                  <strong>5 stars :</strong> Excellent !
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form__content">
                          <p className="lead mt-3 mb-0">
                            Title of your review{" "}
                            <span className="text-muted">(required)</span>
                          </p>
                          <input
                            className="form-control"
                            type="text"
                            required
                          />
                          <p className="lead mb-0">Your review</p>
                          <p>
                            Focus on being factual and objective. Don't use
                            aggressive language and don't post personal details.
                          </p>
                          <p id="note_review">
                            Your review content:{" "}
                            <span className="bad" title="bad">
                              weak
                            </span>
                            <span className="not_bad" title="Not bad">
                              Average
                            </span>
                            <span className="good" title="Good">
                              Good
                            </span>
                            <span className="very_good" title="very good">
                              Very good
                            </span>
                            <span className="excellent" title="excellent">
                              Excellent
                            </span>
                          </p>
                          <textarea
                            className="form-control"
                            name=""
                            id=""
                            rows="10"
                            placeholder="State accurate facts and be objective"
                          ></textarea>
                          <p className="">
                            Ajoutez jusqu'à 3 photos ou vidéos à votre avis{" "}
                            <span className="text-muted">(facultatif)</span>
                          </p>
                          <div className="row mb-4">
                            <div className="col-6 col-lg">
                              <div className="d-flex rounded-4 border bg-white visuel-mark">
                                <img
                                  className="align-self-center"
                                  src="https://m.media-amazon.com/images/I/61Q9Zr0wt5L._AC_UX425_.jpg"
                                  alt="Product name"
                                />
                              </div>
                            </div>
                            <div className="col-6 col-lg">
                              <div className="d-flex rounded-4 border bg-white visuel-mark">
                                <img
                                  className="align-self-center"
                                  src="https://m.media-amazon.com/images/I/61EP5JVoyxL._AC_UL1024_.jpg"
                                  alt="Product name"
                                />
                              </div>
                            </div>
                            <div className="col-6 col-lg">
                              <label className="add__photo rounded-3 border w-100 text-center d-flex">
                                <div className="m-auto">
                                  <i className="flaticon-galerie"></i>
                                  <div className="">
                                    + Ajouter photo ou vidéo
                                  </div>
                                  <input
                                    accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                                    type="file"
                                    className="d-none"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <p className="lead">
                          Proof of purchase{" "}
                          <span className="text-muted">(optional)</span>
                        </p>
                        <div className="mb-3">
                          <input
                            type="file"
                            accept="image/jpeg, image/tiff, image/webp, image/png"
                            type="text"
                            className="form-control"
                            placeholder="Proof of purchase"
                            aria-label="Proof of purchase"
                          />
                        </div>
                        <p className="text-muted">
                          Can be a photo of the product with your veritatrust
                          display name next to it, an invoice, a contract or an
                          email exchange with the company.
                        </p>

                        <p className="text-muted">
                          By submitting a review, I accept Veritatrust's Posting
                          Guidelines and Terms of Use Policy.
                        </p>
                        <div className="form__footer">
                          <div className="d-grid gap-2">
                            <button
                              type="button"
                              className="btn-block btn btn-primary btn-lg text-uppercase text-right"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="merchant"
                    role="tabpanel"
                    aria-labelledby="merchant-tab"
                    tabIndex="0"
                  >
                    <form>
                      <div className="row mb-md-3">
                        <label
                          for="merchantName"
                          className="col-md-3 col-form-label"
                        >
                          Merchant name
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="merchantName"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3 dropdown">
                        <label
                          for="categorymerchant"
                          className="col-md-3 col-form-label"
                        >
                          Category
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="categorymerchant"
                            required
                            value={valuesMerchant.categoryMerchant}
                            name="categoryMerchant"
                            onChange={handleChangeMerchant}
                            data-bs-toggle="dropdown"
                            autoComplete="off"
                          />
                          <ul class="dropdown-menu">
                            {searchResults &&
                              searchResults.map((suggestion, index) => (
                                <li key={index}>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={handleSelectCategoryMerchantInput}
                                  >
                                    {suggestion.category_name}
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <label
                          for="merchantBrand"
                          className="col-md-3 col-form-label"
                        >
                          Merchant brand{" "}
                          <span className="text-muted">
                            <small>(Optional)</small>
                          </span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="merchantBrand"
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <label
                          for="merchantwebsite"
                          className="col-md-3 col-form-label"
                        >
                          Weblink{" "}
                          <span className="text-muted">
                            <small>(Optional)</small>
                          </span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="url"
                            className="form-control"
                            id="merchantwebsite"
                          />
                        </div>
                      </div>
                      <button
                        className="btn btn-primary w-100 mt-3"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#reviewmerchant"
                        aria-expanded="false"
                        aria-controls="reviewmerchant"
                      >
                        Start your review
                      </button>
                      <div className="collapse mt-3" id="reviewmerchant">
                        <div className="form__header container">
                          <div className="row d-flex align-items-center">
                            <div className="col-6 col-md-4 col-xl-3 d-flex rounded-4 border bg-white visuel-mark g-0 mx-auto form__content">
                              <label className="add__photo rounded-3 border w-100 text-center d-flex">
                                <div className="m-auto">
                                  <i className="flaticon-galerie"></i>
                                  <div className="">
                                    + Ajouter photo ou vidéo
                                  </div>
                                  <input
                                    accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                                    type="file"
                                    className="d-none"
                                  />
                                </div>
                              </label>
                              <img
                                className="align-self-center"
                                src="https://m.media-amazon.com/images/I/61Q9Zr0wt5L._AC_UX425_.jpg"
                                alt="merchant name"
                              />
                            </div>
                            <div className="col-12 col-md-8 col-xl-9">
                              <div className="form__header__note">
                                <p className="mb-0">How would you rate it?</p>
                                <div
                                  className="form__star mb-3 d-flex justify-content-start"
                                  id="review"
                                  data-review="merchant"
                                  data-selected="0"
                                >
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="1"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="2"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="3"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="4"
                                  ></div>
                                  <div
                                    className="star-review star-default star-empty"
                                    data-index="5"
                                  ></div>
                                </div>
                                <p>
                                  <strong>5 stars :</strong> Excellent !
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form__content">
                          <p className="lead mt-3 mb-0">
                            Title of your review{" "}
                            <span className="text-muted">(required)</span>
                          </p>
                          <input
                            className="form-control"
                            type="text"
                            required
                          />
                          <p className="lead mb-0">Your review</p>
                          <p>
                            Focus on being factual and objective. Don't use
                            aggressive language and don't post personal details.
                          </p>
                          <p id="note_review">
                            Your review content:{" "}
                            <span className="bad" title="bad">
                              weak
                            </span>
                            <span className="not_bad" title="Not bad">
                              Average
                            </span>
                            <span className="good" title="Good">
                              Good
                            </span>
                            <span className="very_good" title="very good">
                              Very good
                            </span>
                            <span className="excellent" title="excellent">
                              Excellent
                            </span>
                          </p>
                          <textarea
                            className="form-control"
                            name=""
                            id=""
                            rows="10"
                            placeholder="State accurate facts and be objective"
                          ></textarea>{" "}
                        </div>
                        <hr />
                        <p className="lead">
                          Proof of purchase{" "}
                          <span className="text-muted">(optional)</span>
                        </p>
                        <div className="mb-3">
                          <input
                            type="file"
                            accept="image/jpeg, image/tiff, image/webp, image/png"
                            type="text"
                            className="form-control"
                            placeholder="Proof of purchase"
                            aria-label="Proof of purchase"
                          />
                        </div>

                        <p className="text-muted">
                          By submitting a review, I accept Veritatrust's Posting
                          Guidelines and Terms of Use Policy.
                        </p>
                        <div className="form__footer">
                          <div className="d-grid gap-2">
                            <button
                              type="button"
                              className="btn-block btn btn-primary btn-lg text-uppercase text-right"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddNewReviewCreatePage;
