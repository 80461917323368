import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";
import CreateLoginModal from "../CreateLoginModal";

function ConnectModal(props) {
  const { t } = useTranslation();

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("ModalLoginOrRegister")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateLoginModal isAuthenticated={false}></CreateLoginModal>
      </Modal.Body>
    </Modal>
  );
}

function LikeComponent({ reviewdata, reviewtype }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const axios = useAxios();
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState([]);
  const [isLikedByUser, setIsLikedByUser] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("userdata"))
  );
  const [userNames, setUserNames] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [userDATA, setUserDATA] = useState(
    JSON.parse(window.localStorage.getItem("userdata"))
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
    axios
      .get(`/`)
      .then((response) => {
        setUser(response.data);
        setUser(userDATA);
        const userID = userDATA.id;
        axios
          .get(`/likes/get/${reviewdata.id}`)
          .then((response) => {
            setLikes(
              response.data.filter((item) => item.like_type === reviewtype)
            );
            const a = response.data.filter(
              (item) => item.like_type === reviewtype && item.userId === userID
            );
            const b = response.data.filter(
              (item) => item.like_type === reviewtype
            );
            const fetchUserNames = () => {
              try {
                // console.log("****************LIKE******************", b);
                const promises = b
                  .map((like) => like.userId)
                  .map((userId) => {
                    return axios.get(`/users/${userId}`).then((response) => {
                      return (
                        response.data.first_name ||
                        response.data.nickname ||
                        "anonymous:" + response.data.id
                      ); // Supposons que la réponse contienne un champ 'name'
                    });
                  });

                Promise.all(promises)
                  .then((names) => {
                    setUserNames(names);
                  })
                  .catch((error) => {
                    // console.error(
                    //   "Erreur lors de la récupération des noms d'utilisateurs",
                    //   error
                    // );
                  });
              } catch (error) {
                // console.error(
                //   "Erreur lors de la récupération des noms d'utilisateurs",
                //   error
                // );
              }
            };

            fetchUserNames();

            if (a.length > 0) {
              setIsLikedByUser(true);
            }
          })
          .catch((error) => {
            // console.error(error);
          });
      })
      .catch((error) => {
        // console.error(error);
      });
  }, [, lang, i18n, reviewdata.id]);

  const [showPopover, setShowPopover] = useState(false);

  const handlePopover = (show) => {
    setShowPopover(show);
  };

  function handleOnclickLike() {
    axios
      .post("/likes", {
        userId: user.id,
        review_id: reviewdata.id,
        like_type: reviewtype,
        status: "1",
      })
      .then((res) => {
        // console.log(res);
        setIsLiked(true);
        setIsLikedByUser(true);

        axios
          .get(`/likes/get/${reviewdata.id}`)
          .then((response) => {
            setLikes(
              response.data.filter((item) => item.like_type === reviewtype)
            );
          })
          .catch((error) => {
            // console.error(error);
          });
      })
      .catch((err) => {});
  }

  function handleOnclickDislike() {
    axios
      .get(`/likes/delete/${user.id}/${reviewtype}/${reviewdata.id}`)
      .then((res) => {
        // console.log(res);
        setIsLiked(false);
        setIsLikedByUser(false);

        axios
          .get(`/likes/get/${reviewdata.id}`)
          .then((response) => {
            setLikes(
              response.data.filter((item) => item.like_type === reviewtype)
            );
          })
          .catch((error) => {
            // console.error(error);
          });
      })
      .catch((err) => {});
  }

  let onClickHandler;
  if (user?.id) {
    onClickHandler = isLikedByUser ? handleOnclickDislike : handleOnclickLike;
  } else {
    onClickHandler = () => setModalShow(true);
  }
  return (
    <>
      <div className="recommanded">
        <OverlayTrigger
          trigger="manual" // Définir le déclencheur manuellement
          placement="bottom"
          show={showPopover}
          overlay={
            <Popover id="popover-positioned-bottom">
              {/* <Popover.Header as="h3">Votre Titre</Popover.Header> */}
              <Popover.Body>
                <ul className="list-group list-group-flush">
                  {userNames.slice(0, 10).map((name, index) => (
                    <li className="list-group-item" key={index}>
                      {name}
                    </li>
                  ))}
                </ul>
              </Popover.Body>
            </Popover>
          }
        >
          <button
            className={
              isLikedByUser
                ? "btn btn-none text-success"
                : "btn btn-none text-primary"
            }
            onMouseEnter={() => handlePopover(true)}
            onMouseLeave={() => handlePopover(false)}
            variant="primary"
            onClick={onClickHandler}
          >
            <i className="flaticon-heart"></i>{" "}
            <span className="d-none d-lg-inline-block">
              {t("ReviewMerchantPageLike")}{" "}
              {likes.length > 0 && <strong>{likes.length}</strong>}
            </span>
          </button>
        </OverlayTrigger>

        <ConnectModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
}

export default LikeComponent;
