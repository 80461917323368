import React, { createContext, useEffect, useState } from "react";
import AllTransaction from "../../components/AllTransaction.jsx";
import ClaimRewards from "../../components/ClaimRewards";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import Rewards from "../../components/Rewards";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import "../../styles/style.css";

export const SharedValueContext = createContext();

function RewardPage() {
  const axios = useAxios();
  const [historic, setHistoric] = useState([]);
  const [sharedValue, setSharedValue] = useState("0");
  const [walletId, setWalletId] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      setWalletId(response.data["userWalletAddress"]);
      setUser(response.data);
      const res = await axios.get(
        "/CashFlowRewardsRoutes/" + response.data["id"]
      );

      if (res.data) {
        // setTotalRewards(12);
        setSharedValue(res.data.TotalCashflow);
      }
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const resp = await axios.get("/");
      const response = await axios.get(
        "/userTransactions/getbyuserid/" + resp.data["id"]
      );
      if (response.data.length > 0) {
        setHistoric(response.data);
      } else {
        setHistoric([]);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <SharedValueContext.Provider value={{ sharedValue, walletId, user }}>
      <DashboardDiv titlepage={t("AccountRewards")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <Rewards></Rewards>
                  <ClaimRewards
                    cryptoValue={sharedValue}
                    voucherValue={sharedValue}
                  ></ClaimRewards>

                  <AllTransaction historic={historic}></AllTransaction>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SharedValueContext.Provider>
  );
}
const mapStateToProps = (state) => ({
  totalRewards: state.auth.totalRewards,
});

export default connect(mapStateToProps)(RewardPage);
