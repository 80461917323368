import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CreateExperienceDateInput({ onChangeExperienceDate }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value; // Récupérez la date au format "YYYY-MM-DD"
    onChangeExperienceDate(selectedDate);
  };

  return (
    <>
      <p className="lead">
        {t("AddReviewForBetaTesterDate")}
        <sup>*</sup>
      </p>
      <div className="mb-3">
        <input
          type="date"
          className="form-control"
          placeholder="Experience date"
          data-date-inline-picker="true"
          max={date.toISOString().slice(0, 10)}
          aria-label="Experience date"
          onChange={handleDateChange}
          required
        />
      </div>
    </>
  );
}

export default CreateExperienceDateInput;
