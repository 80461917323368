import React, { createContext } from "react";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

import moment from "moment";
import MerchantUserModerationComponent from "../../components/MerchantUserModerationComponent";
moment.locale("fr");

export const SharedValueContext = createContext();

function Blog() {
  const merchantUserData = {
    id: 3,
    website: "www.amazon.fr",
    first_name: "Jean",
    last_name: "dupont",
    email: "dupontj@amazon.fr",
    jobTitle: "Moderateur",
    phoneNumber: "+212 6 67 98 88 76",
    corporate_name: "Amazon fr",
    status: "completed",
    merchant_id: "320",
  };
  return (
    <section className="search_results bg-success pb-5">
      <div
        className=" container mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="mt-3">
          <MerchantUserModerationComponent
            merchantUserData={merchantUserData}
          ></MerchantUserModerationComponent>
        </div>
      </div>
    </section>
  );
}

export default Blog;
