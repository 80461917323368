import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrgReviewForm from "../../components/OrgReviewForm";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function OrgReviewFormPage() {
  const axios = useAxios();
  let { product_name } = useParams();

  const [product, setProduct] = useState([]);

  useEffect(() => {
    let fetchReviews = async () => {
      axios
        .get("/products/getone/" + product_name.split("-")[0])
        .then((res) => {
          //.log(res)
          setProduct(res.data);

          //.log(res.data);
        })
        .catch((err) => {
          //.log(err)
        });
    };
    fetchReviews();
  }, []);

  return (
    <>
      <OrgReviewForm data={product} textAreaId="content"></OrgReviewForm>
    </>
  );
}

export default OrgReviewFormPage;
