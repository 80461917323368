import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";

function NotificationComponent({
  propsNotifDate,
  propsNumberFollower,
  propsTypeNotif,
  propsContent,
}) {
  return (
    <>
      {/* <div className="notifications">
        <div className="notification bg-primary text-white">
          <span className="position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle">
            <span className="visually-hidden">New alerts</span>
          </span>
          <div className="d-flex justify-content-between">
            <p>
              <Link to="/account/community/" className="text-white">
                <strong>
                  {propsNotif.notification_type || "new follower"}
                </strong>
              </Link>
            </p>
            <button
              type="button"
              className="btn-close btn-close-white"
              aria-label="Close"
            ></button>
          </div>
          <p className="text-muted">{propsNotif.message}</p>
          <p className="">{moment(propsNotif.createdAt).calendar()}</p>
        </div>
      </div> */}

      <li className="list-group-item d-flex justify-content-between align-items-start position-relative p-2">
        <div>
          <Link
            className="lien_global fw-bold d-block"
            to="/account/community/"
          >
            {propsTypeNotif}
          </Link>
          {propsContent}
          <span className="d-block text-muted">
            <small>{moment(propsNotifDate).calendar()}</small>
          </span>
        </div>
        <span className="badge bg-success rounded-pill">
          {propsNumberFollower}
        </span>
      </li>
    </>
  );
}

export default NotificationComponent;
