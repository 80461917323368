import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import { useEffect } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

function HistoricalTransaction({ data }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });

  return (
    <>
      <tr
        class={
          data.status === "published"
            ? "aos animated finish aos-init"
            : "aos animated pending aos-init"
        }
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <td data-label="Date" class="info-table" scope="row">
          {moment(data.createdAt).subtract(10, "days").calendar()}
        </td>
        <td data-label="Type" class="info-table">
          {data.type}
        </td>
        <td data-label="Type" class="info-table">
          {data.veritacoins == null ? "" : data.veritacoins}
        </td>
        <td data-label="Amount" class="info-table claim-mount">
          {data.cryptoValue !== null ? data.cryptoValue : data.voucherValue}{" "}
          {data.cryptoValue !== null ? data.cryptoCode : "$"} (
          {data.cryptoValue !== null ? "" : data.voucherWebsite})
        </td>

        <td data-label="Statut" class="info-table claim-statut">
          <span class="state"></span>
        </td>
      </tr>
    </>
  );
}

export default HistoricalTransaction;
