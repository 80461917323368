import React, { useState, useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function FollowButton({ userId, followedUserId }) {
  const [isFollowing, setIsFollowing] = useState(false);
  const axios = useAxios();
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    if (userId && followedUserId) {
      // Vérification des valeurs de userId et followedUserId
      // Vérifiez si l'utilisateur est déjà suivi
      const checkFollowStatus = async () => {
        const response = await axios.get(
          `/data/follow/isFollowed/${userId}/?followingId=${followedUserId}`
        );
        if (response)
          setIsFollowing(response.data.status === "1" ? true : false);
      };

      checkFollowStatus();
    }
    // setIsFollowing(followStatus);
  }, [userId, followedUserId]);

  const handleFollow = async () => {
    // Ajouter une nouvelle entrée dans la table Follow
    await axios.post(`/data/follow/`, {
      follower_userId: followedUserId,
      following_userId: userId,
      status: "1",
    });

    // Add Notification

    await axios.post(`/notification/`, {
      userId: userId,
      notification_type: "following",
      status: "1",
      message: "you are followed by " + followedUserId,
    });

    // Mettre à jour l'état du bouton
    setIsFollowing(true);
  };

  const handleUnfollow = async () => {
    // Supprimer l'entrée correspondante dans la table Follow
    await axios.put(`/data/follow/unfollow/${userId}`, {
      follower_userId: followedUserId,
      following_userId: userId,
      status: "0",
    });

    // Mettre à jour l'état du bouton
    setIsFollowing(false);
  };

  const handleClick = () => {
    // Si l'utilisateur est déjà suivi, unfollow. Sinon, follow
    if (isFollowing) {
      handleUnfollow();
    } else {
      handleFollow();
    }
  };

  return (
    <>
      <button className="btn btn-success btn-sm" onClick={handleClick}>
        {isFollowing
          ? t("AccountCommunityUnfollw")
          : t("AccountCommunityFollow")}
      </button>
    </>
  );
}

export default FollowButton;
