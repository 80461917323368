import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/hook/useAxios";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
// import SocialAuthenticatedModal from "../SocialAuthenticateModal";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt/3R6qvoXNLbaLurkSHr
zEV+n850tYp0RNUNby7tBDs1euwIYjUIvd2RmGA6IDtCu/WNVxa5i0XXEepI3O6S
P8im++gjkDfgBiicCyrAgjl9ZN7JAZy8dSYaw0h/YzfU3aN/l04q/GSMFNaHSzFt
HjfrOfAsEgAqNl3YgdMR3MqPfFZsm7N0BEZhfwW/y7HO6+aX45H0KBs4fM8x08NL
Y0dJGsT3lN72FG/KBh2t+9DbidcIB5R5I89LZeLudsb/zMNy94uR4jktkfP2zw0g
bTkwDe7E/Y5ETN7N09KMAroxCBCifKSSjhTHfuUTM15+cYpassxiMx3pZB/eSD5a
qQIDAQAB
-----END PUBLIC KEY-----`;

function CreateLogin({ isAuthenticated }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [urlModal, setUrlModal] = useState("");

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    // Vérifiez si l'URL contient des données d'utilisateur après l'authentification
    const urlParams = new URLSearchParams(window.location.search);
    const userParam = urlParams.get("user");

    if (userParam) {
      // Mettez à jour l'état avec les données d'utilisateur transmises
      setUserData(JSON.parse(decodeURIComponent(userParam)));

      // Fermez la fenêtre popup (vous pouvez également utiliser window.close() ici)
      window.opener.postMessage("close-popup", window.location.origin);
    }
  }, []);

  function handleModalClick() {
    setModalShow(true);

    const clientId =
      "1036726798056-idduh86bhvsjo0mrhuuhoj8l87u4alvi.apps.googleusercontent.com"; // Remplacez par votre propre client ID
    const redirectUri = `https://api.veritatrust.com/api/auth/google/reviewform/callback`; // URL de redirection après l'authentification
    const scope = "profile email"; // Les autorisations requises
    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=token`;
    setUrlModal(authUrl);

    const width = 600;
    const height = 400;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;
    // Ouvrir la fenêtre pop-up
    // const popup = window.open(
    //   authUrl,
    //   "_blank",
    //   `width=${width},height=${height},left=${left},top=${top}`
    // );

    //Écouter les messages provenant de la fenêtre pop-up
  }

  const axios = useAxios();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  //comportement
  function handleChangeConnect(e) {
    const { name, value } = e.target;
    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const handleConnect = (e) => {
    e.preventDefault();

    const payload = JSON.stringify(user);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payload,
      publicKey
    ).toString();

    axios
      .post("/login", user)
      .then((res) => {
        if (res.data["success"] == true) {
          //.log(res.data);
          // window.location.href = window.location.href;
          window.location.href = window.location.href; // Redirige vers la même URL
        } else {
          //.log("Error to connect");
        }
      })
      .catch((err) => {
        //.log(err);
        toast.error("Invalid password or username", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  function storeReturnToClient() {
    const currentUrl = window.location.href;
    localStorage.setItem("returnTo", currentUrl);
  }
  useEffect(() => {
    // Écoutez les messages postés par la fenêtre popup
    window.addEventListener("message", handleMessage);

    return () => {
      // Assurez-vous de retirer le gestionnaire d'événements lorsque le composant est démonté
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event) => {
    if (event.data.user) {
      // Vous pouvez maintenant utiliser les données de l'utilisateur dans votre application React
      const userData = event.data.user;
      //.log(userData);
    }
  };
  return (
    <>
      {!isAuthenticated && (
        <div className="col-12 mb-4">
          <h3 className="text-center">
            {t("AddReviewForBetaTesterConnectToMsg")}
          </h3>
          <div className="d-flex flex-column flex-md-row gap-2 justify-content-center">
            <a
              className="btn btn-primary"
              style={{ backgroundColor: "#002d6b" }}
              href={`https://api.veritatrust.com/api/auth/facebook/reviewform?returnTo=${encodeURIComponent(
                window.location.href
              )}&lang=${lang}`}
              onClick={() => storeReturnToClient()}
            >
              {" "}
              {t("SigninWithFacebook")}{" "}
            </a>

            <a
              className="btn btn-primary"
              style={{ backgroundColor: "#002d6b" }}
              href={`https://api.veritatrust.com/api/auth/google/reviewform?returnTo=${encodeURIComponent(
                window.location.href
              )}&lang=${lang}`}
              onClick={() => storeReturnToClient()}
            >
              {" "}
              {t("SigninWithGoogle")}
            </a>

            <a href="#" className="btn btn-primary d-none">
              Sign in with Apple
            </a>
            {/* <button className="btn btn-primary" onClick={handleModalClick}>
              Test modal
            </button>
            <SocialAuthenticatedModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              src={urlModal}
            /> */}

            <div class="dropdown">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                {t("AddReviewForBetaTesterSignWithEmail")}
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <form className="px-3" id="login" onSubmit={handleConnect}>
                  <div className="">
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        value={user.email}
                        onChange={handleChangeConnect}
                        name="email"
                        id="usermail"
                        aria-describedby="emailHelp"
                        placeholder={t(
                          "AddReviewForBetaTesterPlaceHolderUserEmail"
                        )}
                      />
                      <span className="input-group-text bg-success text-white border-success">
                        <i className="flaticon-user"></i>
                      </span>
                    </div>
                    <div id="emailHelp" className="form-text mb-3"></div>
                    <div className="input-group mb-3 ">
                      <input
                        type="password"
                        className="form-control"
                        value={user.password}
                        onChange={handleChangeConnect}
                        id="userpassword"
                        name="password"
                        placeholder={t(
                          "AddReviewForBetaTesterPlaceHolderPassword"
                        )}
                      />
                      <span className="input-group-text bg-success toggle-password text-white border-success">
                        <i className="flaticon-hide"></i>
                      </span>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberme"
                        checked={user.stayConnected && true}
                        onChange={handleChangeConnect}
                      />
                      <label className="form-check-label" htmlFor="rememberme">
                        {t("HeaderNavbarConnected")}
                      </label>
                    </div>
                    <div className="d-grid gap-2">
                      <button
                        type="submit"
                        className="btn-block btn btn-success text-white"
                        id="userbutton"
                      >
                        {t("HeaderSignin")}
                      </button>
                    </div>
                  </div>
                </form>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href={"/" + t("lang") + "/signup"}>
                  {t("AddReviewForBetaTesterNewAroundHere")}
                </a>
                <Link
                  className="dropdown-item"
                  to={"/" + t("lang") + "/resetpassword"}
                >
                  {t("HeaderNavbarPass")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateLogin;
