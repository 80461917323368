import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import togglePassword from "../../utils/tools/togglePassword";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt/3R6qvoXNLbaLurkSHr
zEV+n850tYp0RNUNby7tBDs1euwIYjUIvd2RmGA6IDtCu/WNVxa5i0XXEepI3O6S
P8im++gjkDfgBiicCyrAgjl9ZN7JAZy8dSYaw0h/YzfU3aN/l04q/GSMFNaHSzFt
HjfrOfAsEgAqNl3YgdMR3MqPfFZsm7N0BEZhfwW/y7HO6+aX45H0KBs4fM8x08NL
Y0dJGsT3lN72FG/KBh2t+9DbidcIB5R5I89LZeLudsb/zMNy94uR4jktkfP2zw0g
bTkwDe7E/Y5ETN7N09KMAroxCBCifKSSjhTHfuUTM15+cYpassxiMx3pZB/eSD5a
qQIDAQAB
-----END PUBLIC KEY-----`; /* Clé publique récupérée du serveur */

function PasswordChangePage() {
  const axios = useAxios();
  const token = useParams();

  const [values, setValues] = useState({
    email: "",
    actualpassword: "",
    confirmpassword: "",
  });

  useEffect(() => {
    togglePassword();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      token: token.token,
      actualpassword: values.actualpassword,
    };

    const payload = JSON.stringify(dataUser);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payload,
      publicKey
    ).toString();

    if (values.actualpassword == values.confirmpassword) {
      axios
        .post("/changepassword", dataUser)
        .then((res) => {
          //.log(res);

          toast.success("Password saved sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          window.location.href = "/signin";
        })
        .catch((err) => {
          //.log(err);
          toast.error("server url don't not exist", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      toast.error("Password must be the same", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <section class="py-5 form">
        <form action="" onSubmit={handleSubmit}>
          <div class="container">
            <div class="row d-flex align-items-center">
              <div class="col-11 col-lg-8 pt-3 mx-auto text-center">
                <h3>Password reset</h3>
              </div>
              <div class="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
                <div class="form__account">
                  <div class="row d-flex flex-column align-items-center">
                    <div class="col-12 col-md-6 g-0 mx-auto">
                      <div class="orbymail text-center">
                        <div class="separator">Reset your password</div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="input-group mb-3">
                        <input
                          type="password"
                          class="form-control editable"
                          name="actualpassword"
                          value={values.actualpassword}
                          onChange={handleChange}
                          id="actualpassword"
                        />
                        <span class="input-group-text bg-success toggle-password text-white border-success">
                          <i class="flaticon-hide"></i>
                        </span>
                      </div>

                      <hr />
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="input-group mb-3">
                        <input
                          type="password"
                          class="form-control editable"
                          name="confirmpassword"
                          value={values.confirmpassword}
                          onChange={handleChange}
                          id="confirmpassword"
                        />
                        <span class="input-group-text bg-success toggle-password text-white border-success">
                          <i class="flaticon-hide"></i>
                        </span>
                      </div>

                      <hr />
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="form__footer">
                        <div class="d-grid gap-2">
                          <button
                            type="submit"
                            class="btn-block btn btn-success text-white btn-lg"
                            id="signinbutton"
                          >
                            Reset password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}

export default PasswordChangePage;
