import React, { useEffect, useState } from "react";

import { useAxios } from "../../utils/hook/useAxios";
import star5 from "../../assets/star-5.jpg";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import { Link } from "react-router-dom";
import NoPicture from "../../assets/no-picture.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function ProductMerchantItem({ propsData }) {
  const axios = useAxios();
  let srcimg = star1;
  const [productRoute, setProductRoute] = useState(null);
  const [merchantRoute, setMerchantRoute] = useState(null);
  const [categoryProduct, setCategoryProduct] = useState("");

  const product_name = propsData.product_name;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, category } = useParams();
  let srcvalue = "star" + parseInt(propsData.ReviewMean).toString();

  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
  }

  useEffect(() => {
    axios
      .get("/data/categories/get-category-id/" + propsData.category_id)
      .then((res) => {
        setCategoryProduct(res.data.category_name);
      });
  }, [propsData.category_id]);
  const formatProduct_name = () => {
    var formatedName = product_name;

    const accents = {
      a: /[àáâãäå]/g,
      e: /[èéêë]/g,
      i: /[ìíîï]/g,
      o: /[òóôõö]/g,
      u: /[ùúûü]/g,
      y: /[ýÿ]/g,
      n: /[ñ]/g,
      c: /[ç]/g,
    };

    for (let char in accents) {
      formatedName = formatedName.replace(accents[char], char);
    }

    formatedName = formatedName.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();

    setProductRoute(
      "/" + lang + "/review-product/" + propsData.id + "-" + formatedName
    );
    setMerchantRoute("/" + lang + "/review-merchant/" + product_name);

    return formatedName;
  };

  useEffect(() => {
    formatProduct_name();
  });
  return (
    <div
      className={
        "result star-" +
        parseInt(propsData.ReviewMean) +
        " d-md-flex align-items-center mb-2 mb-lg-4 aos animated aos-init aos-animate"
      }
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
    >
      <div className="item categories">
        <Link to={propsData.type === "product" ? productRoute : merchantRoute}>
          <span className="small text-success">{categoryProduct}</span>
          <h3 className="product__name h5 mb-1">{propsData.product_name}</h3>
          <span className="lien_global"></span>
        </Link>
        <div className="notation d-md-flex align-items-center">
          <div className="d-flex align-items-center">
            <img
              className=""
              src={srcimg}
              alt="notation"
              width="100"
              height="40"
            />
            <p className="mb-0 mx-3">
              <strong>{propsData.ReviewMean.toFixed(1)}</strong>,{" "}
              {t("CategoriePageFrom")}{" "}
              <strong>
                {propsData.ReviewsNumber} {t("CategoriePageReviews")}
              </strong>
              {/* <span className="text-muted">
                {" "}
                | {propsData.PageViewsNb} views
              </span> */}
            </p>
          </div>
        </div>
      </div>
      <div className="logo ms-auto">
        <img
          className="review__img__profil"
          src={propsData.aw_image_url || NoPicture}
          alt="Product name"
          height="100"
          width="100"
        />
      </div>
    </div>
  );
}

export default ProductMerchantItem;
