import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

function StateProfile({
  isBasicInfoComplete,
  isKYCComplete,
  isMetamaskComplete,
}) {
  // Account verifications  return Class name
  const isBasicInfo = () => {
    if (isBasicInfoComplete === true) return ["complete", "pending d-none"];
    else return ["complete d-none", "pending"];
  };

  const isKYC = () => {
    if (isKYCComplete === true) return ["complete", "pending d-none"];
    else return ["complete d-none", "pending"];
  };

  const isMetamask = () => {
    if (isMetamaskComplete === true) return ["complete", "pending d-none"];
    else return ["complete d-none", "pending"];
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <div
        className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <h4 className="lead">{t("AccountYourProfile")}</h4>
        <div className="row border border-bottom-0 border-end-0 profil_state mb-3">
          <div className="col-lg-4 border-end border-bottom p-2">
            <p className="mb-0">
              {t("AccountYourProfileBasicInfo")}
              <span className={isBasicInfo()[0]}>
                <i className="flaticon-check flaticon-complete"></i>{" "}
                {t("AccountVTComplete")}
              </span>
              <span className={isBasicInfo()[1]}>
                <i className="flaticon-time-left  flaticon-pending"></i>{" "}
                {t("AccountVTPending")}
              </span>
            </p>
          </div>
          <div className="col-lg-4 border-end border-bottom p-2">
            <p className="mb-0">
              {t("AccountYourProfileKYC")}
              <span className={isKYC()[0]}>
                <i className="flaticon-check flaticon-complete"></i>{" "}
                {t("AccountVTComplete")}
              </span>
              <span className={isKYC()[1]}>
                <i className="flaticon-time-left  flaticon-pending"></i>{" "}
                {t("AccountVTPending")}
              </span>
            </p>
          </div>
          <div className="col-lg-4 border-end border-bottom p-2">
            <p className="mb-0">
              {t("AccountYourProfileMetamask")}
              <span className={isMetamask()[0]}>
                <i className="flaticon-check flaticon-complete"></i>{" "}
                {t("AccountVTComplete")}
              </span>
              <span className={isMetamask()[1]}>
                <i className="flaticon-time-left  flaticon-pending"></i>{" "}
                {t("AccountVTPending")}
              </span>
            </p>
          </div>
        </div>

        <div className="d-grid gap-2 col-lg-6 mx-auto">
          <Link
            className="btn btn-success d-block mt-2 text-white"
            to="/account/settings/"
          >
            {t("AccountYourProfileComplete")}
          </Link>
        </div>
      </div>
    </>
  );
}

export default StateProfile;
