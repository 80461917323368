import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function AboutMerchant({ title, merchantProfileValue }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  let { website } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [merchantdata, setmerchantdata] = useState(null);

  const [selectedCategoriesMsg, setSelectedCategoriesMsg] = useState([]);
  const [categories, setCategories] = useState([]);

  const axios = useAxios();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`/merchant_profiles/${website}`)
  //     .then((response) => {
  //       setSearchResults(response.data);
  //       // setSharedValue(response.data.ReviewsNumber);
  //       setmerchantdata(response.data);
  //       return axios.get(`/merchant_profiles/${website}`);
  //     })
  //     .then((response) => {
  //       setmerchantdata(response.data);
  //       // setMerchantProfileValue(response.data);
  //       setSelectedCategoriesMsg([
  //         response.data.category_1,
  //         response.data.category_2,
  //         response.data.category_3,
  //       ]);
  //       const categoriesMsg = [
  //         response.data.category_1,
  //         response.data.category_2,
  //         response.data.category_3,
  //       ];
  //       return Promise.all(
  //         categoriesMsg.map((categoryId) => {
  //           if (categoryId) {
  //             return axios.get(
  //               `/data/categories/get-category-id/${categoryId}`
  //             );
  //           } else {
  //             return Promise.resolve(null);
  //           }
  //         })
  //       );
  //     })
  //     .then((categoriesInfo) => {
  //       const filteredCategoriesInfo = categoriesInfo.filter(
  //         (categoryInfo) => categoryInfo !== null
  //       );
  //       setCategories(
  //         filteredCategoriesInfo.map((response) => response.data.vt_category)
  //       );
  //     })
  //     .catch((error) => {
  //       //.error(error);
  //     });
  // }, []);
  useEffect(() => {
    axios
      .get(`/merchant_profiles/${website}`)
      .then((response) => {
        setSearchResults(response.data);

        setmerchantdata(response.data);
        return axios.get(`/merchant_profiles/${website}`);
      })
      .then((response) => {
        setmerchantdata(response.data);

        setSelectedCategoriesMsg([
          response.data.category_1,
          response.data.category_2,
          response.data.category_3,
        ]);
        const categoriesMsg = [
          response.data.category_1,
          response.data.category_2,
          response.data.category_3,
        ];
        return Promise.all(
          categoriesMsg.map((categoryId) => {
            if (categoryId) {
              return axios.get(
                `/data/categories/get-category-id/${categoryId}`
              );
            } else {
              return Promise.resolve(null);
            }
          })
        );
      })
      .then((categoriesInfo) => {
        const filteredCategoriesInfo = categoriesInfo.filter(
          (categoryInfo) => categoryInfo !== null
        );
        if (window.localStorage.getItem("langPage") === "en") {
          setCategories(
            filteredCategoriesInfo.map(
              (response) => response.data.category_name
            )
          );
        } //[`category_name_${window.localStorage.getItem("langPage")}`]
        else {
          setCategories(
            filteredCategoriesInfo.map(
              (response) =>
                response.data[
                  `category_name_${window.localStorage.getItem("langPage")}`
                ]
            )
          );
        }
      })
      .catch((error) => {
        //.error(error);
      });
  }, [, lang]);
  return (
    <>
      <div
        className="bg-white p-3 mb-3 rounded-5 shadow widget about aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="header d-flex">
          <h4>
            <strong>
              {t("ReviewMerchantPageAbout")} {merchantProfileValue.name}
            </strong>
          </h4>
        </div>
        <div className="content">
          <p>{merchantProfileValue.description}</p>
          <div className="contact">
            <h4>
              <strong> {t("ReviewMerchantPageContact")}</strong>
            </h4>
            <ul className="ps-0">
              <li className="d-flex">
                <div className="icon">
                  <i className="flaticon-location"></i>
                </div>
                <div className="text">{merchantProfileValue.country_id}</div>
              </li>
            </ul>
          </div>
          <div className="category">
            <h4>
              <strong> {t("ReviewMerchantPageCategory")}</strong>
            </h4>
            <ul className="ps-0">
              <li className="d-flex">
                <div className="text">
                  <span className="badge text-bg-primary me-2">
                    {categories[0]}
                  </span>
                  <span className="badge text-bg-primary me-2">
                    {categories[1]}
                  </span>
                  <span className="badge text-bg-primary">{categories[2]}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  sharedValue: state.auth.sharedValue,
  merchantProfileValue: state.auth.merchantProfileValue,
});

export default connect(mapStateToProps)(AboutMerchant);
