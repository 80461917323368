import React, { useEffect, useState } from "react";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import VoucherIdComponent from "../../components/VoucherIdComponent";
function VoucherItemPage() {
  const [cashflow, setCashflow] = useState(0);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (window.localStorage.getItem("me")) {
      const retrievedObject = JSON.parse(window.localStorage.getItem("me"));
      setCashflow(retrievedObject.TotalCashflow);
      setUserId(retrievedObject.userprofile.id);
    }
  }, []);

  return (
    <>
      {" "}
      <DashboardDiv titlepage="Vouchers" />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div class="dashboard col-12 col-lg-9">
                  <VoucherIdComponent
                    urlImage={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAwGfVrGFkO893novdAdclW2f7vePVC6g_1Q&usqp=CAU"
                    }
                    website={"wolfy.fr"}
                    cashflow={cashflow}
                    userid={userId}
                  ></VoucherIdComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VoucherItemPage;
