import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function Filtering({
  onFilterChange,
  PropsPropotion,
  onLanguageChange,
  sharedValue,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    5: false,
    4: false,
    3: false,
    2: false,
    1: false,
  });

  useEffect(() => {
    handleFilterChange();
  }, [checkboxes, selectedLanguage]);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
  }, []);

  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  }

  function handleFilterChange() {
    const ratingFilters = Object.keys(checkboxes)
      .filter((key) => checkboxes[key])
      .join(",");

    const currentUrlParams = new URLSearchParams(window.location.search);

    //.log("ratingsfilters", ratingFilters);
    //currentUrlParams.set("stars", ratingFilters);

    currentUrlParams.set("stars", ratingFilters);

    //.log("currentUrlParams", currentUrlParams);
    // const newUrl = `${window.location.pathname}?${currentUrlParams.toString()}`;

    if (ratingFilters.length > 0) {
      const params = { stars: ratingFilters };
      const newUrl = `${window.location.pathname}?${Object.entries(params)
        .map((e) => e.join("="))
        .join("&")}`;
      window.history.pushState({}, "", newUrl);
      onFilterChange(currentUrlParams);
    } else {
      const newUrl = window.location.pathname;
      window.history.pushState({}, "", newUrl);

      // Supprimer le paramètre "stars"
      currentUrlParams.delete("stars");

      onFilterChange(currentUrlParams);
    }
  }

  return (
    <>
      <div
        className="bg-white p-3 mb-3 rounded-5 shadow reviews-filter aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <div className="d-lg-flex justify-content-between">
          <h2 className="d-flex">
            {t("FilteringReviews")} •{" "}
            <span className="ms-2">{sharedValue}</span>
          </h2>
          <select
            className="form-select d-flex"
            aria-label="Default select example"
            onChange={(e) => onLanguageChange(e.target.value)}
          >
            <option value="">{t("FilteringAllLangages")}</option>
            <option value="27">{t("FileringEnglish")}</option>
            <option value="22">{t("FilteringFrench")}</option>
            <option value="45">{t("FilteringItalian")}</option>
          </select>
        </div>
        <hr />

        <div className="filters">
          <label htmlFor="excellent" className="lead d-grid">
            <input
              className="form-check-input"
              name="5"
              type="checkbox"
              id="excellent"
              checked={checkboxes["5"]}
              onChange={handleCheckboxChange}
            />
            <small>{t("FilteringExcellent")}</small>
            <small>{parseInt(PropsPropotion.excellent)}%</small>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="Tres positif"
                style={{ width: PropsPropotion.excellent + "%" }}
                aria-valuenow={PropsPropotion.excellent}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </label>
          <label htmlFor="Very Good" className="lead d-grid">
            <input
              className="form-check-input"
              name="4"
              type="checkbox"
              id="very_good"
              checked={checkboxes["4"]}
              onChange={handleCheckboxChange}
            />
            <small>{t("FilteringVeryGood")}</small>
            <small>{parseInt(PropsPropotion.very_good)}%</small>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="Tres positif"
                style={{ width: PropsPropotion.very_good + "%" }}
                aria-valuenow={PropsPropotion.very_good}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </label>
          <label htmlFor="good" className="lead d-grid">
            <input
              className="form-check-input"
              name="3"
              type="checkbox"
              id="good"
              checked={checkboxes["3"]}
              onChange={handleCheckboxChange}
            />
            <small>{t("FilteringGood")}</small>
            <small>{parseInt(PropsPropotion.good)}%</small>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="Tres positif"
                style={{ width: PropsPropotion.good + "%" }}
                aria-valuenow={PropsPropotion.good}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </label>
          <label htmlFor="bad" className="lead d-grid">
            <input
              className="form-check-input"
              name="2"
              type="checkbox"
              id="bad"
              checked={checkboxes["2"]}
              onChange={handleCheckboxChange}
            />
            <small>{t("FilteringBad")}</small>
            <small>{parseInt(PropsPropotion.bad)}%</small>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="Tres positif"
                style={{ width: PropsPropotion.bad + "%" }}
                aria-valuenow={PropsPropotion.bad}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </label>
          <label htmlFor="very_bad" className="lead d-grid">
            <input
              className="form-check-input"
              name="1"
              type="checkbox"
              id="very_bad"
              checked={checkboxes["1"]}
              onChange={handleCheckboxChange}
            />
            <small>{t("FilteringVeryBad")}</small>
            <small>{parseInt(PropsPropotion.very_bad)}%</small>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="Tres positif"
                style={{ width: PropsPropotion.very_bad + "%" }}
                aria-valuenow={PropsPropotion.very_bad}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  sharedValue: state.auth.sharedValue,
});

export default connect(mapStateToProps)(Filtering);
