import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Web3 from "web3";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function MetamaskAssociationCTA({ metamaskAssociatedProp, idprop }) {
  const axios = useAxios();
  const [userWalletAddress, setUserWalletAddress] = useState(null);
  const [web3, setWeb3] = useState(null);
  const { t } = useTranslation();

  // on component mount, check for ethereum extension and set web3 instance
  useEffect(() => {
    const initializeWeb3 = async () => {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
        } catch (error) {
          //.error(error);
        }
      } else {
        //.log("Please install MetaMask or any Ethereum Extension Wallet");
      }
    };
    initializeWeb3();
  }, []);

  // login function
  const loginWithMetamask = async () => {
    if (web3) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const selectedAccount = accounts[0];
        setUserWalletAddress("selectedAccount");
        //.log(selectedAccount);
        // window.localStorage.setItem("userWalletAddress", selectedAccount);

        // send userWalletAddress to server to create session

        axios
          .post("/connect-authweb3", {
            id: idprop,
            userWalletAddress: selectedAccount,
          })
          .then((res) => {
            //.log(res);
            if (res.data) {
              //.log("welcome in dashboard");
              //window.location.href = "/user-dashboard";
              //.log(res);
            } else {
              //.log("Error to connect");
            }
          })
          .catch((err) => {
            //.log(err);
          });
      } catch (error) {
        //.error(error);
      }
    } else {
      //.log("Wallet not found");
      window.alert("Please install MetaMask or any Ethereum Extension Wallet");
    }
  };

  // logout function
  const logout = () => {
    setUserWalletAddress(null);
    //  window.localStorage.removeItem("userWalletAddress");
  };

  // on component mount, check for existing userWalletAddress in localStorage and update state
  useEffect(() => {
    const storedAddress = ""; // window.localStorage.getItem("userWalletAddress");
    if (storedAddress) {
      setUserWalletAddress(storedAddress);
    }
  }, []);

  return (
    <>
      {metamaskAssociatedProp == true ? (
        <button type="button" className="btn btn-success" disabled={true}>
          <i className="flaticon-logo-metamask-fil"></i>{" "}
          {t("AccountProfileConnectedMetamask")}
          <i class="flaticon-check ms-auto"></i>
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={loginWithMetamask}
        >
          <i className="flaticon-logo-metamask-fil"></i>{" "}
          {t("AccountProfileConnectMetamask")}
        </button>
      )}
    </>
  );
}

export default MetamaskAssociationCTA;
