import React from "react";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function CategoriesDiv({ subpage }) {
  const [typeheader, setTypeheader] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, category } = useParams();

  useEffect(() => {
    const Url = window.location.href;
    setTypeheader(Url.split("/")[3]);
  }, []);

  return (
    <section className="py-lg-5">
      <div className="container user_profil">
        <div className="row d-flex align-items-center">
          <div className="col-11 pt-lg-3 py-1 mx-auto">
            <h1 className="display-6 text-primary">
              {subpage
                .split("-")
                [subpage.split("-").length - 1].replaceAll("%20", " ")}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 mb-md-3">
                <li className="breadcrumb-item">
                  <a href="./">
                    <i className="flaticon-home"></i> {t("AccountBreadcrumb1")}
                  </a>
                </li>
                {typeheader === "user-dashboard" ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                ) : (
                  <>
                    <li className="breadcrumb-item active" aria-current="page">
                      {subpage.replace("-", " / ").replaceAll("%20", " ")}
                    </li>
                  </>
                )}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CategoriesDiv;
