import { useState, useEffect, useContext } from "react";
import "../../styles/style.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import { connect } from "react-redux";
import { useAxios } from "../../utils/hook/useAxios";
import portrait from "../../assets/lorem-portrait.jpg";
import FollowButton from "../FollowButton";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function FollowComponent({ proposFollow, propsUserIdFollowerd, propsAction }) {
  const axios = useAxios();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const [user, setUser] = useState({});
  // const [followStatus, setFollowStatus] = useState(false);

  useEffect(() => {
    let fetchReviews = async () => {
      if (propsAction === "follower") {
        const response = await axios.get(
          "/users/" + proposFollow.follower_userId
        );
        setUser(response.data);
        //.log("user");
        //.log(user);
        // setFollowStatus(false);
      } else if (propsAction === "following") {
        const response = await axios.get(
          "/users/" + proposFollow.following_userId
        );
        setUser(response.data);
        // setFollowStatus(true);
        //.log("user");
        //.log(user);
      } else {
        const response = await axios.get("/users/" + proposFollow.id);
        setUser(response.data);
        // setFollowStatus(true);
        //.log("user");
        //.log(user);
      }
    };
    fetchReviews();
  }, []);

  const levelNames = ["Newbie", "Bronze", "Silver", "Gold", "Platinum"];

  return (
    <>
      <li className="claim__item">
        <div className="userpicture">
          <a href={"/" + t("lang") + "/users/" + user.id}>
            <img
              className="user-profil-avatar"
              src={user.profile_url || portrait}
              alt="user-avatar"
              height="100"
              width="100"
            />
          </a>
        </div>
        <p className="mb-0">
          <strong>{user.nickname}</strong>
          <br />
          <span className="level0">
            {levelNames[user.level_account]} influencer
          </span>
        </p>
        <p className="ms-auto">
          <FollowButton
            userId={user.id}
            followedUserId={propsUserIdFollowerd}
          ></FollowButton>
        </p>
      </li>
    </>
  );
}

export default FollowComponent;
