import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { FacebookShareButton, TwitterShareButton } from "react-share";

function ShareComponent({ Url }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="share">
        {["bottom"].map((placement) => (
          <OverlayTrigger
            trigger="focus"
            key={placement}
            placement={placement}
            overlay={
              <Popover id={`popover-positioned-${placement}`}>
                <Popover.Body>
                  <div className="d-flex gap-2 justify-content-center">
                    <FacebookShareButton url={Url} className="">
                      <a className="" href="#">
                        <i class="flaticon-facebook-app-symbol"></i>
                      </a>
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={Url}
                      className="btn btn-outline-secondary"
                    >
                      <a className="" href="#">
                        <i class="flaticon-twitter-1"></i>
                      </a>
                    </TwitterShareButton>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <button className="btn btn-none text-primary" variant="secondary">
              <i className="flaticon-network"></i>{" "}
              <span className="d-none d-lg-inline-block">
                {t("ReviewMerchantPageShare")}
              </span>
            </button>
          </OverlayTrigger>
        ))}
      </div>
    </>
  );
}

export default ShareComponent;
