import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ReportedReviewItem({ propsData }) {
  const axios = useAxios();
  const [user, setUser] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [responseDataUser, setResponseDataUser] = useState([]);

  const [responseReport, setResponseReport] = useState("");

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const levels = ["Newbie", "Bronze", "Gold", "Platinum"];

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const response = await axios.get(
          "/reportreview/responses/" + propsData.id
        );

        if (response.data) {
          setResponseData(response.data);
        } else setResponseData([]);

        const responseUser = await axios.get(
          "/supportmessages/reportreviewid/" + propsData.id
        );
        if (responseUser.data) {
          setResponseDataUser(responseUser.data);
        } else setResponseDataUser([]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchResponses();
  }, [propsData.id]);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Empêche la soumission par défaut du formulaire

    const VeritaTrustUserData = await axios.get("/admin");

    try {
      // Effectuer la requête POST
      const dataSend = {
        ReportReviewId: propsData.id,
        SupportUserId: VeritaTrustUserData.data.id || "8900011",
        Message: responseReport,
      };
      const res = await axios.post("/reportreview/response", dataSend);
      // const notifications = await axios.post("/notification", {
      //   userId: propsData.reportUserId,
      //   notification_type: "support",
      //   status: "0",
      //   message: responseReport,
      // });

      const messages = await axios.post("/supportmessages", {
        issueType: "other",
        subject: "Reporting with support",
        status: "0",
        message: responseReport,
        reportReviewId: propsData.id,
        userId: propsData.reportUserId,
        isWritingBy: "support",
      });

      const response = await axios.get(
        "/reportreview/responses/" + propsData.id
      );

      if (response) setResponseData(response.data);

      console.log(res.data);
    } catch (error) {
      // Gérer les erreurs éventuelles
      console.error(error);
    }
  };
  const handleChange = (event) => {
    setResponseReport(event.target.value);
    console.log(event.target.value); // Mettre à jour la valeur de la réponse dans le state
  };

  return (
    <>
      <div class="personal-data mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4">
        <div class="title">
          <p class="lead me-auto">
            Reported review type -{" "}
            <span class="badge bg-success">
              {propsData.reviewType.replace("_", " ")}
            </span>
          </p>
          <button class="btn btn-danger ms-2">Reject</button>
          <button class="btn btn-success ms-2">Approuve</button>
        </div>
        <div class="row">
          <div class="col-md-3 mb-3">
            <figure class="figure userpicture">
              <figcaption class="figure-caption">
                <button
                  class="btn btn-primary text-white me-3 preview"
                  type="button"
                >
                  More details
                </button>
              </figcaption>
            </figure>

            <p>Reported at: {moment(propsData.updatedAt).calendar()}</p>
            <p>
              Reporter name:{" "}
              <span className="text-muted">
                {propsData.first_name} {propsData.last_name}
              </span>
            </p>
            <p>
              Reporter level:{" "}
              <span className="badge bg-warning">
                {levels[propsData.level_account]}
              </span>
            </p>
          </div>
          <div class="col-md mb-3">
            <dl class="row">
              <dt class="col-sm-3">Report source</dt>
              <dd class="col-sm-9">{propsData.reportSource}</dd>

              <dt class="col-sm-3">Motif</dt>
              <dd class="col-sm-9">
                <p>{propsData.motif}</p>
              </dd>

              <dt class="col-sm-3">Message</dt>
              <dd class="col-sm-9">{propsData.content}</dd>

              <dt class="col-sm-3 text-truncate">Review Title</dt>
              <dd class="col-sm-9">{propsData.mtitle || propsData.ptitle}</dd>

              <dt class="col-sm-3">Review Content</dt>
              <dd class="col-sm-9">
                {propsData.mcontent || propsData.pcontent}
              </dd>
              <dt class="col-sm-3">Order id</dt>
              <dd class="col-sm-9">{propsData.morderid}</dd>
            </dl>

            <div class="reject mb-3">
              <div class="d-flex align-items-start mt-3">
                {/* <div
                  class="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    class="nav-link active"
                    id="v-pills-responses-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-responses"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-responses"
                    aria-selected="true"
                  >
                    Responses
                  </button>
                  <button
                    class="nav-link"
                    id="v-pills-report-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-report"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-report"
                    aria-selected="false"
                    tabindex="-1"
                  >
                    Report
                  </button>
                </div> */}
                <div class=" w-100">
                  <div class="">
                    <form action="" onSubmit={handleSubmit}>
                      <div class="d-md-flex gap-3 mb-3">
                        <textarea
                          class="form-control"
                          id="message"
                          rows="2"
                          placeholder="Your response"
                          value={responseReport}
                          onChange={handleChange}
                        ></textarea>
                        <button
                          type="submit"
                          class="btn btn-primary mt-2 mt-md-0"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                    <div className="responses mb-3">
                      {responseData &&
                        responseData.slice(0, 1).map((response, index) => (
                          <div className="answer">
                            <div className="d-flex justify-content-between">
                              <p className="author">
                                by{" "}
                                <a href="#" target="_blank">
                                  {response.VeritaTrustUserName?.replace(
                                    "null",
                                    ""
                                  ).replace("(null)", "")}
                                </a>
                              </p>
                              <p>
                                {" "}
                                {moment(response.createdAt).format(
                                  "MMM DD, YYYY"
                                )}
                              </p>
                            </div>
                            <p className="text-success">{response.Message}</p>
                          </div>
                        ))}
                      {responseDataUser &&
                        responseDataUser.slice(0, 1).map((response, index) => (
                          <div className="answer">
                            <div className="d-flex justify-content-between">
                              <p className="author">
                                by{" "}
                                <a href="#" target="_blank">
                                  user
                                </a>
                              </p>
                              <p>
                                {" "}
                                {moment(response.createdAt).format(
                                  "MMM DD, YYYY"
                                )}
                              </p>
                            </div>
                            <p className="text-primary">{response.message}</p>
                          </div>
                        ))}
                      <div className="my-2">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                        >
                          Read more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportedReviewItem;
