import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import WriteReviewStar from "../../assets/star-3.svg";
import star0 from "../../assets/star-1.png";
import { Link } from "react-router-dom";

function WriteReviewDiv({ website }) {
  const linkurl = "/evaluate/" + website;
  return (
    <>
      <div
        className="bg-white p-3 mb-2 mb-md-5 rounded-5 shadow add-review-express d-flex justify-content-between aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <div className="profil d-flex">
          <img
            className="me-3 d-none d-lg-block"
            src={star0}
            width="50"
            height="50"
          />
          <p>
            <Link to={linkurl}>Write a review</Link>
          </p>
        </div>
        <div className="form__star">
          <img src={WriteReviewStar} alt="reviews" />
        </div>
      </div>
    </>
  );
}

export default WriteReviewDiv;
