import { useEffect } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
// import AOS from "aos";
// import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import HistoricalTransaction from "../HistoricalTransaction";

function AllTransaction({ historic }) {
  // const [historic, setHistoric] = useState([]);
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <div
        class="claim__system mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div class="title">
          <p class="lead me-auto">{t("AccountRewardsAllTransaction")}</p>
        </div>
        <table class="table mb-3">
          <thead>
            <tr>
              <th class="claim-date" scope="col">
                {t("AccountRewardsAllTransactionDate")}
              </th>
              <th class="claim-type" scope="col">
                {t("AccountRewardsAllTransactionType")}
              </th>
              <th class="claim-type" scope="col">
                {t("AccountRewardsAllTransactionVeritaCoins")}
              </th>
              <th class="claim-mount" scope="col">
                {t("AccountRewardsAllTransactionAmount")}
              </th>
              <th class="claim-statut" scope="col">
                {t("AccountRewardsAllTransactionStatus")}
              </th>
            </tr>
          </thead>
          <tbody>
            {historic &&
              historic.map((transaction, index) => (
                <HistoricalTransaction
                  data={transaction}
                  key={index}
                ></HistoricalTransaction>
              ))}
          </tbody>
        </table>
        {historic.length === 0 && (
          <div className="callout callout-info">
            <p className="mb-0">
              {t("AccountRewardsAllTransactionNoTransaction")}
            </p>
          </div>
        )}
      </div>
      {/* <nav className="mt-5" aria-label="...">
        <ul className="pagination pagination-lg justify-content-center">
          <li
            className={
              location.pathname === "/dashboard/rewards?page=2"
                ? "page-item active"
                : "page-item"
            }
            aria-current="page"
          >
            <span className="page-link">1</span>
          </li>
          <li
            className={
              location.pathname === "/dashboard/rewards?page=2"
                ? "page-item active"
                : "page-item"
            }
          >
            <a className="page-link" href="/dashboard/rewards?page=2">
              2
            </a>
          </li>
          <li
            className={
              location.pathname === "/dashboard/rewards?page=2"
                ? "page-item active"
                : "page-item"
            }
          >
            <a className="page-link" href="/dashboard/rewards?page=3">
              3
            </a>
          </li>
        </ul>
      </nav> */}
    </>
  );
}

export default AllTransaction;
