import React from "react";
import QRCode from "react-qr-code";

function QRCodeImage({ base32Secret }) {
  const otpauthUrl = `otpauth://totp/VeritaTrust?secret=${base32Secret}`;

  return (
    <div className="img-fluid mb-3">
      <QRCode value={otpauthUrl} />
    </div>
  );
}

export default QRCodeImage;
