import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../../utils/hook/useAxios";
import CreateLoginModal from "../CreateLoginModal";

function ConnectModal(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Login or register with VeritaTrust
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateLoginModal isAuthenticated={false}></CreateLoginModal>
      </Modal.Body>
    </Modal>
  );
}

function ReportModal(props) {
  const axios = useAxios();
  const [Motif, setMotif] = useState("");
  const [formData, setFormData] = useState({
    content: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectedMotif = (e) => {
    setMotif(e.target.value);
  };

  const handleOnclickReport = async (e) => {
    e.preventDefault();
    axios
      .post("/reportreview", {
        reportUserId: props.userid,
        reportSource: "user",
        motif: Motif,
        content: formData.content,
        reviewType: props.reviewtype,
        reviewId: props.reviewId,
        status: 0,
      })
      .then((res) => {
        toast.success("Thank you. Your report has been reported", {
          position: "left-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Error ", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Report review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="support mb-4 bg-white rounded-5 px-4 py-2 p-lg-4">
          <form className="form__content" onSubmit={handleOnclickReport}>
            <div className="mb-3">
              <p>Why do you want to report this resource?</p>
              <select
                className="form-select"
                aria-label="Select support service"
                onChange={handleSelectedMotif}
              >
                <option value="">Select a reason</option>
                <option value="duplicate">duplicate</option>
                <option value="spam">spam</option>
                <option value="profanity">profanity</option>
                <option value="other">other</option>
              </select>
            </div>

            <div className="mb-3">
              <p>Please provide more details</p>
              <textarea
                name="content"
                className="form-control"
                id="content"
                cols="30"
                rows="10"
                placeholder="Please provide more details"
                aria-label="Please provide more details"
                value={formData.content}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="mb-3">
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ReportComponent({ reviewdata, reviewtype }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const axios = useAxios();

  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("userdata"))
  );

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [userDATA, setUserDATA] = useState(
    JSON.parse(window.localStorage.getItem("userdata"))
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
    axios
      .get(`/`)
      .then((response) => {
        setUser(response.data);
        if (JSON.parse(window.localStorage.getItem("userdata")))
          setUser(userDATA);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [, lang, i18n, reviewdata.id]);

  const [showPopover, setShowPopover] = useState(false);

  const handlePopover = (show) => {
    setShowPopover(show);
  };

  let onClickHandler;
  if (user?.id) {
    onClickHandler = () => setModalShow2(true);
  } else {
    onClickHandler = () => setModalShow(true);
  }

  return (
    <>
      <div className="flag">
        <button
          className={"btn btn-none text-primary"}
          onMouseEnter={() => handlePopover(true)}
          onMouseLeave={() => handlePopover(false)}
          variant="primary"
          onClick={onClickHandler}
          aria-label="flag"
        >
          <i className="flaticon-red-flag"></i>{" "}
          <span className="d-none d-lg-inline-block">
            {t("ReviewMerchantPageReport")}{" "}
          </span>
        </button>
        <ReportModal
          userid={user?.id}
          reviewtype={reviewtype}
          reviewId={reviewdata.id}
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        />
        <ConnectModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
}

export default ReportComponent;
