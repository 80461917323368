import React, { useState, useEffect, useRef } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

function CreateAddPhotos({ updateImageUrls, resetInput }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [imagereview, setImagereview] = useState([]);
  const [file, setFile] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const createMiniatureForImage = (e) => {
    Array.from(e.target.files).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageUrl = reader.result;
        const state = [...imagereview];
        state.push(imageUrl);
        setImagereview(state);
      };
    });
  };

  const handleFileChange = (e) => {
    // const selectedFiles = e.target.files;
    // const urls = [];

    // // Convertir les fichiers en URLs d'images
    // for (let i = 0; i < selectedFiles.length; i++) {
    //   const file = selectedFiles[i];
    //   // const url = URL.createObjectURL(file);
    //   urls.push(file);
    // }

    const newFiles = [...file]; // Créez une copie du tableau existant
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]); // Ajoutez chaque fichier à la copie
    }

    setFile(newFiles);

    // Mettre à jour les URLs des images dans le composant parent
    updateImageUrls(newFiles);
    createMiniatureForImage(e);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  useEffect(() => {
    if (resetInput) {
      setImagereview([]);
      setFile([]);
    }
  }, [resetInput]);

  return (
    <>
      <p className="">
        <Trans
          i18nKey="AddReviewForBetaTesterAddPhotos"
          components={{
            1: <span className="text-muted"></span>,
          }}
        />
      </p>
      <div className="row mb-4">
        {imagereview.length > 0 ? (
          // (imagereview[length-1].map((img) => {
          <div className="col-4 col-lg">
            <div className="d-flex rounded-4 border bg-white visuel-mark">
              <img
                className="align-self-center"
                src={imagereview[imagereview.length - 1]}
                alt="Product name"
                id="img2"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {imagereview.length > 1 ? (
          // (imagereview[length-1].map((img) => {
          <div className="col mx-auto">
            <div className="d-flex rounded-4 border bg-white visuel-mark">
              <img
                className="align-self-center"
                src={imagereview[imagereview.length - 2]}
                alt="Product name"
                id="img2"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {imagePreview && (
          <img src={imagePreview} alt="Preview" style={{ maxWidth: "100%" }} />
        )}
        {imagereview.length > 2 ? (
          <div className="col mx-auto">
            <div className="d-flex rounded-4 border bg-white visuel-mark">
              <img
                className="align-self-center"
                src={imagereview[imagereview.length - 3]}
                alt="Product name"
                id="img2"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="col-6 col-lg">
              <label className="add__photo rounded-3 border w-100 text-center d-flex">
                <div className="m-auto">
                  <i className="flaticon-galerie"></i>
                  <div className="">
                    + {t("AddReviewForBetaTesterAddPhotosOrVideo")}
                  </div>
                  <input
                    accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                    type="file"
                    id="image"
                    className="d-none"
                    onChange={handleFileChange}
                  />
                </div>
              </label>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CreateAddPhotos;
