import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ServiceReview from "../../components/ServiceReview";
import { useAxios } from "../../utils/hook/useAxios";

function MerchantReviewAlone() {
  const axios = useAxios();
  const BaseUrl = "https://api.veritatrust.com/review-merchant/";

  let { id } = useParams();

  const [reviews, setReviews] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [displayPendingMessage, setDisplayPendingMessage] = useState(true);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      try {
        const response = await axios.get(
          `/data/merchantreview/merchantreview/${id}`
        );
        const reviewsData = response.data;
        setReviews(reviewsData);

        if (reviewsData.length > 0) {
          const merchantId = reviewsData[0].merchant_id;
          const merchantResponse = await axios.get(
            `/merchant_profiles/findmerchant/${merchantId}`
          );
          const merchantData = merchantResponse.data;
          setMerchant(merchantData);
          //.log(merchantResponse.data);
          setDisplayPendingMessage(false);
        }
      } catch (error) {
        //.error(error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <>
      <section className="bg-success profil-main">
        <div className="container py-2 py-lg-5">
          <div className="row">
            <div className="col-lg-8">
              {merchant && (
                <Link
                  to={"/" + t("lang") + "/review-merchant/" + merchant.website}
                  className="link-light mt-3"
                >
                  Review in {merchant.name}{" "}
                </Link>
              )}

              <div className="reviews-list">
                {reviews &&
                  reviews.map((review, index) => (
                    <div>
                      <ServiceReview key={index} data={review} />
                    </div>
                  ))}
              </div>
              {merchant && (
                <a
                  className="btn btn-secondary btn-sm mt-2"
                  href={
                    "/" + t("lang") + "/review-merchant/" + merchant.website
                  }
                >
                  Views details
                </a>
              )}

              {displayPendingMessage === true && (
                <>
                  <h3 className="text-muted">review in pending status</h3>
                  <Link className="btn btn-secondary btn-sm mt-2" to="/account">
                    Views details
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MerchantReviewAlone;
