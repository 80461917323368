import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavListAdmin from "../../components/NavlistAdmin";
import DashboardDivAdmin from "../../components/DashboardDivAdmin";
import moment from "moment";
function AdminAllReviews() {
  const axios = useAxios();
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [allTransactions, setAllTransactions] = useState([]);
  const [Message, setMessage] = useState(["Moderated", "To moderate"]);

  const [visibleTransactions, setVisibleTransactions] = useState(20); // Nombre de transactions initialement visibles
  const [searchTerm, setSearchTerm] = useState(""); // État pour stocker l'ID de transaction recherché
  const loadMore = () => {
    setVisibleTransactions(
      (prevVisibleTransactions) => prevVisibleTransactions + 5
    ); // Charger plus de transactions (par exemple, 5 de plus)
  };

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/admin");
      setUser(response.data);
      const resp = await axios.get("/support/merchantreviews");
      setAllTransactions(resp.data.reviews);
    };
    fetchReviews();
  }, []);

  return (
    <>
      <DashboardDivAdmin titlepage={t("AccountDashboard")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavListAdmin></NavListAdmin>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <h2>Welcome, {user?.first_name || ""}</h2>
                    <input
                      id="id_order"
                      className="form-control mb-1"
                      type="text"
                      value={searchTerm}
                      placeholder="Search by Transaction ID"
                    />
                    <div className="d-grid gap-2 col-md-4 mx-auto">
                      <button className="btn btn-success text-white">
                        Search
                      </button>
                    </div>
                    <table className="table mb-3">
                      <thead>
                        <tr>
                          <th className="review-date" scope="col">
                            Date
                          </th>
                          <th className="review-details" scope="col">
                            Details
                          </th>
                          <th className="review-details" scope="col">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allTransactions
                          .slice(0, visibleTransactions)
                          .map((transaction) => (
                            <tr
                              className="aos animated aos-init aos-animate"
                              data-aos="fade-up"
                              data-aos-anchor-placement="top-bottom"
                              key={transaction.id}
                            >
                              <td data-label="Date" scope="row">
                                {moment(transaction.createdAt).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td data-label="Details">
                                <span>{transaction.title}</span>
                              </td>
                              <td scope="row">{transaction.status}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    {visibleTransactions < allTransactions.length && (
                      <div className="d-grid gap-2 col-md-4 mx-auto">
                        <button
                          className="btn btn-success text-white"
                          onClick={loadMore}
                        >
                          Load More reviews
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminAllReviews;
