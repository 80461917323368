import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

function AddReviewDiv({ titlepage }) {
  const [typeheader, setTypeheader] = useState(null);
  const [subpage, setSubpage] = useState(null);
  useEffect(() => {
    const Url = window.location.href;
    setTypeheader(Url.split("/")[3]);

    setSubpage("Add review");
  });

  return (
    <section class="py-lg-5">
      <div class="container user_profil">
        <div class="row d-flex align-items-center">
          <div class="col-11 pt-lg-3 py-1 mx-auto">
            <h1 class="display-6 text-primary">Add review</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 mb-md-3">
                <li class="breadcrumb-item">
                  <a href="./">
                    <i class="flaticon-home"></i> Home
                  </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Add review
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddReviewDiv;
