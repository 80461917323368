import React from "react";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

function NavCategorieItem(cat) {
  const { lang, category } = useParams();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const name = cat.name.replace("-", " ");

  var formatedName = cat.categoryName ? cat.categoryName : name;

  const accents = {
    a: /[àáâãäå]/g,
    e: /[èéêë]/g,
    i: /[ìíîï]/g,
    o: /[òóôõö]/g,
    u: /[ùúûü]/g,
    y: /[ýÿ]/g,
    n: /[ñ]/g,
    c: /[ç]/g,
  };

  for (let char in accents) {
    formatedName = formatedName.replace(accents[char], char);
  }

  formatedName = formatedName
    .replace(/[^a-zA-Z0-9]/g, "-")
    .toLowerCase()
    .replaceAll("---", "-")
    .replaceAll("--", "-");

  const [isActive, setIsActive] = useState(false);
  const [isParentActive, setIsParentActive] = useState(false);

  useEffect(() => {
    setIsParentActive(category?.split("-")[0] === `${cat.id}`);
  }, [category, cat.id]);

  const handleCategoryClick = () => {
    setIsActive(!isActive);
    setIsParentActive(category?.split("-")[0] === `${cat.id}`);
  };

  return (
    <li>
      <Link
        to={`/${lang}/c/${cat.id}-${formatedName}`}
        className={isActive && isParentActive ? "active" : ""}
        onClick={handleCategoryClick}
      >
        {cat.categoryName ? cat[`category_name_${lang}`] : name}
      </Link>

      {/* {cat.children?.length > 0 && (
        <ul className={`list-unstyled user-menu ${!isActive ? "d-none" : ""}`}>
          {cat.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((child, index) => {
              return (
                <NavCategorieItem
                  {...child}
                  key={`sub-${index}-${child.name}`}
                  data-category-id={`sub-${index}-${child.id}`}
                  isActive={category?.split("-")[0] === `${child.id}`}
                />
              );
            })}
        </ul>
      )} */}
    </li>
  );
}

export default NavCategorieItem;
