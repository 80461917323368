import React from "react";
import { useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useEffect } from "react";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import data from "./data.json";
import FaqItem from "../../components/FaqItem";
import { Link } from "react-router-dom";

function Faq() {
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setFaqData(data.faq);
  }, []);

  return (
    <>
      <DashboardDiv titlepage="FAQ" />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="faq mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="title">
                      <h2>Reviews</h2>
                    </div>
                    <div
                      className="accordion accordion-flush aos animated aos-init aos-animate"
                      id="issuereview"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                    >
                      {faqData &&
                        faqData.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <FaqItem
                              propsItem={item}
                              propsIndex={index}
                              propsId="issuereview"
                            ></FaqItem>
                          </div>
                        ))}{" "}
                    </div>

                    <div className="faq_support text-center">
                      <span className="lead">
                        Unable to answer your question?
                      </span>
                      <br />
                      Please send us a message!
                      <br />
                      <Link className="btn btn-success" to="/account/support/">
                        Contact our support
                      </Link>
                    </div>
                  </div>
                  <div
                    className="faq mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="title">
                      <h2>Rewards</h2>
                    </div>
                    <div
                      className="accordion accordion-flush aos animated aos-init aos-animate"
                      id="issuerewards"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                    >
                      {faqData &&
                        faqData.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <FaqItem
                              propsItem={item}
                              propsIndex={index}
                              propsId="issuerewards"
                            ></FaqItem>
                          </div>
                        ))}{" "}
                    </div>

                    <div className="faq_support text-center">
                      <span className="lead">
                        Unable to answer your question?
                      </span>
                      <br />
                      Please send us a message!
                      <br />
                      <Link className="btn btn-success" to="/account/support/">
                        Contact our support
                      </Link>
                    </div>
                  </div>

                  <div
                    className="faq mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="title">
                      <h2>Account</h2>
                    </div>
                    <div
                      className="accordion accordion-flush aos animated aos-init aos-animate"
                      id="issueaccount"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                    >
                      {faqData &&
                        faqData.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <FaqItem
                              propsItem={item}
                              propsIndex={index}
                              propsId="issueaccount"
                            ></FaqItem>
                          </div>
                        ))}{" "}
                    </div>

                    <div className="faq_support text-center">
                      <span className="lead">
                        Unable to answer your question?
                      </span>
                      <br />
                      Please send us a message!
                      <br />
                      <Link className="btn btn-success" to="/account/support/">
                        Contact our support
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
