import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AssociationAccount from "../../components/AssociationAccount";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import PersonalData from "../../components/PersonalData";
import SecureAccount from "../../components/SecureAccount";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function SettingPage() {
  const axios = useAxios();
  const [user, setUser] = useState(0);

  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DashboardDiv titlepage={t("AccountProfile")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <PersonalData></PersonalData>
                  <AssociationAccount></AssociationAccount>
                  <SecureAccount></SecureAccount>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SettingPage;
