import React from "react";
import ReactDOM from "react-dom/client";
//import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./styles/index.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MerchantReviewForm from "./components/MerchantReviewForm";
import ReviewValid from "./components/ReviewValid";
import ValidReview from "./components/ValidReview";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationIT from "./locales/it/translation.json";
import AddNewReviewPage from "./pages/AddNewReview";
import AddNewReviewBetaTesterPage from "./pages/AddNewReviewBetaTesterPage";
import AddNewReviewCreatePage from "./pages/AddNewReviewCreate";
import AdminAllReviews from "./pages/AdminAllReviews";
import AdminDashboard from "./pages/AdminDashboard";
import AdminSubscriptions from "./pages/AdminSubscriptions";
import BadgePage from "./pages/BadgePage";
import Blog from "./pages/Blog";
import BrandModerationPage from "./pages/BrandModerationPage";
import BrandModerationPageUnique from "./pages/BrandModerationPageUnique";
import CategoriesPage from "./pages/CategoriesPage";
import CommunityPage from "./pages/CommunityPage";
import ConditionsDeVente from "./pages/ConditionsDeVente";
import ConfirmAccount from "./pages/ConfirmAccount";
import Create from "./pages/Create";
import DashboardReviewsPage from "./pages/DashboardReviewsPage";
import EmailUpdateConfirm from "./pages/EmailUpdateConfirm";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import LoginAdminPage from "./pages/LoginAdminPage";
import MerchantReviewAlone from "./pages/MerchantReviewAlone";
import MerchantReviewFormPage from "./pages/MerchantReviewFormPage";
import MerchantUserModerationPage from "./pages/MerchantUserModerationPage";
import Notfound from "./pages/NotFound";
import NotificationPage from "./pages/NotificationPage";
import OrgMerchantFormPage from "./pages/OrgMerchantFormPage";
import OrgReviewFormPage from "./pages/OrgReviewFormPage";
import PasswordChangePage from "./pages/PasswordChangePage";
import ProductModerationPage from "./pages/ProductModerationPage";
import ProductModerationPageUnique from "./pages/ProductModerationPageUnique";
import ProductReviewAlone from "./pages/ProductReviewAlone";
import ProductReviewFormPage from "./pages/ProductReviewFormPage";
import RegisterConfirmation from "./pages/RegisterConfirmation";
import ReportedReviewPage from "./pages/ReportedReviewPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ReviewMerchant from "./pages/ReviewMerchant";
import ReviewMerchantAlone from "./pages/ReviewMerchantAlone";
import ReviewModerationPage from "./pages/ReviewModeragionPage";
import ReviewProduct from "./pages/ReviewProduct";
import ReviewsPage from "./pages/ReviewsPage";
import RewardPage from "./pages/RewardPage";
import SearchResult from "./pages/SearchResult";
import SettingPage from "./pages/SettingPage";
import SupportPage from "./pages/SupportPage";
import Transactioncompleted from "./pages/Transactioncompleted";
import UserDashboard from "./pages/UserDashboard";
import UserprofilePage from "./pages/UserprofilePage";
import VoucherItemPage from "./pages/VoucherItemPage";
import VoucherPage from "./pages/VoucherPage";
import VouchersTransactionPage from "./pages/VouchersTransactionPage";
import WebshopModerationPage from "./pages/WebshopModerationPage";
import WebshopModerationPageUnique from "./pages/WebshopModerationPageUnique";
import LoginPage from "./pages/loginPage";
import store from "./store";
import { ProvideAxios, useAxios } from "./utils/hook/useAxios";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return null;
}

// // Fonction pour détecter la langue du navigateur
// const getBrowserLanguage = () => {
//   return navigator.language || navigator.userLanguage;
// };

i18n

  .use(initReactI18next) // initialise i18next pour une utilisation avec React
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
      it: {
        translation: translationIT,
      },
    },
    fallbackLng: "en", // langue par défaut
    debug: false, // afficher les messages de débogage
    interpolation: {
      escapeValue: false, // permet d'inclure des éléments HTML dans les traductions
    },
  });
const PrivateRoute = ({ Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("user");
  const [isLoading, setIsLoading] = useState(true); // Ajouter un état de chargement
  const [user, setUser] = useState({}); // Ajouter un état de chargement
  const axios = useAxios();

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const res = await axios.get("/auth/check-auth");
        console.log("/auth/check-auth", res);
        setIsAuthenticated(res.data.isAuthenticated);
        setIsLoading(false); // Mettre à jour l'état de chargement une fois l'authentification terminée

        const response = await axios.get("/");
        console.log("https://api.veritatrust.com/v1", response);

        if (response.data.id) {
          const jsonString = JSON.stringify(response.data);
          window.localStorage.setItem("userdata", jsonString);
        }

        setUser(response.data);

        if (window.localStorage.getItem("userdata")) {
          const retrievedObject = JSON.parse(
            window.localStorage.getItem("userdata")
          );
          setRole(retrievedObject.role);
          setUser(retrievedObject);
          setIsAuthenticated(true);
        }
      } catch (error) {
        //.error(error);
        setIsLoading(false); // Mettre à jour l'état de chargement en cas d'erreur
      }
    };

    fetchAuthStatus();
  }, []);

  // Afficher le composant de chargement si l'authentification est en cours
  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  // return isAuthenticated ? <Component /> : <LoginPage />;
  return isAuthenticated && role === "user" ? (
    user.verified === true || user.verified === 1 || user.verified === "1" ? (
      <Component />
    ) : (
      <div className="container">
        <div className="row">
          <div className="col col-md-8 mx-auto">
            <p className="callout callout-error my-5">
              You must verify your email address!
            </p>
          </div>
        </div>
      </div>
    )
  ) : (
    <LoginPage />
  );
};

// Composant de redirection personnalisé pour les routes
const RedirectToDefaultLanguage = ({ lang }) => {
  // Si le paramètre `lang` est absent, redirige vers la version par défaut (en)
  return (
    <Navigate to={`/${window.localStorage.getItem("langPage")}`} replace />
  );

  // Si le paramètre `lang` est présent, laisse la route actuelle inchangée
};

const RedirectRoute = ({}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const axios = useAxios();

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const res = await axios.get("/auth/check-auth");
        setIsAuthenticated(res.data.isAuthenticated);
        //.log("res.data.isAuthenticated");
        //.log(res.data.isAuthenticated);
      } catch (error) {
        //.error(error);
      }
    };

    fetchAuthStatus();
  }, []);

  return isAuthenticated ? <UserDashboard /> : <Create />;
};

const AdminPrivateRoute = ({ Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Ajouter un état de chargement
  const [user, setUser] = useState({}); // Ajouter un état de chargement

  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(false);
  const [userAdmin, setUserAdmin] = useState({});
  const axios = useAxios();

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const res = await axios.get("/admin/auth/check-auth");
        setIsAuthenticatedAdmin(res.data.isAuthenticated);
        setIsLoading(false); // Mettre à jour l'état de chargement une fois l'authentification terminée

        const response = await axios.get("/admin");

        setUserAdmin(response.data);
      } catch (error) {
        //.error(error);
        setIsLoading(false); // Mettre à jour l'état de chargement en cas d'erreur
      }
    };

    fetchAuthStatus();
  }, []);

  // Afficher le composant de chargement si l'authentification est en cours
  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  // return isAuthenticated ? <Component /> : <LoginPage />;
  return isAuthenticatedAdmin &&
    (userAdmin.role === "administrator" || userAdmin.role === "moderator") ? (
    <Component />
  ) : (
    <LoginAdminPage />
  );
};

const App = () => {
  return (
    // TODO fatah why StrictMode? hooks called twice
    <React.StrictMode>
      <ProvideAxios>
        <Provider store={store}>
          <Router>
            <ScrollToTop />
            <Header />
            <GoogleOAuthProvider clientId="1036726798056-idduh86bhvsjo0mrhuuhoj8l87u4alvi.apps.googleusercontent.com">
              <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                {/* <Route
                path="/*"
                element={
                  <RedirectToDefaultLanguage
                    lang={window.localStorage.getItem("langPage")}
                  />
                }
              /> */}
                <Route path="/:lang" element={<Home />} />
                <Route path="/" element={<Navigate to="/en" />} />{" "}
                {/* Redirige vers la langue par défaut si aucun paramètre de langue n'est spécifié */}
                <Route path="Blog" element={<Blog />} />
                <Route path="/:lang/signup" element={<Create />} />
                <Route
                  path="/signup"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem("langPage")}/signup`}
                    />
                  }
                />{" "}
                <Route path="/:lang/signin" element={<LoginPage />} />
                <Route
                  path="/signin"
                  element={<Navigate to="/en/signin" />}
                />{" "}
                <Route
                  path="/:lang/review-product/:product_name"
                  element={<ReviewProduct />}
                />
                <Route
                  path="/review-product/:product_name"
                  element={<Navigate to="/en/review-product/:product_name" />}
                />{" "}
                <Route
                  path="/:lang/review-merchant/:website"
                  element={<ReviewMerchant />}
                />
                <Route
                  path="/review-merchant/:website"
                  element={<Navigate to="/en/review-merchant/:website" />}
                />{" "}
                {/* <Route path="/search" element={<Navigate to="/en/search?" />} />{" "} */}
                <Route path="/:lang/search" element={<SearchResult />} />
                {/* <Route
                path="/search"
                element={<Navigate to="/en/search?" />}
              />{" "} */}
                <Route
                  path="/:lang/listings/write-review/:product_name"
                  element={<OrgReviewFormPage />}
                />
                <Route
                  path="/listings/write-review/:product_name"
                  element={
                    <Navigate to="/en/listings/write-review/:product_name" />
                  }
                />{" "}
                <Route
                  path="/:lang/evaluate/:website"
                  element={<OrgMerchantFormPage />}
                />
                <Route
                  path="/evaluate/:website"
                  element={<Navigate to="/en/evaluate/:website" />}
                />{" "}
                <Route
                  path="merchant-review-form"
                  element={<MerchantReviewForm />}
                />
                <Route
                  path="mreview/:id"
                  element={<MerchantReviewFormPage />}
                />
                <Route path="preview/:id" element={<ProductReviewFormPage />} />
                <Route path="all-reviews" element={<ReviewsPage />} />
                <Route path="review-valided" element={<ValidReview />} />
                <Route path="404" element={<Notfound />} />
                <Route
                  path="/:lang/merchantreview/:id"
                  element={<MerchantReviewAlone />}
                />
                <Route
                  path="/:lang/productreview/:id"
                  element={<ProductReviewAlone />}
                />
                <Route
                  path="/merchantreview/:id"
                  element={<Navigate to="/en/merchantreview/:id" />}
                />
                <Route
                  path="/productreview/:id"
                  element={<Navigate to="/en/productreview/:id" />}
                />
                <Route
                  path="transaction_completed/:id"
                  element={<Transactioncompleted />}
                />
                <Route
                  path="review-merchant-alone/:id"
                  element={<ReviewMerchantAlone />}
                />
                <Route path="review-validated/:id" element={<ReviewValid />} />
                <Route
                  path="/:lang/account/"
                  element={<PrivateRoute Component={UserDashboard} />}
                />
                <Route
                  path="/account/"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem("langPage")}/account`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/settings/"
                  element={<PrivateRoute Component={SettingPage} />}
                />
                <Route
                  path="/account/settings"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/settings`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/vouchers/"
                  element={<PrivateRoute Component={VoucherPage} />}
                />
                <Route
                  path="/account/vouchers"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/vouchers`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/vouchers/:voucherid"
                  element={<PrivateRoute Component={VoucherItemPage} />}
                />
                <Route
                  path="/account/vouchers/:voucherid"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/vouchers/:voucherid`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/rewards/"
                  element={<PrivateRoute Component={RewardPage} />}
                />
                <Route
                  path="/account/rewards"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/rewards`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/reviews/"
                  element={<PrivateRoute Component={DashboardReviewsPage} />}
                />
                <Route
                  path="/account/reviews/"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/reviews`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/community/"
                  element={<PrivateRoute Component={CommunityPage} />}
                />
                <Route
                  path="/account/community/"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/community`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/badges/"
                  element={<PrivateRoute Component={BadgePage} />}
                />
                <Route
                  path="/account/badges"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/badges`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/support/"
                  element={<PrivateRoute Component={SupportPage} />}
                />
                <Route
                  path="/account/support"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/support`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/notifications/"
                  element={<PrivateRoute Component={NotificationPage} />}
                />
                <Route
                  path="/account/notifications"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/notifications`}
                    />
                  }
                />
                <Route
                  path="/:lang/account/faq/"
                  element={<PrivateRoute Component={Faq} />}
                />
                <Route
                  path="/account/faq"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/account/faq`}
                    />
                  }
                />
                <Route
                  path="/:lang/email-confirmation"
                  element={<RegisterConfirmation />}
                />
                <Route
                  path="/email-confirmation"
                  element={<Navigate to="/en/email-confirmation/" />}
                />
                <Route path="/resetpassword" element={<ResetPasswordPage />} />
                <Route
                  path="/confirmaaccount/:id"
                  element={<ConfirmAccount />}
                />
                <Route
                  path="/user-changepassword/:token"
                  element={<PasswordChangePage />}
                />
                <Route
                  path="/update-email-confirmation"
                  element={<EmailUpdateConfirm />}
                />
                <Route
                  path="/:lang/c/:category?"
                  element={<CategoriesPage />}
                />
                <Route
                  path="/c/:category?"
                  element={<Navigate to="/en/c/:category?" />}
                />{" "}
                <Route path="/:lang/users/:id" element={<UserprofilePage />} />
                <Route
                  path="/users/:id"
                  element={<Navigate to="/en/users/:id" />}
                />{" "}
                <Route
                  path="/:lang/add-review"
                  element={<AddNewReviewPage />}
                />
                <Route
                  path="/add-review"
                  element={<Navigate to="/en/add-review" />}
                />{" "}
                <Route
                  path="/:lang/add-review-create"
                  element={<AddNewReviewCreatePage />}
                />
                <Route
                  path="/add-review-create"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/add-review-create`}
                    />
                  }
                />{" "}
                <Route
                  path="/:lang/add-new-review"
                  element={<AddNewReviewBetaTesterPage />}
                />
                <Route
                  path="/add-new-review"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/add-new-review`}
                    />
                  }
                />
                <Route
                  path="/:lang/conditions-de-vente"
                  element={<ConditionsDeVente />}
                />
                <Route
                  path="/conditions-de-vente"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/conditions-de-vente`}
                    />
                  }
                />
                <Route path="/:lang/login-admin" element={<LoginAdminPage />} />
                <Route
                  path="/login-admin"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/login-admin`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-subscriptions"
                  element={<AdminSubscriptions />}
                />
                <Route
                  path="/admin-subscriptions"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-subscriptions`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-dashboard"
                  element={<AdminPrivateRoute Component={AdminDashboard} />}
                />
                <Route
                  path="/admin-dashboard"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-dashboard`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-allreviews"
                  element={<AdminPrivateRoute Component={AdminAllReviews} />}
                />
                <Route
                  path="/admin-allreviews"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-allreviews`}
                    />
                  }
                />
                {/* <Route
                path="/:lang/review-moderation/:transactionId"
                element={<AdminPrivateRoute Component={ReviewModerationPage} />}
              />
              <Route
                path="/review-moderation/:transactionId"
                element={
                  <Navigate
                    to={`/${window.localStorage.getItem(
                      "langPage"
                    )}/review-moderation/:transactionId`}
                  />
                }
              /> */}
                <Route
                  path="/:lang/review-moderation/:transactionId"
                  element={<ReviewModerationPage />}
                />
                <Route
                  path="/review-moderation/:transactionId"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/review-moderation/:transactionId`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-products"
                  element={<ProductModerationPage />}
                />
                <Route
                  path="/admin-products"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-products`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-product/:product_id"
                  element={<ProductModerationPageUnique />}
                />
                <Route
                  path="/admin-product/:product_id"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-products/:product_id`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-brands"
                  element={<BrandModerationPage />}
                />
                <Route
                  path="/admin-brands"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-brands`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-brand/:brand_id"
                  element={<BrandModerationPageUnique />}
                />
                <Route
                  path="/admin-brand/:brand_id"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-brand/:brand_id`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-webshop"
                  element={<WebshopModerationPage />}
                />
                <Route
                  path="/admin-webshop"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-webshop`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-merchantusers"
                  element={<MerchantUserModerationPage />}
                />
                <Route
                  path="/admin-merchantusers"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-merchantusers`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-one-webshop/:merchant_id"
                  element={<WebshopModerationPageUnique />}
                />
                <Route
                  path="/admin-one-webshop/:merchant_id"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-one-webshop/:merchant_id`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-support-public"
                  element={<ReportedReviewPage />}
                />
                <Route
                  path="/:lang/admin-support"
                  element={<AdminPrivateRoute Component={ReportedReviewPage} />}
                />
                <Route
                  path="/admin-support"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-support`}
                    />
                  }
                />
                <Route
                  path="/:lang/admin-vouchers-transaction"
                  element={
                    <AdminPrivateRoute Component={VouchersTransactionPage} />
                  }
                />
                <Route
                  path="/admin-vouchers-transaction"
                  element={
                    <Navigate
                      to={`/${window.localStorage.getItem(
                        "langPage"
                      )}/admin-vouchers-transaction`}
                    />
                  }
                />
              </Routes>
            </GoogleOAuthProvider>
            <Footer></Footer>
          </Router>
        </Provider>
      </ProvideAxios>
    </React.StrictMode>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
