import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";

import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import star0 from "../../assets/star-1.png";
import PopoverVerified from "../PopoverVerified";
import moment from "moment";

function ReviewDiv({ data }) {
  let srcvalue = "star" + data.rating;
  let srcimg = star1;
  let statusClass = "pending";
  let statusSymbolClass = "flaticon-time-left flaticon-pending";

  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
    default:
      break;
  }

  return (
    <>
      <div
        className="bg-white p-3 mb-2 rounded-5 shadow aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="profil d-flex pb-2">
          <img src={star0} width="50" height="50" />
          <div className="info ms-3">
            <p className="name lead mb-0">
              {data.first_name} {data.last_name}
            </p>
            <p className="analyse text-muted mb-0">
              {data.Nbre} reviews • <i className="flaticon-location"></i> FR
            </p>
          </div>
        </div>
        <div className="notation d-lg-flex justify-content-between my-3">
          <div className="note small me-lg-3 mb-2">
            <div className="form__star d-flex justify-content-start">
              <div>
                {" "}
                <img
                  alt="Noté 5 sur 5 étoiles"
                  src={srcimg}
                  width="120"
                  height="70"
                />{" "}
              </div>
            </div>
          </div>

          <PopoverVerified hash_transaction={data.hash_transaction} />
          <div className="date text-primary ms-auto">
            {moment(data.createdAt).format("MMM DD, YYYY")}
          </div>
        </div>
        <div className="content mb-2">
          <h3>{data.title}</h3>
          <p className="mb-0">
            Experience bought at{" "}
            {moment(data.experienceDate).format("MMM DD, YYYY")}{" "}
          </p>
          <p className="text-muted">{data.content}</p>
        </div>
        <div className="related_product">
          <h4 className="h5">{data.titreprodreview}</h4>
          <div className="d-flex">
            <div className="photo">
              <img src={data.image_video} alt="user image review" />
            </div>

            <div className="related_content">
              <p className="text-primary mb-0">{data.contentpr}</p>
              <p className="mb-0">
                ({data.first_name}) has rated this {data.product_id}{" "}
                {data.ratingp}/5
              </p>
              <a className="btn btn-secondary btn-sm mt-2" href="#">
                All reviews for this product
              </a>
            </div>
          </div>
        </div>
        <div className="share d-flex justify-content-around">
          <div className="recommanded">
            <a href="#">
              <i className="flaticon-heart"></i> Useful
            </a>
          </div>
          <div className="share">
            <a href="#">
              <i className="flaticon-network"></i> Share
            </a>
          </div>
          <div className="flag">
            <a href="#" aria-label="flag">
              <i className="flaticon-red-flag"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewDiv;
