import React from "react"; 
import '../../styles/bootstrap.css'
import '../../styles/style.css'
import '../../styles/flaticon_veritatrust.css'
import MerchantReviewForm from "../../components/MerchantReviewForm";
import { useEffect } from 'react';


function MerchantReviewFormPage()
{
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
    <>
    <br/>
      <MerchantReviewForm  textAreaId="content"></MerchantReviewForm>
    </>)
}

 export default MerchantReviewFormPage;