import React, { useState } from "react";
import { toast } from "react-toastify";
import avatar from "../../assets/lorem-portrait.jpg";
import { useAxios } from "../../utils/hook/useAxios";

function VoucherTransactionItem({ data }) {
  const [amount, setAmount] = useState(0);
  const [enable, setEnable] = useState(true);
  const [voucher, setVoucher] = useState({});
  const [VoucherCode, setVoucherCode] = useState(data.VoucherCode);
  const [VoucherStatus, setVoucherStatus] = useState(data.status);
  const [VoucherCodeClient, setVoucherCodeClient] = useState(data.VoucherCode);
  const axios = useAxios();
  function handleSave() {
    axios
      .put("/VoucherGiftTransactions/" + data.id, {
        VoucherCode: VoucherCode,
        status: "1",
      })
      .then((res) => {
        setVoucherStatus(res.data.status);
        setVoucherCodeClient(res.data.VoucherCode);
        toast.success("Data saved sucessfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        //.log(err);
      });
  }

  return (
    <>
      <div className="container">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#" + data.id}
              aria-expanded="true"
              aria-controls={data.id}
            >
              {VoucherStatus === "0" ? (
                <i className="flaticon-time-left me-3 text-warning"></i>
              ) : (
                <i className="flaticon-check me-3 text-success"></i>
              )}
              #{data?.id} - {data?.userprofile?.first_name}{" "}
              {data?.userprofile?.last_name} - {data?.VoucherGift?.VoucherName}
            </button>
          </h2>
          <div
            id={data.id}
            className={
              data.id
                ? "accordion-collapse collapse"
                : "accordion-collapse collapse show"
            }
            data-bs-parent="#accordionbrands"
          >
            <div className="accordion-body personal-data moderator">
              <div className="row">
                <div className="col-md-2 mb-3">
                  <div className="userpicture">
                    <img
                      className="user-profil-avatar"
                      src={data?.userprofile?.profile_url || avatar}
                      alt="user-avatar"
                    />
                  </div>
                </div>
                <div className="col-md-7 mb-3">
                  <p>Amount: {data.Amount} VT</p>
                  <input
                    className="form-control mb-2"
                    type="text"
                    placeholder="Insert new voucher code"
                    value={VoucherCode}
                    onChange={(e) => {
                      setVoucherCode(e.target.value);
                    }}
                    disabled={VoucherCodeClient ? true : false}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <>
                    <button className="btn btn-danger w-100 mb-2">
                      Reject
                    </button>
                    <button
                      className="btn btn-success w-100 mb-2"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VoucherTransactionItem;
