import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile from "../../assets/lorem-portrait.jpg";
import { useAxios } from "../../utils/hook/useAxios";
// import AOS from "aos";
// import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function PersonalData() {
  const [editing, setEditing] = useState(false);
  const axios = useAxios();
  const [data, setData] = useState({});
  const [id, setId] = useState(null);
  const [notif, setNotif] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);

  const [selectedValue, setSelectedValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [date, setDate] = useState(new Date());
  const [countryCode, setCountryCode] = useState("+1");
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams(); // Par exemple, le code du pays +1 pour les États-Unis

  const handleChangeCountryInput = (event) => {
    setSelectedValue(event.target.innerText);
    // setValues({ ...values, country: event.target.value });

    setValues({ ...values, country: event.target.innerText.split("(")[0] });
    setCountryCode(event.target.innerText.split("(")[1].split(")")[0]);
    const regex = /^[\d+\s]+$/; // Expression régulière pour n'accepter que les chiffres et le symbole "+"
    if (regex.test(event.target.innerText.split("(")[1].split(")")[0])) {
      setValues((prevValues) => ({
        ...prevValues,
        phoneNumber: event.target.innerText.split("(")[1].split(")")[0],
      }));
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    setSearchTerm(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const newPhone = `${countryCode}${e.target.value.substring(
      countryCode.length
    )}`;

    const regex = /^[\d+\s]+$/; // Expression régulière pour n'accepter que les chiffres et le symbole "+"
    if (regex.test(newPhone)) {
      setValues((prevValues) => ({
        ...prevValues,
        phoneNumber: newPhone,
      }));
    }
  };

  const [values, setValues] = useState({
    displayName: "",
    firstName: "",
    lastName: "",
    birthdayDate: "",
    localAdress: "",
    phoneNumber: "",
    zip: "",
    city: "",
    country: "",
    currency: "",
  });
  useEffect(() => {
    i18n.changeLanguage(lang);

    // AOS.init({
    //   duration: 1000,
    // });
  }, []);

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm) {
      axios
        .get(`/data/countries?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );

        setData(retrievedObject);
        response.data = retrievedObject;
      }

      setData(response.data);
      setValues({
        displayName: response.data.nickname,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        birthdayDate: response.data.dateNaissance,
        localAdress: response.data.localAdress,
        phoneNumber: response.data.phoneNumber,
        zip: response.data.zip,
        city: response.data.city,
        country: response.data.country,
        currency: response.data.currency,
      });
      setId(response.data.id);
      if (response.data.profile_url) setImageProfile(response.data.profile_url);
      //.log(response);

      if (
        response.data.nickname &&
        response.data.first_name &&
        response.data.last_name &&
        response.data.dateNaissance &&
        response.data.localAdress &&
        response.data.phoneNumber &&
        response.data.zip &&
        response.data.city &&
        response.data.country &&
        response.data.currency
      ) {
        setNotif(false);
      }
    };
    fetchReviews();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      id: id,
      nickname: values.displayName,
      first_name: values.firstName,
      last_name: values.lastName,
      dateNaissance: values.birthdayDate,
      localAdress: values.localAdress,
      phoneNumber: values.phoneNumber,
      zip: values.zip,
      city: values.city,
      country: selectedValue.split("(")[0],
      currency: values.currency,
      profile_url: imageUrl ? imageUrl : imageProfile,
    };

    //.log("imageUrl");
    //.log(imageUrl);

    axios
      .put("/data/update-personaldata", dataUser)
      .then((res) => {
        //.log(res);

        toast.success("Data saved sucessfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setEditing(false);
      })
      .catch((err) => {
        //.log(err);
        toast.success("server url don't not exist", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewImageUrl = URL.createObjectURL(file);
      setPreviewImage(previewImageUrl);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "VeritaTrust_x2023Upload_preset_name");

      fetch("https://api.cloudinary.com/v1_1/dnbpmsofq/image/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          //.log("Success:", result);
          setImageUrl(result.url);
        })
        .catch((error) => {
          //.error("Error:", error);
        });

      //.log(imageUrl);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <>
      <form
        className="position-relative"
        style={{ zIndex: "1" }}
        onSubmit={handleSubmit}
        action=""
      >
        <div
          className="personal-data mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="title">
            <p className="lead me-auto">{t("AccountProfilePersonalData")}</p>
            {notif == true ? (
              <p className="callout callout-info mb-0">
                {t("AccountProfileNotificationMsg")}
              </p>
            ) : (
              <></>
            )}

            {editing ? (
              <>
                <button type="submit" className="btn btn-success ms-2">
                  {t("AccountProfileSaveButton")}
                </button>
                <button className="btn btn-danger ms-2" onClick={handleCancel}>
                  {t("AccountProfileCancelButton")}
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-primary ms-2"
                onClick={handleEdit}
              >
                {t("AccountProfileEditButton")}
              </button>
            )}
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="userpicture">
                <img
                  className="user-profil-avatar"
                  src={imageUrl ? imageUrl : imageProfile || profile}
                  alt="user-avatar"
                />
                <label className="add-visual" id="userpicture">
                  <input
                    name="userpicture"
                    accept="image/jpeg, image/webp, image/png"
                    type="file"
                    className="d-none"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="username mb-3">
                <label className="text-primary" for="username">
                  {t("AccountProfileDisplayName")}
                </label>
                <input
                  id="username"
                  className="form-control "
                  type="text"
                  name="displayName"
                  value={values.displayName}
                  onChange={handleChange}
                  disabled={!editing}
                />
              </div>
              <div className="firstname mb-3">
                <label className="text-primary" for="firstname">
                  {t("AccountProfileFirstname")}
                </label>
                <input
                  id="firstname"
                  className="form-control "
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  disabled={!editing}
                />
              </div>
              <div className="lastname mb-3">
                <label className="text-primary" for="lastname">
                  {t("AccountProfileLastname")}
                </label>
                <input
                  id="lastname"
                  className="form-control "
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  disabled={!editing}
                />
              </div>
              <div className="currency mb-3">
                <label className="text-primary" for="currency">
                  {t("AccountProfileCurrency")}
                </label>
                <select
                  id="currency"
                  className="form-select "
                  name="currency"
                  value={values.currency}
                  onChange={handleChange}
                  disabled={!editing}
                >
                  <option value="$">$</option>
                  <option value="€">€</option>
                </select>
              </div>
            </div>
            <div className="col-md-5 mb-3">
              <div className="birthday mb-3">
                <label className="text-primary" for="birthday">
                  {t("AccountProfileBirthday")}
                </label>
                <input
                  id="birthday"
                  className="form-control "
                  type="date"
                  data-date-inline-picker="true"
                  name="birthdayDate"
                  value={values.birthdayDate?.substring(0, 10)}
                  onChange={handleChange}
                  lang="en"
                  disabled={!editing}
                />
              </div>
              <div className="address mb-3">
                <label className="text-primary" for="address">
                  {t("AccountProfileAddress")}
                </label>
                <input
                  id="address"
                  className="form-control  autosize"
                  type="text"
                  name="localAdress"
                  value={values.localAdress}
                  onChange={handleChange}
                  disabled={!editing}
                />
              </div>
              <div className="address mb-3">
                <label className="text-primary" for="address">
                  {t("AccountProfileZip")}
                </label>
                <input
                  id="zip"
                  className="form-control  autosize"
                  type="text"
                  name="zip"
                  value={values.zip}
                  onChange={handleChange}
                  disabled={!editing}
                />
              </div>
              <div className="address mb-3">
                <label className="text-primary" for="address">
                  {t("AccountProfileCity")}
                </label>
                <input
                  id="city"
                  className="form-control  autosize"
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  disabled={!editing}
                />
              </div>

              <div className="address mb-3">
                <label className="text-primary" for="address">
                  {t("AccountProfileCountry")}
                </label>
                <div className="dropdown">
                  <input
                    id="country"
                    className="form-control  autosize"
                    type="text"
                    name="country"
                    data-bs-toggle="dropdown"
                    value={values.country}
                    onChange={handleChange}
                    disabled={!editing}
                    aria-expanded="false"
                    autocomplete="off"
                    data-bs-auto-close="inside"
                  />

                  <ul class="dropdown-menu w-100">
                    {searchResults &&
                      searchResults.map((suggestion, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={handleChangeCountryInput}
                          >
                            {suggestion.name_en +
                              "(" +
                              suggestion.phone_code +
                              ")"}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="phone">
                <label className="text-primary" for="phone">
                  {t("AccountProfilePhone")}
                </label>
                <input
                  id="phone"
                  className="form-control "
                  type="tel"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handlePhoneChange}
                  disabled={!editing}
                />
              </div>
              <div className="d-md-none d-flex justify-content-end mt-3">
                {editing ? (
                  <>
                    <button type="submit" className="btn btn-success ms-2">
                      {t("AccountProfileSaveButton")}
                    </button>
                    <button
                      className="btn btn-danger ms-2"
                      onClick={handleCancel}
                    >
                      {t("AccountProfileCancelButton")}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default PersonalData;
