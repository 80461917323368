import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";

function Transactioncompleted() {
  const [jobId, setJobId] = useState("");
  const navigate = useNavigate();

  const axios = useAxios();

  useEffect(() => {
    const invitationUrl = window.location.href;
    axios
      .get("/data/invitationdata-completed/?invitationUrl=" + invitationUrl)
      .then((res) => {
        if (res == null) navigate("/404", { replace: true });

        // console.log(res);

        setJobId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[0]
            .split("=")[1]
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <>
      <section className="py-5 form valid">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-11 col-lg-8 py-4 px-lg-4 mx-auto result-ok">
              <div className="form__header">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-12 col-md-8 col-xl-9 text-center">
                    <img
                      className="align-self-center mb-4"
                      src="http://dev.veritatrust.com/assets/img/coin.png"
                      alt="Product name"
                    />
                    <p className="lead text-green">
                      Ooops!
                      <br /> You already submitted your review
                    </p>
                    <p className="">Get more reward and review more.</p>
                    <div class="btn-group" role="group" aria-label="Add review">
                      <Link
                        to="https://api.veritatrust.com/add-review"
                        className="btn btn-success"
                      >
                        Write new review
                      </Link>
                      <Link
                        to="https://api.veritatrust.com/account"
                        className="btn btn-outline-success"
                      >
                        Check rewards
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Transactioncompleted;
