import { useState, useEffect, useContext } from "react";
import "../../styles/style.css";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { connect } from "react-redux";
import FollowComponent from "../../components/FollowComponent";
import { useAxios } from "../../utils/hook/useAxios";
// import { responseInterceptor } from "http-proxy-middleware";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function FollowersListComponent({ propsUserid }) {
  const axios = useAxios();

  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [suggestusers, setsuggestusers] = useState([]);
  const [suggestTopusers, setsuggestTopusers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermFollower, setSearchTermFollower] = useState("");
  const [searchTermSuggest, setSearchTermSuggest] = useState("");
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const res = await axios.get("/data/follow/followers/" + propsUserid);
      if (res.data.length > 0) {
        setFollowers(res.data);
      } else {
        setFollowers([]);
      }
    };
    fetchReviews();
  }, [propsUserid]);

  useEffect(() => {
    const fetchReviews = () => {
      axios
        .get("/data/follow/followers/" + propsUserid)
        .then((res) => {
          if (res.data.length > 0) {
            setFollowers(res.data);
          }
        })
        .catch((error) => {
          // Gérer les erreurs ici
        });

      axios
        .get("/data/follow/followings/" + propsUserid)
        .then((response) => {
          if (response.data.length > 0) {
            setFollowings(response.data);
          }
        })
        .catch((error) => {
          // Gérer les erreurs ici
        });

      axios
        .get("/suggestusers/" + propsUserid)
        .then((querieresponse) => {
          if (querieresponse.data) {
            setsuggestTopusers(querieresponse.data);
          }
        })
        .catch((error) => {
          // Gérer les erreurs ici
        });
    };

    fetchReviews();
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      // Send a request to the server to search for reviews matching the search term
      if (searchTerm) {
        const response = await axios.get(
          `/data/follow/followings/search/${propsUserid}?q=${searchTerm}`
        );

        if (response.data.length) {
          setFollowings(response.data);
        } else setFollowings([]);
      } else {
        setFollowings([]);
      }
    };
    fetchReviews();
  }, [searchTerm]);

  useEffect(() => {
    let fetchReviews = async () => {
      // Send a request to the server to search for reviews matching the search term
      if (searchTermFollower) {
        const response = await axios.get(
          `/data/follow/followers/search/${propsUserid}?q=${searchTermFollower}`
        );

        if (response.data.length) {
          setFollowers(response.data);
        } else setFollowers([]);
      } else {
        setFollowers([]);
      }
    };
    fetchReviews();
  }, [searchTermFollower]);

  useEffect(() => {
    let fetchReviews = async () => {
      // Send a request to the server to search for reviews matching the search term
      if (searchTermSuggest) {
        const response = await axios.get(
          `/users/resultsfiltered?q=${searchTermSuggest}`
        );
        if (response.data.length) {
          setsuggestTopusers(response.data);
        } else setsuggestTopusers([]);
      } else {
        setsuggestTopusers([]);
      }
    };
    fetchReviews();
  }, [searchTermSuggest]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchChangeFollowers = (event) => {
    setSearchTermFollower(event.target.value);
  };
  const handleSearchChangeSuggest = (event) => {
    setSearchTermSuggest(event.target.value);
  };
  const handleClickFollowers = async () => {
    let fetchReviews = async () => {
      const res = await axios.get("/data/follow/followers/" + propsUserid);

      if (res.data.length > 0) {
        setFollowers(res.data);
      } else {
        setFollowers([]);
      }
    };
    fetchReviews();
  };
  const handleClickFollowings = async () => {
    let fetchReviews = async () => {
      const response = await axios.get(
        "/data/follow/followings/" + propsUserid
      );

      if (response.data.length > 0) {
        setFollowings(response.data);
      } else {
        setFollowings([]);
      }
    };
    fetchReviews();
  };
  const handleClickSuggests = async () => {
    let fetchReviews = async () => {
      const resp = await axios.get("/suggestusers/" + propsUserid);
      const response = await axios.get("/suggestusersfollowers/" + propsUserid);
      if (response.data.length > 0) {
        setsuggestusers(response.data);
      } else {
        setsuggestusers([]);
      }

      if (resp.data.length > 0) {
        setsuggestTopusers(resp.data);
      }
    };
    fetchReviews();
  };

  return (
    <>
      <div
        className="community mb-4 bg-white shadow rounded-5 px-lg-4 px-1 py-2 p-lg-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="claim__system">
          <ul className="nav nav-pills nav-justified" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="Follower-tab"
                data-bs-toggle="tab"
                data-bs-target="#followers"
                type="button"
                onClick={handleClickFollowers}
                role="tab"
                aria-controls="followers"
                aria-selected="true"
              >
                {t("AccountCommunityFollowers")}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="following-tab"
                data-bs-toggle="tab"
                data-bs-target="#following-tab-pane"
                type="button"
                onClick={handleClickFollowings}
                role="tab"
                aria-controls="following-tab-pane"
                aria-selected="false"
                tabindex="-1"
              >
                {t("AccountCommunityFollowing")}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="suggest-tab"
                data-bs-toggle="tab"
                data-bs-target="#suggest-tab-pane"
                type="button"
                onClick={handleClickSuggests}
                role="tab"
                aria-controls="suggest-tab-pane"
                aria-selected="false"
                tabindex="-1"
              >
                {t("AccountCommunitySuggestions")}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade active show"
              id="followers"
              role="tabpanel"
              aria-labelledby="Follower-tab"
              tabindex="0"
            >
              <div className="jobs-filters">
                <input
                  type="search"
                  className="form-control"
                  placeholder={t("AccountCommunitySearch")}
                  name="q"
                  autoComplete="off"
                  value={searchTermFollower}
                  onChange={handleSearchChangeFollowers}
                />
              </div>
              <ul className="" key={searchTermFollower}>
                {followers &&
                  followers.map((follower, index) => (
                    <FollowComponent
                      proposFollow={follower}
                      propsUserIdFollowerd={propsUserid}
                      propsAction="follower"
                      key={index}
                    ></FollowComponent>
                  ))}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="following-tab-pane"
              role="tabpanel"
              aria-labelledby="following-tab"
              tabindex="0"
            >
              <div className="jobs-filters">
                <input
                  className="form-control"
                  placeholder={t("AccountCommunitySearch")}
                  type="search"
                  name="q"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <ul className="" key={searchTerm}>
                {followings &&
                  followings.map((following, index) => (
                    <FollowComponent
                      proposFollow={following}
                      propsUserIdFollowerd={propsUserid}
                      propsAction="following"
                      key={index}
                    ></FollowComponent>
                  ))}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="suggest-tab-pane"
              role="tabpanel"
              aria-labelledby="suggest-tab"
              tabindex="0"
            >
              <div className="jobs-filters">
                <input
                  className="form-control"
                  placeholder={t("AccountCommunitySearch")}
                  type="search"
                  name="q"
                  autoComplete="off"
                  value={searchTermSuggest}
                  onChange={handleSearchChangeSuggest}
                />
              </div>

              <h3>{t("AccountCommunityTopInfluencer")}</h3>
              <ul className="mt-5" key={searchTermSuggest}>
                {suggestTopusers &&
                  suggestTopusers.map((suggest, index) => (
                    <FollowComponent
                      proposFollow={suggest}
                      propsUserIdFollowerd={propsUserid}
                      propsAction="suggest"
                      key={index}
                    ></FollowComponent>
                  ))}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="contact-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabindex="0"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="disabled-tab-pane"
              role="tabpanel"
              aria-labelledby="disabled-tab"
              tabindex="0"
            >
              ...
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FollowersListComponent;
