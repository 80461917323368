import React, { useEffect } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image, Transformation } from "cloudinary-react";

function CreateProofPurchaseInput({ updateProofOfPurchase, resetInput }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {}, [resetInput]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "VeritaTrust_x2023Upload_preset_name");

    fetch(`https://api.cloudinary.com/v1_1/drbhco8al/image/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error uploading image to Cloudinary");
        }
        return response.json();
      })
      .then((data) => {
        updateProofOfPurchase(data.secure_url);
      })
      .catch((error) => {
        console.error("Error uploading image to Cloudinary:", error);
      });

    // const url = URL.createObjectURL(selectedFile);

    // updateProofOfPurchase(url);
  };

  return (
    <>
      {resetInput === 0 && (
        <>
          <p className="lead">
            {t("AddReviewForBetaTesterProofOfPurchase")}
            <sup>*</sup>
          </p>
          <p>{t("AddReviewForBetaTesterProofOfPurchaseInfos")}</p>
          <div className="mb-3">
            <input
              type="file"
              accept="image/jpeg, image/tiff, image/webp, image/png,application/pdf"
              className="form-control"
              placeholder="Proof of purchase"
              aria-label="Proof of purchase"
              onChange={handleFileChange}
            />
            <p className="text-muted mt-2">{t("AddReviewForBetaTesterMsg5")}</p>
          </div>
        </>
      )}
    </>
  );
}

export default CreateProofPurchaseInput;
