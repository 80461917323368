import $ from "jquery";

function togglePassword() {
  $(".toggle-password").click(function () {
    $(this).children().toggleClass("flaticon-seen flaticon-hide");
    let input = $(this).prev();
    input.attr("type", input.attr("type") === "password" ? "text" : "password");
  });
}

export default togglePassword;
