import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import portrait from "../../assets/lorem-portrait.jpg";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

function ScrollReview({ data }) {
  let srcimg = star1;
  let srcvalue = "star" + data.rating.toString();
  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
    default:
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <div
      className="review border border-success rounded-3 p-3 mb-3"
      style={{ maxWidth: "500px" }}
    >
      <div className="review__header d-flex align-items-center mb-3">
        <img
          className="rounded-circle me-2"
          src={data.profile_url || portrait}
          alt=""
          height="50"
          width="50"
          style={{ maxWidth: "80px" }}
        />
        <div className="stars ms-auto align-middle w-100">
          <div className="form__star reviews d-flex justify-content-start">
            <img
              className="mb-2 mb-md-0"
              src={srcimg}
              alt="notation"
              width="120"
              height="40"
            />
          </div>
        </div>
      </div>
      <div className="review__body">
        <p>
          <Link to={"users/" + data.userid}>
            <span className="review__author">
              {data.nickname || (data.first_name && data.last_name)
                ? data.nickname ||
                  data.first_name +
                    " " +
                    (data.last_name ? data.last_name[0] + "." : "")
                : "Anonymous"}
            </span>{" "}
          </Link>
          {t("HomePageReviewed")} <br />
          <Link
            to={
              data.item_url?.includes("www")
                ? "review-merchant/" + data.item_url
                : "review-product/" + data.item_id + "-" + data.item_name
            }
          >
            <span className="review__societe">{data.item_name}</span>
          </Link>
        </p>
        <p>
          <span className="lead text-success">“</span> {data.content}{" "}
          <span className="lead text-success">”</span>
        </p>
      </div>
    </div>
  );
}

export default ScrollReview;
