import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavListAdmin from "../../components/NavlistAdmin";
import DashboardDivAdmin from "../../components/DashboardDivAdmin";
import BrandModerationComponent from "../../components/BrandModerationComponent";

function BrandModerationPage() {
  const axios = useAxios();

  const [childApproveEvent, setChildApproveEvent] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [allTransactions, setAllTransactions] = useState([]);
  const [allTransactionsFiltred, setAllTransactionsFiltred] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isModerateChecked, setIsModerateChecked] = useState(true);

  const [visibleTransactions, setVisibleTransactions] = useState(20); // Nombre de transactions initialement visibles

  // État pour stocker l'ID de transaction recherché
  const handleCheckboxChange = async () => {
    if (!isModerateChecked) {
      const resp = await axios.get("/brands/search/getall");
      setAllTransactions(
        resp.data
          .sort((a, b) => a.createdAt - b.createdAt)
          .filter((transaction) => transaction.status === "0")
      );
    } else {
      const resp = await axios.get("/brands/search/getall");
      setAllTransactions(resp.data.sort((a, b) => a.createdAt - b.createdAt));
    }
    setIsModerateChecked(!isModerateChecked); // Toggle the checkbox status
  };

  const loadMore = () => {
    setVisibleTransactions(
      (prevVisibleTransactions) => prevVisibleTransactions + 5
    ); // Charger plus de transactions (par exemple, 5 de plus)
  };

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const resp = await axios.get("/brands/search/getall");
      setAllTransactions(
        resp.data
          .sort((a, b) => a.createdAt - b.createdAt)
          .filter((transaction) => transaction.status === "0")
      );
      setAllTransactionsFiltred(
        resp.data.sort((a, b) => a.createdAt - b.createdAt)
      );
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const resp = await axios.get("/brands/search/getall");
      setAllTransactions(
        resp.data
          .sort((a, b) => a.createdAt - b.createdAt)
          .filter((transaction) => transaction.status === "0")
      );
      setAllTransactionsFiltred(
        resp.data.sort((a, b) => a.createdAt - b.createdAt)
      );
    };
    fetchReviews();
  }, [childApproveEvent]);

  const handleSearch = () => {
    // Mettez à jour le nombre de transactions visibles en fonction de la recherche par ID
    if (searchTerm) {
      const filteredTransactions = allTransactionsFiltred.filter(
        (transaction) =>
          transaction.Brand_name.toLowerCase().includes(
            searchTerm.toLowerCase()
          )
      );
      setAllTransactions(filteredTransactions);

      setVisibleTransactions(filteredTransactions.length);
      //.log(filteredTransactions);
    } else {
      setVisibleTransactions(20);
    }
  };

  return (
    <>
      <DashboardDivAdmin titlepage={t("AccountDashboard")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavListAdmin></NavListAdmin>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div class="input-group mb-3">
                      <input
                        id="id_order"
                        className="form-control"
                        type="text"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                        placeholder="Search by Brand name"
                      />
                      <button
                        className="btn btn-success text-white"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                    <div className="row my-2 ms-2">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={isModerateChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Filter by brands to moderate
                        </label>
                      </div>
                    </div>
                    <div
                      id="accordionbrands"
                      className="accordion accordion-flush"
                    >
                      {allTransactions
                        .slice(0, visibleTransactions)
                        .map((Brand) => (
                          <BrandModerationComponent
                            brandData={Brand}
                          ></BrandModerationComponent>
                        ))}
                    </div>

                    {visibleTransactions < allTransactions.length && (
                      <div className="d-grid gap-2 col-md-4 mx-auto">
                        <button
                          className="btn btn-success text-white"
                          onClick={loadMore}
                        >
                          Load More reviews
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BrandModerationPage;
