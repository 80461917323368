import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DashboardDivAdmin from "../../components/DashboardDivAdmin";
import NavListAdmin from "../../components/NavlistAdmin";
import WebshopModerationComponent from "../../components/WebshopModerationComponent";

function WebshopModerationPageUnique() {
  const axios = useAxios();

  const [parentState, setParentState] = useState(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, merchant_id } = useParams();
  const [allTransactions, setAllTransactions] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(lang);
    let fetchReviews = async () => {
      const resp = await axios.get(
        `/merchant_profiles/findmerchant/${merchant_id}`
      );
      setAllTransactions(resp.data);
      // console.log(resp.data);
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DashboardDivAdmin titlepage={t("AccountDashboard")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavListAdmin></NavListAdmin>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div
                      id="accordionbrands"
                      className="accordion accordion-flush"
                    >
                      {allTransactions && (
                        <WebshopModerationComponent
                          webshopData={allTransactions}
                          parentValue={parentState}
                        ></WebshopModerationComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WebshopModerationPageUnique;
