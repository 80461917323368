import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SearchProduct from "../../components/SearchProduct";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function SearchResult() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    const params = new URLSearchParams(window.location.search);
    setSearchTerm(params.get("q"));

    if (searchTerm) {
      axios
        .get(`/search?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
    if (window.location.hash && window.location.hash === "#_=_") {
      window.location.hash = "";
    }
  }, []);

  return (
    <>
      <section className="py-2 py-lg-5">
        <div className="container user_profil">
          <div className="row d-flex align-items-center">
            <div className="col-11 pt-3 mx-auto text-center">
              <h1>
                {t("searchResultPageTitle")} "
                <span className="text-success">{searchTerm}</span>"{" "}
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="search_results bg-success pb-5">
        <div className="container">
          <div className="row">
            <div className="col-11 col-md-12 col-lg-8 py-4 mx-auto">
              {searchResults.map((product, index) => (
                <SearchProduct
                  key={index}
                  product_name={product.product_name}
                  product_id={product.id}
                  type={product.type}
                  propsNbReviews={product.ReviewsNumber}
                  propsRM={product.ReviewMean}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchResult;
