import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect, useRef } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import avatar from "../../assets/lorem-portrait.jpg";
import CreateSelectBrandInput from "../CreateSelectBrandInput";
import { ToastContainer, toast } from "react-toastify";
import AdminCreateSelectBrandInput from "../AdminCreateSelectBrandIput";
import AdminCreateSelectSubCategoryInput from "../AdminCreateSelectSubCategoryInput";
import { Link } from "react-router-dom";
import Modal from "react-modal";
function ProductModerationComponent({
  productData,
  onApproveChild,
  parentValue,
}) {
  const axios = useAxios();
  const [product_name, setProduct_name] = useState(productData.product_name);
  const [ean, setEan] = useState(productData.ean);
  const [modelNumber, setModelNumber] = useState(productData.model_number);
  const [description, setDescription] = useState(productData.description);
  const [categoryIdSelected, SetCategoryIdSelected] = useState();
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [productImageUrl, setProductImageUrl] = useState(
    productData.aw_image_url
  );
  const [datalistProduct, setDatalistProduct] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryName, setCategoryName] = useState(null);
  const [SubcategoryName, setSubCategoryName] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [brandStatus, setBrandStatus] = useState(null);
  const [displayApprouve, setDisplayApprouve] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    productData.category_id.toString()
  ); // État pour stocker la catégorie sélectionnée

  const [selectedValue, setSelectedValue] = useState(
    productData.category_id.toString()
  );

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, product_id } = useParams();

  useEffect(() => {
    setProduct_name(productData.product_name);
    setEan(productData.ean);
    setModelNumber(productData.modelNumber);
    setProductImageUrl(productData.aw_image_url);
    setDescription(productData.description);
    setSelectedValue(productData.category_id.toString());
    setSelectedCategory(productData.category_id.toString());
  }, [parentValue]);
  useEffect(() => {
    i18n.changeLanguage(lang);

    let fetchReviews = async () => {
      const response = await axios.get(`/brands/${productData.Brand_id}`);
      setSelectedBrandId(response.data.id);
      setBrandName(response.data.Brand_name);
      setBrandStatus(response.data.status);
      const resp = await axios.get(
        `/data/categories/get-category-id/${productData.category_id}`
      );

      const res = await axios.get(
        `/data/categories/admin/get-category-id/${productData.category_id}`
      );

      if (window.localStorage.getItem("langPage") === "en") {
        setCategoryName(resp.data.vt_category);
        setSubCategoryName(resp.data.category_name);
      } else {
        setCategoryName(
          resp.data[`category_name_${window.localStorage.getItem("langPage")}`]
        );
        setSubCategoryName(
          resp.data[`category_name_${window.localStorage.getItem("langPage")}`]
        );
      }

      switch (
        res.data[0].category_parent_id === 0
          ? res.data[0].google_category_id
          : res.data[0].category_parent_id
      ) {
        case 111:
        case 922:
          setSelectedValue(111);
          break;

        case 1:
          setSelectedValue(1);
          break;

        case 412:
          setSelectedValue(412);
          break;

        case 141:
        case 222:
        case 2092:
          setSelectedValue(141);
          break;

        case 436:
        case 536:
        case 642:
          setSelectedValue(436);
          break;

        case 783:
          setSelectedValue(783);
          break;

        case 537:
          setSelectedValue(537);
          break;

        case 8:
        case 772:
        case 988:
          setSelectedValue(8);
          break;

        case 1239:
          setSelectedValue(1239);
          break;

        case 888:
          setSelectedValue(888);
          break;

        case 469:
          setSelectedValue(469);
          break;

        case 166:
        case 5181:
          setSelectedValue(166);
          break;

        default:
          break;
      }
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      if (searchTerm.length >= 3) {
        const resp = await axios.get(`/search?q=${searchTerm}`);
        setDatalistProduct(
          resp.data.filter(
            (product) => product.status === "1" && product.type === "product"
          )
        );
      } else {
        setDatalistProduct([]);
      }
    };
    fetchReviews();
  }, [searchTerm]);

  useEffect(() => {
    i18n.changeLanguage(lang);

    let fetchReviews = async () => {
      const response = await axios.get(`/brands/${productData.Brand_id}`);
      setSelectedBrandId(response.data.id);
      setBrandName(response.data.Brand_name);
      setBrandStatus(response.data.status);
      const resp = await axios.get(
        `/data/categories/get-category-id/${productData.category_id}`
      );

      const res = await axios.get(
        `/data/categories/admin/get-category-id/${productData.category_id}`
      );
      if (window.localStorage.getItem("langPage") === "en") {
        setCategoryName(resp.data.vt_category);
        setSubCategoryName(resp.data.category_name);
      } else {
        setCategoryName(
          resp.data[`category_name_${window.localStorage.getItem("langPage")}`]
        );
        setSubCategoryName(
          resp.data[`category_name_${window.localStorage.getItem("langPage")}`]
        );
      }

      switch (
        res.data[0].category_parent_id === 0
          ? res.data[0].google_category_id
          : res.data[0].category_parent_id
      ) {
        case 111:
        case 922:
          setSelectedValue(111);
          break;

        case 1:
          setSelectedValue(1);
          break;

        case 412:
          setSelectedValue(412);
          break;

        case 141:
        case 222:
        case 2092:
          setSelectedValue(141);
          break;

        case 436:
        case 536:
        case 642:
          setSelectedValue(436);
          break;

        case 783:
          setSelectedValue(783);
          break;

        case 537:
          setSelectedValue(537);
          break;

        case 8:
        case 772:
        case 988:
          setSelectedValue(8);
          break;

        case 1239:
          setSelectedValue(1239);
          break;

        case 888:
          setSelectedValue(888);
          break;

        case 469:
          setSelectedValue(469);
          break;

        case 166:
        case 5181:
          setSelectedValue(166);
          break;

        default:
          break;
      }
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, [productData.product_name]);

  function handleApprove() {
    let fetchReviews = async () => {
      await axios.put(`/products/admin-update/${productData.id}`, {
        status: "1",
        product_name: product_name,
        category_id: categoryIdSelected,
        Brand_id: selectedBrandId,
        aw_image_url: productImageUrl,
        ean: ean,
        model_number: modelNumber,
        description: description,
      });
      toast.success("Product updated successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };
    fetchReviews();
    onApproveChild();
    //window.location.reload();
  }
  const handleSelectedCategory = (e) => {
    //setSelectedCategory(e.target.value);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const categoryId = selectedOption.getAttribute("data-category-id");

    //.log("categoryId:", categoryId);

    // Mettre à jour l'état de selectedCategory si nécessaire
    setSelectedCategory(categoryId);
    setSelectedValue(categoryId.split(",")[0]);
  };

  const handleSubCategorySelection = (subCategory) => {
    SetCategoryIdSelected(subCategory.google_category_id);
    setSubCategoryName(subCategory.category_name);
  };

  const handleBrandSelection = (Brand) => {
    setSelectedBrandId(Brand.id);
  };

  const handleChangeProductName = (e) => {
    setProduct_name(e.target.value);
  };

  const handleChangeEan = (e) => {
    setEan(e.target.value);
  };

  const handleChangeModelNumber = (e) => {
    setModelNumber(e.target.value);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "VeritaTrust_x2023Upload_preset_name");

    fetch(`https://api.cloudinary.com/v1_1/drbhco8al/image/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error uploading image to Cloudinary");
        }
        return response.json();
      })
      .then((data) => {
        setProductImageUrl(data.secure_url);
      })
      .catch((error) => {
        //.error("Error uploading image to Cloudinary:", error);
      });
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const handleDeleteProduct = () => {
    setModalOpen(true);
  };

  const confirmDelete = () => {
    // Le code pour supprimer l'élément ici
    let fetchReviews = async () => {
      await axios.get(`/products/deleteone/${productData.id}`);
      toast.success("Product deleted successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };

    fetchReviews();

    onApproveChild();
    setModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleProductChange = (event) => {
    const productName = event.target.value;
    setSearchTerm(event.target.value);
    setSelectedProductName(productName);

    const product = datalistProduct.find((p) => p.product_name === productName);

    if (product) {
      setSelectedProductId(product.id);
      //.log(product.id);
    }
  };

  const handleAssociateProduct = () => {
    let fetchReviews = async () => {
      const resp = await axios.put(
        `/support/productreviews/productreviewbyproductid/${productData.id}`,
        { product_id: selectedProductId }
      );

      await axios.get(`/products/deleteone/${productData.id}`);
      if ((resp.status = 200)) {
        toast.success("Product associated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };
    fetchReviews();
    //window.location.reload();
  };

  return (
    <>
      {" "}
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + productData.id}
            aria-expanded="true"
            aria-controls={productData.id}
          >
            {productData.status === "0" ? (
              <i className="flaticon-time-left me-3 text-warning"></i>
            ) : (
              <i className="flaticon-check me-3 text-success"></i>
            )}
            {productData.product_name}
          </button>
        </h2>
        <div
          id={productData.id}
          className={
            product_id
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          data-bs-parent="#accordionbrands"
        >
          <div className="accordion-body personal-data moderator">
            <div className="row">
              <div className="col-md-2 mb-3">
                <div className="userpicture">
                  <img
                    className="user-profil-avatar"
                    src={productImageUrl || productData.aw_image_url || avatar}
                    alt="user-avatar"
                  />
                  <label className="add-visual" id="userpicture">
                    <input
                      name="userpicture"
                      accept="image/jpeg, image/webp, image/png"
                      type="file"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-7 mb-3">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="Product name"
                  onChange={handleChangeProductName}
                  value={product_name}
                  disabled={!displayApprouve}
                />
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="GTIN or ISBN"
                  onChange={handleChangeEan}
                  value={ean}
                  disabled={!displayApprouve}
                />
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="Model number"
                  onChange={handleChangeModelNumber}
                  value={modelNumber}
                  disabled={!displayApprouve}
                />

                {displayApprouve === true && (
                  <>
                    <textarea
                      className="form-control mb-2"
                      name="description"
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Describe product"
                      onChange={handleChangeDescription}
                      value={description}
                      disabled={!displayApprouve}
                    ></textarea>
                    <p className="mb-1">Select the categorie</p>
                    <select
                      id="categoryProduct"
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedValue}
                      onChange={handleSelectedCategory}
                    >
                      <option value="1" data-category-id="1">
                        {t("AddReviewForBetaTesterCategoryAnimals")}
                      </option>
                      <option value="412" data-category-id="412">
                        {t("AddReviewForBetaTesterCategoryFoodsAndDrunk")}
                      </option>
                      <option value="141" data-category-id="141,222,2092">
                        {t("AddReviewForBetaTesterCategoryHighTech")}
                      </option>
                      <option value="436" data-category-id="436,536,642">
                        {t("AddReviewForBetaTesterCategoryHomeDIY")}
                      </option>
                      <option value="783" data-category-id="783">
                        {t("AddReviewForBetaTesterCategoryCulture")}
                      </option>
                      <option value="537" data-category-id="537">
                        {t("AddReviewForBetaTesterCategoryChilcare")}
                      </option>
                      <option value="111" data-category-id="111,922">
                        {t("AddReviewForBetaTesterCategoryCompanies")}
                      </option>
                      <option value="8" data-category-id="8,772,988">
                        {t("AddReviewForBetaTesterCategorySportLeisure")}
                      </option>
                      <option value="1239" data-category-id="1239">
                        {t("AddReviewForBetaTesterCategoryVideogames")}
                      </option>
                      <option value="888" data-category-id="888">
                        {t("AddReviewForBetaTesterCategoryVehicles")}
                      </option>
                      <option value="469" data-category-id="469">
                        {t("AddReviewForBetaTesterCategoryHealthBeauty")}
                      </option>
                      <option value="166" data-category-id="166,5181">
                        {t("AddReviewForBetaTesterCategoryFashionAccess")}
                      </option>
                    </select>

                    {/* <CreateSelectSubCategoryInput
                      handleSelectSubCategory={handleSubCategorySelection}
                      category_parent_idSelected={selectedCategory}
                    /> */}
                    <AdminCreateSelectSubCategoryInput
                      handleSelectSubCategory={handleSubCategorySelection}
                      category_parent_idSelected={selectedCategory}
                      SubcategoryDefault={SubcategoryName}
                    />
                    {brandName === null && (
                      <CreateSelectBrandInput
                        handleSelectBrand={handleBrandSelection}
                        Category_id={selectedCategory}
                      />
                    )}
                    {brandName && (
                      <AdminCreateSelectBrandInput
                        handleSelectBrand={handleBrandSelection}
                        Category_id={selectedCategory}
                        brandNameDefault={brandName}
                      />
                    )}
                  </>
                )}
                {displayApprouve === false && (
                  <dl className="row">
                    <dt className="col-sm-3">Categories</dt>
                    <dd className="col-sm-9">
                      <span className="badge text-bg-primary me-2">
                        {SubcategoryName}
                      </span>
                    </dd>
                    <dt className="col-sm-3">Brand</dt>
                    <dd className="col-sm-9">
                      <span
                        className={
                          brandStatus === "0"
                            ? "badge bg-warning me-2"
                            : "badge bg-success text-white me-2"
                        }
                      >
                        {brandStatus === "0" ? (
                          <Link
                            to={
                              "/" +
                              window.localStorage.getItem("langPage") +
                              "/admin-brand/" +
                              selectedBrandId
                            }
                          >
                            {brandName}
                          </Link>
                        ) : (
                          brandName
                        )}
                      </span>
                      {brandStatus === "0" ? (
                        <i className="flaticon-time-left me-3 text-warning"></i>
                      ) : (
                        <i className="flaticon-check me-3 text-success"></i>
                      )}
                    </dd>
                  </dl>
                )}
              </div>
              <div className="col-md-3 mb-3">
                {displayApprouve === false && (
                  <>
                    <button
                      className="btn btn-primary w-100 mb-2"
                      onClick={() => {
                        setDisplayApprouve(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-danger w-100"
                      type="button"
                      onClick={handleDeleteProduct}
                    >
                      Delete
                    </button>
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      contentLabel="Delete Product Confirmation"
                    >
                      <p>Are you sure you want to delete this product?</p>
                      <div className="d-flex justify-content-evenly g-2">
                        <button
                          className="btn btn-secondary"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={confirmDelete}
                        >
                          Delete
                        </button>
                      </div>
                    </Modal>
                  </>
                )}
                {displayApprouve === true && (
                  <>
                    <button
                      className="btn btn-danger w-100 mb-2"
                      onClick={() => {
                        setDisplayApprouve(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success w-100 mb-2"
                      onClick={handleApprove}
                    >
                      Approuve
                    </button>

                    <hr className="mb-2" />
                    <ul className="list-group ">
                      <label for="Products">
                        Attribute to product already exist
                      </label>
                      <input
                        className="form-control list-group-item"
                        list="products"
                        placeholder="Type a product"
                        id="Products"
                        onChange={handleProductChange}
                        value={selectedProductName}
                      />
                      <button
                        className="btn bg-warning list-group-item"
                        type="button"
                        onClick={handleAssociateProduct}
                      >
                        Associate
                      </button>
                    </ul>

                    <datalist id="products">
                      {datalistProduct &&
                        datalistProduct.map((product) => (
                          <option value={product.product_name} />
                        ))}
                    </datalist>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ProductModerationComponent;
