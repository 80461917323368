import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import star5 from "../../assets/star-5.jpg";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
// import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function SearchProduct({
  product_name,
  product_id,
  type,
  propsNbReviews,
  propsRM,
  propsLocation,
  propsLang,
}) {
  const [productRoute, setProductRoute] = useState(null);
  const [merchantRoute, setMerchantRoute] = useState(null);
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(propsLang);
  }, []);

  let srcimg = star1;
  let srcvalue = "star" + parseInt(propsRM).toString();
  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
  }

  const formatProduct_name = () => {
    var formatedName = product_name;

    const accents = {
      a: /[àáâãäå]/g,
      e: /[èéêë]/g,
      i: /[ìíîï]/g,
      o: /[òóôõö]/g,
      u: /[ùúûü]/g,
      y: /[ýÿ]/g,
      n: /[ñ]/g,
      c: /[ç]/g,
    };

    for (let char in accents) {
      formatedName = formatedName.replace(accents[char], char);
    }

    formatedName = formatedName.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
    if (propsLocation === "add-review") {
      setProductRoute(
        "/" +
          window.localStorage.getItem("langPage") +
          "/listings/write-review/" +
          product_id +
          "-" +
          formatedName
      );
      setMerchantRoute(
        "/" +
          window.localStorage.getItem("langPage") +
          "/evaluate/" +
          product_name
      );
    } else {
      setProductRoute(
        "/" +
          window.localStorage.getItem("langPage") +
          "/review-product/" +
          product_id +
          "-" +
          formatedName
      );
      setMerchantRoute(
        "/" +
          window.localStorage.getItem("langPage") +
          "/review-merchant/" +
          product_name
      );
    }

    return formatedName;
  };

  useEffect(() => {
    formatProduct_name();
    //.log(product_name);
  }, [product_name]);

  return (
    <>
      <div
        className="result star-5 d-md-flex align-items-center mb-2 mb-lg-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <div className="item categories">
          <a href={type == "product" ? productRoute : merchantRoute}>
            <h3 className="product__name h6 mb-1">{product_name}</h3>
            <span className="lien_global"></span>
          </a>
          <div className="notation d-md-flex align-items-center">
            <div className="d-flex">
              <img
                alt="Noté sur 5 étoiles"
                src={srcimg}
                width="120"
                height="70"
              />
              <p className="mb-2 mb-md-0 mx-3">
                {propsNbReviews} {t("CategoriePageReviews")}
              </p>
            </div>
          </div>
        </div>
        {propsLocation === "add-review" && (
          <div class="ms-auto">
            <button class="btn btn-success">{t("AccountAddReview")}</button>
          </div>
        )}

        {/** <div className="ms-auto">
          <a
            className="add_review mb-2 mb-md-0 border rounded-3 d-block"
            href="/listings/write-review/dell-universal-dock-d6000s"
          >
            Add review
          </a>
        </div> */}
      </div>
    </>
  );
}

// function mapStateToProps(state) {
//   return {
//     sharedValue: state.auth.sharedValue,
//     propsNbReviews: state.auth.propsNbReviews,
//     propsRM: state.auth.propsRM,
//   };
// }

export default SearchProduct;
