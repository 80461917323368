import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardDiv from "../../components/DashboardDiv";
import FollowersListComponent from "../../components/FollowersListComponent";
import NavList from "../../components/NavList";
import ProfileComponentCommunity from "../../components/ProfileComponentCommunity";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
export const UserValueContext = createContext();

function CommunityPage() {
  const axios = useAxios();
  const [reviewNumber, setReviewNumber] = useState(0);
  const [levelclass, setLevelclass] = useState(
    "card text-center d-flex flex-column level0"
  );
  const [followersNumber, setFollowersNumber] = useState(0);
  const [followingsNumber, setFollowingsNmber] = useState(0);

  const [user, setUser] = useState({});
  const [userId, setUserId] = useState(0);
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
      }
      setUser(response.data);
      const res = await axios.get("/lreview-dashboard/" + response.data["id"]);
      setUserId(response.data["id"]);

      //.log("userid: ", userId);

      if (res.data.length > 0) {
        // setTotalRewards(12);
        setReviewNumber(res.data.length);

        switch (res.data[0].level_account) {
          case 0:
            setLevelclass("card text-center d-flex flex-column level0");
            break;
          case 1:
            setLevelclass("card text-center d-flex flex-column level1");
            break;
          case 2:
            setLevelclass("card text-center d-flex flex-column level2");
            break;
          case 3:
            setLevelclass("card text-center d-flex flex-column level3");
            break;
          case 4:
            setLevelclass("card text-center d-flex flex-column level4");
            break;
          default:
        }
      }

      const followers = await axios.get(
        "/data/follow/followers/" + response.data["id"]
      );
      const followings = await axios.get(
        "/data/follow/followings/" + response.data["id"]
      );

      if (followers.data.length > 0) {
        setFollowersNumber(followers.data.length);
      }

      if (followings.data.length > 0) {
        setFollowingsNmber(followings.data.length);
      }
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <UserValueContext.Provider value={user}>
      <DashboardDiv titlepage={t("AccountCommunity")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <ProfileComponentCommunity
                    ReviewNumber={reviewNumber}
                    FollowerNumber={followersNumber}
                    FollowingNumber={followingsNumber}
                    levelclass={levelclass}
                  ></ProfileComponentCommunity>
                  <FollowersListComponent
                    propsUserid={user.id}
                  ></FollowersListComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserValueContext.Provider>
  );
}
const mapStateToProps = (state) => ({
  totalRewards: state.auth.totalRewards,
});

export default connect(mapStateToProps)(CommunityPage);
