import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";

import Modal from "react-bootstrap/Modal";

function ImageModal(props) {
  const { src } = props;

  // Extract merchant from props
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={src?.replace(".pdf", ".jpg")} alt="proofPurchase" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageModal;
