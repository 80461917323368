import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxios } from "../../utils/hook/useAxios";

function VoucherIdComponent({ website, urlImage, cashflow, userid }) {
  const [amount, setAmount] = useState(0);
  const [enable, setEnable] = useState(true);
  const [voucher, setVoucher] = useState({});

  const axios = useAxios();
  const { voucherid } = useParams();

  useEffect(() => {
    axios
      .get(`/VoucherGifts/${voucherid}`)
      .then((response) => {
        setVoucher(response.data);
      })
      .catch((error) => {
        //.error(error);
      });
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    if (cashflow < e.target.value) {
      setAmount(cashflow);
      setEnable(false);
    } else {
      setEnable(true);
    }
  };

  function handleSubmit() {
    axios
      .post("/VoucherGiftTransactions", {
        VoucherGiftId: voucher.id,
        Amount: amount,
        userId: userid,
        status: "0",
      })
      .then((res) => {
        toast.success("Data saved sucessfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        //.log(err);
      });
  }

  return (
    <div
      class="personal-data mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div class="rewards cashback row mt-3 g-0">
        <div class="col-12 mb-4 card shadow p-4 py-md-0">
          <div class="row d-flex align-items-center">
            <div class="col-md-3 border-end d-flex justify-content-center">
              <div class="logo py-3 gap-5 text-center">
                <img
                  src={voucher.VoucherImage}
                  alt="Metamask"
                  height="75"
                  width="75"
                />
              </div>
            </div>
            <div class="col-md-9">
              <div class="py-3 gap-5">
                <h3>
                  <span class="h2 text-success">{voucher.VoucherName}</span>
                </h3>
                <h4>
                  Your balance: <span class="text-green">{cashflow}</span>{" "}
                  <span class="text-primary">VeritaCoins</span> (≃{" "}
                  <span class="text-primary">€</span>{" "}
                  <span class="text-green">{cashflow}</span> )
                </h4>
                <h4 class="h6 text-muted">
                  This gift code may only work in France
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col mb-3">
          <div class="card shadow p-4">
            <form action="">
              <h4>
                Gift card amount available: €{" "}
                <span class="text-green">1.00</span> - €{" "}
                <span class="text-green">{cashflow}</span>
              </h4>
              <p>Indicate the desired currency amount for your gift card</p>
              <div class="form-floating my-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="Enter amount"
                  onChange={handleAmountChange}
                />
                <label for="floatingInput">Enter amount</label>
              </div>

              <button
                type="button"
                class="btn btn-primary btn-lg mb-3"
                disabled={!enable}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </form>
            {!enable && (
              <p className="text-danger">
                Your balance is insufficient to make this withdrawal
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherIdComponent;
