import { useContext, useEffect, useState } from "react";
import logoAmazon from "../../assets/logo-amazon.png";
import logoMetamask from "../../assets/logo-metamask.png";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
// import AOS from "aos";
// import "aos/dist/aos.css";
import CryptoJS from "crypto-js";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SharedValueContext } from "../../pages/RewardPage";
import MetamaskAssociationCTA from "../MetamaskAssociationCTA";
import QRCodeImage from "../QRCodeImage";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt/3R6qvoXNLbaLurkSHr
zEV+n850tYp0RNUNby7tBDs1euwIYjUIvd2RmGA6IDtCu/WNVxa5i0XXEepI3O6S
P8im++gjkDfgBiicCyrAgjl9ZN7JAZy8dSYaw0h/YzfU3aN/l04q/GSMFNaHSzFt
HjfrOfAsEgAqNl3YgdMR3MqPfFZsm7N0BEZhfwW/y7HO6+aX45H0KBs4fM8x08NL
Y0dJGsT3lN72FG/KBh2t+9DbidcIB5R5I89LZeLudsb/zMNy94uR4jktkfP2zw0g
bTkwDe7E/Y5ETN7N09KMAroxCBCifKSSjhTHfuUTM15+cYpassxiMx3pZB/eSD5a
qQIDAQAB
-----END PUBLIC KEY-----`; /* Clé publique récupérée du serveur */

function ClaimRewards({ cryptoValue, voucherValue }) {
  const { sharedValue, walletId, user } = useContext(SharedValueContext);
  const [copyButtonText, setCopyButtonText] = useState("Copy full code");
  const [token, setToken] = useState(null);
  const axios = useAxios();
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);
  //Définition de state pour la formulaire
  const [values, setValues] = useState({
    userId: "",
    token: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(user.twoFactorAuthSecret);
    setCopyButtonText("Copied");
  };

  // Comportement submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      userId: user.id,
      token: values.token,
    };

    axios
      .post("/verify-otp", dataUser)
      .then((res) => {
        // console.log(res);

        if (res.message === "failure") {
          toast.success("Invalid Code", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("2FA Authentication activated successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          window.location.reload();

          // Appel le service api paiement ici
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Invalid Code", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const [codeValue, setCodeValue] = useState({
    token: "",
  });

  const handleCodeChange = (e) => {
    setCodeValue({ ...codeValue, [e.target.name]: e.target.value });
  };

  // Comportement submit
  const handleCodeSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      validationCode: codeValue.token,
    };

    axios
      .post("/transactionCode/verifyCode", dataUser)
      .then((res) => {
        // console.log(res);

        if (res.message === "failure") {
          toast.success("Invalid Code", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Correct code", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          axios
            .post("/userTransactions", {
              type: "crypto",
              amount: 3,
              veritacoins: sharedValue,
              cryptoValue: sharedValue > 0 ? sharedValue * 1 : 0.0,
              cryptoCode: "HMT",
              wallet_id: walletId,
              user_id: user.id,
            })
            .then((res) => {
              // console.log(res);
              //
              window.location.reload();
            })
            .catch((err) => {});

          // Appel le service api paiement ici
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Invalid Code", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  // generate code à 6 chiffres

  function handlePostCode() {
    const code = Math.floor(100000 + Math.random() * 900000);
    const dataUser = { userId: user.id, validationCode: code };

    const payload = JSON.stringify(dataUser);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payload,
      publicKey
    ).toString();

    axios
      .post("/transactionCode", { encryptedPayload })
      .then((res) => {
        // console.log(res);
        //
      })
      .catch((err) => {});
  }

  return (
    <>
      <div
        className="claim__system mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="title">
          <p className="lead me-auto">{t("AccountRewardsClaim")}</p>
        </div>
        <div className="claim__system">
          <div className="accordion accordion-flush" id="rewards">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <div className="claim__item">
                  <img
                    src={logoMetamask}
                    alt="Metamask"
                    height="75"
                    width="75"
                  />
                  <p className="mb-0">
                    <strong>{t("AccountRewardsClaimMetamask")}</strong>
                    <br />

                    {t("AccountRewardsClaimMetamaskMessage", {
                      Cryptoamount: sharedValue > 0 ? sharedValue * 1 : 0.0,
                    })}
                  </p>
                  <button
                    className={
                      cryptoValue >= 4
                        ? "ms-auto btn btn-success collapsed"
                        : "ms-auto btn btn-success collapsed disabled"
                    }
                    data-bs-toggle={cryptoValue >= 4 ? "collapse" : ""}
                    data-bs-target={cryptoValue >= 4 ? "#metamask" : ""}
                    role="button"
                    aria-expanded="false"
                    aria-controls="metamask"
                  >
                    {t("AccountRewardsClaimButton")}
                  </button>
                </div>
              </h2>
              {cryptoValue >= 4 && (
                <div
                  id="metamask"
                  className="accordion-collapse collapse"
                  data-bs-parent="#rewards"
                >
                  <div className="bg-light text-primary p-3 rounded-3 my-2">
                    <h6>
                      <strong>{t("AccountRewardsClaimWithdraw")}</strong>
                    </h6>
                    {/* <p className="mb-2">
                      You are about to convert your{" "}
                      <strong>
                        {sharedValue} VeritaCoins (VT) into {sharedValue * 0.1}{" "}
                        HMT
                      </strong>
                      , and receive them directly on your Metamask wallet.
                    </p> */}
                    <p className="mb-2">
                      {" "}
                      <Trans
                        i18nKey="AccountRewardsClaimMessage1"
                        values={{
                          amount: sharedValue,
                          hmtAmount: sharedValue,
                        }}
                        components={{
                          1: <strong></strong>,
                        }}
                      />
                    </p>
                    <h6 className="mb-2">
                      <strong>{t("AccountRewardsClaimConfirm")}</strong>
                    </h6>
                    {/* <p className="mb-2">
                      I confirm that I want to receive my{" "}
                      <span className="text-success">
                        <strong>
                          {sharedValue} VT points in {sharedValue * 0.1} HMT
                        </strong>
                      </span>{" "}
                      on the following Metamask wallet:{" "}
                      <strong>{walletId}</strong>
                    </p> */}
                    <p className="mb-2">
                      <Trans
                        i18nKey="AccountRewardsClaimMessage2"
                        values={{
                          amount: sharedValue,
                          hmtAmount: sharedValue,
                          walletId: walletId,
                        }}
                        components={{
                          1: (
                            <span className="text-success">
                              <strong></strong>
                            </span>
                          ),
                          2: <strong></strong>,
                        }}
                      />
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-3">
                      {!walletId ? (
                        <MetamaskAssociationCTA
                          metamaskAssociatedProp={false}
                          idprop={user.id}
                        ></MetamaskAssociationCTA>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target="#autheconnector"
                          onClick={handlePostCode}
                        >
                          {t("AccountRewardsClaimConfirmButton")}{" "}
                        </button>
                      )}

                      <button
                        type="button"
                        className="btn btn-danger collapsed"
                        href="#metamask"
                        data-bs-toggle="collapse"
                        aria-controls="metamask"
                        aria-expanded="false"
                      >
                        {t("AccountRewardsClaimCancelButton")}
                      </button>
                    </div>
                    <p className="mb-2">{t("AccountRewardClaimMessage3")}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <div className="claim__item">
                  <img src={logoAmazon} alt="Amazon" height="75" width="75" />
                  <p className="mb-0">
                    <strong>{t("AccountRewardsClaimAmazon")}</strong>
                    <br />

                    {t("AccountRewardsClaimAmazonMessage2", {
                      FiatAmount: sharedValue > 0 ? sharedValue * 0.041 : 0.0,
                      FiatCurrency: user.currency,
                    })}
                  </p>
                  <button
                    className={
                      voucherValue >= 12
                        ? "ms-auto btn btn-success collapsed"
                        : "ms-auto btn btn-success collapsed disabled"
                    }
                    data-bs-toggle={voucherValue >= 12 ? "collapse" : ""}
                    data-bs-target={voucherValue >= 12 ? "#amazon" : ""}
                    role="button"
                    aria-expanded="false"
                    aria-controls="Amazon"
                  >
                    {t("AccountRewardsClaimButton")}
                  </button>
                </div>
              </h2>
              {voucherValue >= 12 && (
                <div
                  id="Amazon"
                  className="accordion-collapse collapse"
                  data-bs-parent="#rewards"
                >
                  <div className="bg-light text-primary p-3 rounded-3 my-2">
                    <h6>
                      <strong>Withdraw my VeritaCoins &gt; Amazon</strong>
                    </h6>
                    <p className="mb-2">
                      You are about to convert your{" "}
                      <strong>
                        {sharedValue} VeritaCoins (VT) ${sharedValue * 0.041}{" "}
                        Amazon voucher
                      </strong>
                      , and receive it directly by email.
                    </p>
                    <h6 className="mb-2">
                      <strong>Please confirm your action:</strong>
                    </h6>
                    <p className="mb-2">
                      I confirm that I want to receive my{" "}
                      <span className="text-success">
                        <strong>
                          {sharedValue} VT points in ${sharedValue * 0.041}{" "}
                          Amazon voucher HMT
                        </strong>
                      </span>{" "}
                      on the following email address:{" "}
                      <strong>{user.email}</strong>
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-3">
                      <button type="button" className="btn btn-success">
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger collapsed"
                        href="#Amazon"
                        data-bs-toggle="collapse"
                        aria-controls="Amazon"
                        aria-expanded="false"
                      >
                        Cancel
                      </button>
                    </div>
                    <p className="mb-2">
                      After clicking the confirmation button, your request will
                      be registered, and you will receive a confirmation e-mail
                      once the request has been validated.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="autheconnector"
        tabindex="-1"
        aria-labelledby="modaltitle"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modaltitle">
                {t("AccountProfileAuthenticator")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {user.twoFactorAuthSecret ? (
              <div className="modal-body">
                <div className="row">
                  <div className="text-center">
                    <p className="lead mb-1">
                      <strong>1</strong> - {t("AccountProfileScanQrCode")}
                    </p>
                    {/* <img className="img-fluid mb-3" src={qrCode} alt="qrcode" /> */}
                    <QRCodeImage base32Secret={user.twoFactorAuthSecret} />

                    <p className="lead mb-1">{t("AccountProfileCopyCode")}</p>
                    <p className="mb-1 text-break col-8 mx-auto text-success">
                      {token != null ? token : ""}
                    </p>

                    <div>
                      <button
                        className="btn btn-primary mb-1"
                        onClick={handleCopy}
                      >
                        {copyButtonText}
                      </button>
                    </div>
                    <hr className="my-4 col-4 mx-auto" />
                    <p className="lead mb-1">
                      <strong>2</strong> - {t("AccountProfileEnterPrivateCode")}
                    </p>
                    <form action="" onSubmit={handleSubmit}>
                      <input
                        className="form-control form-control-lg mb-2"
                        type="text"
                        class="form-control"
                        id="token"
                        title="token"
                        placeholder="token *"
                        name="token"
                        required=""
                        value={values.token}
                        onChange={handleChange}
                      />
                      <button
                        type="submit"
                        class="btn-block btn btn-success text-white btn-lg"
                        id="signinbutton"
                      >
                        {t("AccountProfilePrivateCode")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {" "}
                <div className="modal-body">
                  <div className="row">
                    <div className="text-center">
                      <p className="lead mb-1"></p>
                      <p className="lead mb-1">
                        Please enter your private code
                      </p>
                      <form action="" onSubmit={handleCodeSubmit}>
                        <input
                          className="form-control mb-2"
                          type="text"
                          id="token"
                          title="token"
                          placeholder="token *"
                          name="token"
                          value={codeValue.token}
                          onChange={handleCodeChange}
                        />
                        <button
                          type="submit"
                          className="btn-block btn btn-success text-white btn-lg"
                          id="signinbutton"
                        >
                          Verified private code
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div
        className="modal fade"
        id="autheconnector"
        tabindex="-1"
        aria-labelledby="modaltitle"
        style="display: none;"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modaltitle">
                Authenticator
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {user.twoFactorAuthSecret ? (
              <div className="modal-body">
                <div className="row">
                  <div className="text-center">
                    <p className="lead mb-1">
                      <strong>1</strong> - Scan this QRcode
                    </p>
                    <div className="img-fluid mb-3">
                      <QRCodeImage base32Secret={user.twoFactorAuthSecret} />
                    </div>
                    <p className="lead mb-1">
                      Or copy this code by clicking on button
                    </p>
                    <p className="mb-1 text-break col-8 mx-auto text-success">
                      {user.twoFactorAuthSecret}
                    </p>
                    <div>
                      <button
                        className="btn btn-primary mb-1"
                        onClick={handleCopy}
                      >
                        {copyButtonText}
                      </button>
                    </div>
                    <hr className="my-4 col-4 mx-auto" />
                    <p className="lead mb-1">
                      <strong>2</strong> - Enter your private code
                    </p>
                    <form action="">
                      <input
                        className="form-control mb-2"
                        type="text"
                        id="token"
                        title="token"
                        placeholder="token *"
                        name="token"
                        value=""
                      />
                      <button
                        type="submit"
                        className="btn-block btn btn-success text-white btn-lg"
                        id="signinbutton"
                      >
                        Send private code
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {" "}
                <div className="modal-body">
                  <div className="row">
                    <div className="text-center">
                      <p className="lead mb-1"></p>
                      <p className="lead mb-1">Enter your private code</p>
                      <form action="">
                        <input
                          className="form-control mb-2"
                          type="text"
                          id="token"
                          title="token"
                          placeholder="token *"
                          name="token"
                          value=""
                        />
                        <button
                          type="submit"
                          className="btn-block btn btn-success text-white btn-lg"
                          id="signinbutton"
                        >
                          Verified private code
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )} 
           
          </div>
        </div>
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  totalRewards: state.auth.totalRewards,
});

export default connect(mapStateToProps)(ClaimRewards);
