import React, { useEffect, useState } from "react";
import Web3 from "web3";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function MetamaskButton({ propsMsgButton }) {
  const axios = useAxios();
  const [userWalletAddress, setUserWalletAddress] = useState(null);
  const [web3, setWeb3] = useState(null);

  // on component mount, check for ethereum extension and set web3 instance
  useEffect(() => {
    const initializeWeb3 = async () => {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
        } catch (error) {
          console.error(error);
        }
      } else {
      }
    };
    initializeWeb3();
  }, []);

  // login function
  const loginWithMetamask = async () => {
    if (web3) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const selectedAccount = accounts[0];
        setUserWalletAddress("selectedAccount");
        // //(selectedAccount);
        // window.localStorage.setItem("userWalletAddress", selectedAccount);

        // send userWalletAddress to server to create session

        axios
          .post("/login-authweb3", {
            userWalletAddress: selectedAccount,
          })
          .then((res) => {
            //(res);
            if (res.data) {
              //("welcome in dashboard");
              window.location.href = "/user-dashboard";
              //(res);
            } else {
              //("Error to connect");
            }
          })
          .catch((err) => {
            //(err);
          });
      } catch (error) {
        console.error(error);
      }
    } else {
      //("Wallet not found");
      window.alert("Please install MetaMask or any Ethereum Extension Wallet");
    }
  };

  // logout function
  const logout = () => {
    setUserWalletAddress(null);
    //  window.localStorage.removeItem("userWalletAddress");
  };

  // on component mount, check for existing userWalletAddress in localStorage and update state
  useEffect(() => {
    const storedAddress = ""; // window.localStorage.getItem("userWalletAddress");
    if (storedAddress) {
      setUserWalletAddress(storedAddress);
    }
  }, []);

  return (
    <>
      <button
        className="btn btn-primary"
        id="metaloginbtnbody"
        onClick={loginWithMetamask}
      >
        {propsMsgButton ? propsMsgButton : "Connect your metamask"}
      </button>
    </>
  );
}

export default MetamaskButton;
