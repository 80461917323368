import React, { useState } from "react"; 
import '../../styles/bootstrap.css'
import '../../styles/style.css'
import '../../styles/flaticon_veritatrust.css'
import { useParams } from 'react-router-dom';
import OrgMerchantReviewForm from "../../components/OrgMerchantReviewForm";

function OrgMerchantFormPage()
{

    let { website } = useParams();

    const [product, setProduct] = useState([]);

  /*  useEffect(() => {
        let fetchReviews = async () => {
         
            const res = await axios.get("http://localhost:4000/api/products/"+product_name.toString());
            setProduct(res.data[0]);

            console.log(res.data[0]);
            
        };
        fetchReviews();
    }, []); */

    return (<>
      <OrgMerchantReviewForm website={website} textAreaId="content"></OrgMerchantReviewForm>
    </>)
}

 export default OrgMerchantFormPage;