import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DashboardDiv from "../../components/DashboardDiv";
import NavListAdmin from "../../components/NavlistAdmin";
import ReviewModerationComponent from "../../components/ReviewModerationComponent";
function ReviewModerationPage() {
  const axios = useAxios();
  const [user, setUser] = useState(null);
  const [Reviews, setReviews] = useState([]);
  const [childClickEvent, setChildClickEvent] = useState(false);
  const [childApproveEvent, setChildApproveEvent] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const { transactionId } = useParams();
  const [triggerEffect, setTriggerEffect] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const resp = await axios.get(
        `/support/merchantreviews/searchreviewby-transactionid/${transactionId}`
      );

      setReviews(resp.data);
    };
    if (setTriggerEffect) {
      fetchReviews();
      setTriggerEffect(false);
    }
  }, [triggerEffect]);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/admin");
      const resp = await axios.get(
        `/support/merchantreviews/searchreviewby-transactionid/${transactionId}`
      );
      setUser(response.data);
      setReviews(resp.data);
    };
    fetchReviews();
  }, []);

  // Utilisez useEffect pour surveiller la variable de dépendance
  useEffect(() => {
    if (childClickEvent) {
      let fetchReviews = async () => {
        const response = await axios.get("/admin");
        const resp = await axios.get(
          `/support/merchantreviews/searchreviewby-transactionid/${transactionId}`
        );
        setUser(response.data);
        setReviews(resp.data);
      };
      fetchReviews();
      setChildClickEvent(false);
    }
  }, [childClickEvent]);

  useEffect(() => {
    if (childApproveEvent) {
      let fetchReviews = async () => {
        const response = await axios.get("/admin");
        const resp = await axios.get(
          `/support/merchantreviews/searchreviewby-transactionid/${transactionId}`
        );
        setUser(response.data);
        setReviews(resp.data);
      };
      fetchReviews();
      setChildApproveEvent(false);
    }
  }, [childApproveEvent]);

  // Fonction de rappel pour déclencher l'effet
  const handleTriggerEffect = () => {
    setTriggerEffect(true);
  };

  return (
    <>
      <DashboardDiv titlepage={t("AccountDashboard")} />
      <section class="page_content pb-5 bg-success">
        <div class="container">
          <div class="row">
            <div class="col-12 py-4 mx-auto">
              <div class="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavListAdmin></NavListAdmin>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  {Reviews?.reviews &&
                    Reviews?.reviews.map((reviewData, index) => (
                      <ReviewModerationComponent
                        reviewData={reviewData}
                        onChangeChild={() => setChildClickEvent(true)}
                        onApproveChild={() => setChildApproveEvent(true)}
                        onUpdate={handleTriggerEffect}
                        indexValue={index}
                      ></ReviewModerationComponent>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewModerationPage;
