import React, { useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
//import AOS from "aos";
//import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CreateAddPhotos from "../../components/CreateAddPhotos";
import CreateContentReview from "../../components/CreateContentReview";
import CreateStarsComponent from "../../components/CreateStarsComponent";

import CreateProofPurchaseInput from "../../components/CreateProofPurchaseInput";
// import CreateSelectSubCategoryInput from "../../components/CreateSelectSubCategoryInput";
import ScrollToTop from "react-scroll-to-top";
import CreateExperienceDateInput from "../../components/CreateExperienceDateInput";
import CreateLogin from "../../components/CreateLogin";
import CreateSelectBrandInput from "../../components/CreateSelectBrandInput";
import CreateWebshopInput from "../../components/CreateWebshopInput";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function AddNewReviewBetaTesterPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [collapseShow, setCollapseShow] = useState(false);
  const [langId, setLangId] = useState(null);
  const [jobid, setJobid] = useState(Math.floor(Math.random() * 100000));
  const [merchantID, setmerchantID] = useState(null);
  const [stepperStatus, setStepperStatus] = useState(0);

  const [brandShow, setBrandShow] = useState(false);
  const [webshopShow, setWebshopShow] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);
  const location = useLocation();

  //handle change image and create miniatures

  const axios = useAxios();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    axios
      .get(`/store-lang?lang=${lang}`)
      .then((response) => {})
      .catch((error) => {
        //.error(error);
      });
  }, []);

  useEffect(() => {
    // Vérifiez si le paramètre de recherche "redirected" est présent
    const queryParams = new URLSearchParams(location.search);
    const isRedirected = queryParams.get("redirected");

    if (isRedirected === "true") {
      //.log("La page a été atteinte par une redirection.");
    } else {
      localStorage.setItem("langPage", lang);
      //.log("Acces direct");
    }
  }, [location]);

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm.length >= 3) {
      axios
        .get(`/search-categories-item?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    } else {
      setSearchResults([]);
    }
    //.log(searchResults);
  }, [searchTerm]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    //.log("go to top");
  }, [collapseShow]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    axios
      .get(`/langs/${lang}`)
      .then((response) => {
        setLangId(response.data[0].id);
        //.log("lang selected", response.data);
      })
      .catch((error) => {
        //.error(error);
      });
  }, []);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [experienceDate, setExperienceDate] = useState("");
  const [file, setFile] = useState(null);

  const [startReviewButtonDisabled, setStartReviewButtonDisabled] =
    useState(false);
  const [productReviewed, setProductReviewed] = useState("");

  const [rating, setRating] = useState(0); // État pour stocker la valeur ratingReview

  // Fonction pour mettre à jour la valeur ratingReview
  const updateRating = (newRating) => {
    setRating(newRating);
  };

  const [imageUrls, setImageUrls] = useState([]); // État pour stocker les URLs des images

  // Fonction pour mettre à jour les URLs des images
  const updateImageUrls = (urls) => {
    setImageUrls(urls);
    setFile(urls);
  };

  const [proofOfPurchase, setProofOfPurchase] = useState(null); // État pour stocker le fichier
  // Fonction pour mettre à jour le fichier
  const updateProofOfPurchase = (file) => {
    setProofOfPurchase(file);
  };

  function handleClickCollapseShow() {
    setCollapseShow(false);
    setStartReviewButtonDisabled(false);
    setProductReviewed("");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function handleClickCollapseShow2() {
    window.scrollTo(0, 0);
    //.log("onMouseDoown go to top");
  }

  function handleClickStartReview() {
    setCollapseShow(true);
    setStartReviewButtonDisabled(true);
    setProductReviewed(productName);
    setStepperStatus(1);
  }

  // Cette fonction sera appelée lorsque le formulaire est soumis
  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    // Create new product

    axios
      .post("/products", {
        product_name: productName,
        category_id: selectedCategory,
        Brand_id: selectedBrandId,
      })
      .then((res) => {
        //.log(res);

        // Send Product review

        const formData = new FormData();
        // Ajoutez chaque fichier sélectionné au FormData
        if (file)
          for (let i = 0; i < file.length; i++) {
            formData.append(`files${i}`, file[i]); // Ajoutez chaque fichier à la copie
          }
        formData.append("product_name", productName);
        formData.append("product_id", res.data.id);
        // formData.append("proofOfPurchase", proofOfPurchase);
        formData.append("title", title);
        formData.append("content", content);
        formData.append("rating", rating);
        formData.append("job_id", jobid);
        formData.append("user_id", userData["id"]);
        formData.append("merchant_id", merchantID);
        formData.append("lang_id", langId);

        if (title === "" || content === "" || userData["id"] === null) {
          toast.error("Data incomplete", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          axios
            .post("/data/product-review/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              //.log(res);
              if (res.status === 200) {
                axios
                  .post(
                    "/data/transaction-create",
                    {
                      user_id: userData["id"],
                      merchant_id: null,
                      order_id: null,
                      transaction_id: jobid,
                      proofOfPurchase: proofOfPurchase,
                    },
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    //.log(res);
                  })
                  .catch((err) => {});
              } else {
                // toast.error(
                //   "Last review was submitted within the last 5 minutes",
                //   {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                //   }
                // );
              }
            })
            .catch((err) => {
              //.log(err);
              // toast.error(
              //   "Last review was submitted within the last 5 minutes",
              //   {
              //     position: "top-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     theme: "colored",
              //   }
              // );
            });
        }
      })
      .catch((err) => {
        //.log(err);
        toast.error("Product not created", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

    setMessagesAdded([...messagesAdded, productName]);
    setProductName("");
    setSelectedBrandName("");
    setSelectedCategory("");
    setResetInput(resetInput + 1);
    setBrandShow(false);
    setRating(0);

    setFile(null);

    e.target.reset();
  };

  // Cette fonction sera appelée lorsque le formulaire est soumis

  const handleSubmitMerchant = (e) => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire
    window.scrollTo(0, 0);
    axios
      .get(`/merchant_profiles/${webshop}`)
      .then((response) => {
        setmerchantdata(response.data);
        //.log("Webshop  Id:", response.data.id);

        const review = {
          title: title,
          content: content,
          rating: rating,
          experienceDate: experienceDate,
          job_id: jobid,
          user_id: userData["id"],
          merchant_id: response.data.id,
          order_id: null,
          lang_id: langId,
        };
        //.log(review);

        axios
          .post("/data/merchant-review", review)
          .then((res) => {
            //.log(res);

            if (res.status === 200) {
              toast.success(t("AddReviewForBetaTesterNotification2"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setMessagesAddedMerchant(webshop);

              axios
                .post(
                  "/data/transaction-create",
                  {
                    user_id: userData["id"],
                    merchant_id: merchantdata.id,
                    order_id: null,
                    transaction_id: jobid,
                    proofOfPurchase: proofOfPurchase,
                  },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  //.log(res);
                  window.location.href = "/account";
                })
                .catch((err) => {});
            } else {
              // toast.error(
              //   "Last review was submitted within the last 5 minutes",
              //   {
              //     position: "top-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     theme: "colored",
              //   }
              // );
            }

            // axios
            //   .post(
            //     "/LastReviewRoutes",
            //     {
            //       userId: userData["id"],
            //       LastReviewSubmitDate: new Date(),
            //     },
            //     {
            //       headers: {
            //         "Content-Type": "multipart/form-data",
            //       },
            //     }
            //   )
            //   .then((res) => {
            //     //.log(res);
            //     //  window.location.href = "/account";
            //   })
            //   .catch((err) => {});
          })
          .catch((err) => {
            //.log(err);
            // toast.error("Last review was submitted within the last 5 minutes", {
            //   position: "top-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "colored",
            // });
          });
      })
      .catch((error) => {});

    // Vous pouvez maintenant utiliser les valeurs title et content comme vous le souhaitez
    setStepperStatus(3);
    e.target.reset();
  };

  const [selectedCategory, setSelectedCategory] = useState(1); // État pour stocker la catégorie sélectionnée
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [resetInput, setResetInput] = useState(0);

  const [messagesAdded, setMessagesAdded] = useState([]);
  const [messagesAddedMerchant, setMessagesAddedMerchant] = useState("");

  // const [selectedWebshop, setSelectedWebshop] = useState(""); // État pour stocker la catégorie sélectionnée

  const handleBrandSelection = (Brand) => {
    setSelectedBrandName(Brand.Brand_name);
    setSelectedBrandId(Brand.id);
    setWebshopShow(true);
  };

  const handleWebshopSelection = (webshop) => {
    setWebshop(webshop.website);
    setmerchantID(webshop.id);
  };

  const [productName, setProductName] = useState(null);
  const [webshop, setWebshop] = useState(null);

  const [isShow, setIsShow] = useState(false);

  const handleChangeProductName = (e) => {
    setProductName(e.target.value);
    if (e.target.value >= 3 && selectedCategory && selectedBrandName && webshop)
      setIsShow(true);
    else setIsShow(false);
  };

  const handleSelectedCategory = (e) => {
    //setSelectedCategory(e.target.value);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const categoryId = selectedOption.getAttribute("data-category-id");

    // categoryId contiendra la valeur de data-category-id de l'option sélectionnée
    //.log("categoryId:", categoryId);

    // Mettre à jour l'état de selectedCategory si nécessaire
    setSelectedCategory(categoryId);
    setBrandShow(true);

    if (
      productName.length >= 3 &&
      e.target.value &&
      selectedBrandName &&
      webshop
    )
      setIsShow(true);
    else setIsShow(false);
  };

  const [toggleMerchantProduct, setToggleMerchantProduct] = useState(false);

  function handleToggleMerchantProduct() {
    setMessagesAdded([...messagesAdded, productName]);
    setProductReviewed("");
    setToggleMerchantProduct(true);
    window.scrollTo(0, 0);

    axios
      .post("/products", {
        product_name: productName,
        category_id: selectedCategory,
        Brand_id: selectedBrandId,
      })
      .then((res) => {
        //.log(res);

        // Send Product review

        const formData = new FormData();
        if (file)
          for (let i = 0; i < file.length; i++) {
            formData.append(`files${i}`, file[i]);
          }
        // formData.append("proofOfPurchase", proofOfPurchase);
        formData.append("product_name", productName);
        formData.append("product_id", res.data.id);
        formData.append("title", title);
        formData.append("content", content);
        formData.append("rating", rating);
        formData.append("job_id", jobid);
        formData.append("user_id", userData["id"]);
        formData.append("merchant_id", merchantID);
        formData.append("lang_id", langId);

        if (title === "" || content === "" || userData["id"] === null) {
          toast.error("Data incomplete", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          axios
            .post("/data/product-review/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              //.log(res);
              if (res.status === 200) {
                axios
                  .post(
                    "/data/transaction-create",
                    {
                      user_id: userData["id"],
                      merchant_id: null,
                      order_id: null,
                      transaction_id: jobid,
                      proofOfPurchase: proofOfPurchase,
                    },
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    //.log(res);
                  })
                  .catch((err) => {});
              } else {
                // toast.error(
                //   "Last review was submitted within the last 5 minutes",
                //   {
                //     position: "top-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                //   }
                // );
              }
            })
            .catch((err) => {
              //.log(err);
            });
        }
      })
      .catch((err) => {
        //.log(err);
        toast.error("Product not created", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

    setRating(0);
    setStepperStatus(2);
  }
  const handleExperienceDateChange = (selectedDate) => {
    // Cette fonction sera appelée lorsque la date sera modifiée dans le composant enfant
    setExperienceDate(selectedDate);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState({}); //useSelector((state) => state.auth.user);
  const [merchantdata, setmerchantdata] = useState(null);

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
        setIsAuthenticated(true);
      }

      // const res = await axios.get("/auth/check-auth");
      // setIsAuthenticated(res.data.isAuthenticated);
      //.log(isAuthenticated);

      setUserData(response.data);
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    if (
      productName?.length >= 3 &&
      selectedCategory &&
      selectedBrandName &&
      webshop
    )
      setIsShow(true);
    else setIsShow(false);
  }, [productName, selectedCategory, selectedBrandName, webshop]);

  return (
    <>
      <section className="page_content bg-success pb-5 form">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 py-2 py-lg-4 mx-auto">
              <div className="Content mb-4 bg-white shadow rounded-5 px-2 py-2 p-lg-4">
                <CreateLogin isAuthenticated={isAuthenticated} />
                {isAuthenticated && (
                  <>
                    <div className="progress mb-3" style={{ height: "2rem" }}>
                      <div
                        class={
                          stepperStatus >= 1
                            ? "progress-bar bg-success"
                            : "progress-bar progress-bar-striped progress-bar-animated "
                        }
                        role="progressbar"
                        style={{ width: "34%" }}
                        aria-valuenow="34"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <strong>1</strong>
                      </div>
                      <div
                        class={
                          stepperStatus >= 1
                            ? stepperStatus >= 2
                              ? "progress-bar bg-success"
                              : "progress-bar progress-bar-striped progress-bar-animated"
                            : "progress-bar bg-light text-dark"
                        }
                        role="progressbar"
                        style={{ width: "33%" }}
                        aria-valuenow="33"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <strong>2</strong>
                      </div>
                      <div
                        class={
                          stepperStatus >= 2
                            ? stepperStatus >= 3
                              ? "progress-bar bg-success"
                              : "progress-bar progress-bar-striped progress-bar-animated"
                            : "progress-bar bg-light text-dark"
                        }
                        role="progressbar"
                        style={{ width: "33%" }}
                        aria-valuenow="33"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <strong>3</strong>
                      </div>
                    </div>
                    {stepperStatus !== 3 && (
                      <p className="callout callout-info">
                        {stepperStatus === 2
                          ? t("AddReviewForBetaTesterMerchantReview")
                          : stepperStatus === 0
                          ? t("AddReviewForBetaTesterCreateProduct1")
                          : ""}
                        {stepperStatus === 1
                          ? t("AddReviewForBetaTesterProductReview")
                          : ""}
                      </p>
                    )}
                  </>
                )}
                <div className="mt-1" id="add-reviews-product">
                  {!toggleMerchantProduct && (
                    <div
                      className={isAuthenticated ? "mb-3" : "mb-3 blur-8"}
                      id="product"
                    >
                      {messagesAdded.length > 0 && (
                        <div className="list-group mb-3">
                          {messagesAdded &&
                            messagesAdded.map((message, index) => (
                              <div
                                key={index}
                                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                              >
                                <h5 className="mb-0">{message}</h5>
                                <span className="text-success p-0">
                                  <i className="flaticon-check"></i>
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                      <h2 className="text-success">
                        {t("AddReviewForBetaTesterMsg1")}
                      </h2>

                      {
                        <form onSubmit={handleSubmit}>
                          {
                            <div
                              className={
                                startReviewButtonDisabled ? "d-none" : ""
                              }
                            >
                              <div className="row mb-md-3">
                                <label
                                  for="productName"
                                  className="col-md-3 col-form-label"
                                >
                                  {t("AddReviewForBetaTesterProductName")}
                                  <sup>*</sup>
                                </label>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="productName"
                                    value={productName}
                                    onChange={handleChangeProductName}
                                    required
                                    placeholder={t(
                                      "AddReviewForBetaTesterProductNamePlaceholder"
                                    )}
                                    disabled={startReviewButtonDisabled}
                                  />
                                </div>
                              </div>
                              <div className="row mb-md-3">
                                <label
                                  for="categoryProduct"
                                  className="col-md-3 col-form-label"
                                >
                                  {t("AddReviewForBetaTesterSelectCat")}
                                  <sup>*</sup>
                                </label>
                                <div className="col-md-9">
                                  <select
                                    id="categoryProduct"
                                    size="4"
                                    className="form-select"
                                    aria-label="Default select example"
                                    // value={selectedCategory}
                                    onChange={handleSelectedCategory}
                                    disabled={startReviewButtonDisabled}
                                  >
                                    <option value="1" data-category-id="1">
                                      {t(
                                        "AddReviewForBetaTesterCategoryAnimals"
                                      )}
                                    </option>
                                    <option value="412" data-category-id="412">
                                      {t(
                                        "AddReviewForBetaTesterCategoryFoodsAndDrunk"
                                      )}
                                    </option>
                                    <option
                                      value="222"
                                      data-category-id="141,222,2092"
                                    >
                                      {t(
                                        "AddReviewForBetaTesterCategoryHighTech"
                                      )}
                                    </option>
                                    <option
                                      value="536"
                                      data-category-id="436,536,642"
                                    >
                                      {t(
                                        "AddReviewForBetaTesterCategoryHomeDIY"
                                      )}
                                    </option>
                                    <option value="783" data-category-id="783">
                                      {t(
                                        "AddReviewForBetaTesterCategoryCulture"
                                      )}
                                    </option>
                                    <option value="537" data-category-id="537">
                                      {t(
                                        "AddReviewForBetaTesterCategoryChilcare"
                                      )}
                                    </option>
                                    <option
                                      value="111"
                                      data-category-id="111,922"
                                    >
                                      {t(
                                        "AddReviewForBetaTesterCategoryCompanies"
                                      )}
                                    </option>
                                    <option
                                      value="8"
                                      data-category-id="8,772,988"
                                    >
                                      {t(
                                        "AddReviewForBetaTesterCategorySportLeisure"
                                      )}
                                    </option>
                                    <option
                                      value="1239"
                                      data-category-id="1239"
                                    >
                                      {t(
                                        "AddReviewForBetaTesterCategoryVideogames"
                                      )}
                                    </option>
                                    <option value="888" data-category-id="888">
                                      {t(
                                        "AddReviewForBetaTesterCategoryVehicles"
                                      )}
                                    </option>
                                    <option value="469" data-category-id="469">
                                      {t(
                                        "AddReviewForBetaTesterCategoryHealthBeauty"
                                      )}
                                    </option>
                                    <option
                                      value="166"
                                      data-category-id="166,5181"
                                    >
                                      {t(
                                        "AddReviewForBetaTesterCategoryFashionAccess"
                                      )}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              {/* {subcategoryShow && (
                                <CreateSelectSubCategoryInput
                                  handleSelectSubCategory={
                                    handleSubCategorySelection
                                  }
                                  startReviewButtonDisabled={
                                    startReviewButtonDisabled
                                  }
                                  category_parent_idSelected={selectedCategory}
                                  resetInput={resetInput}
                                />
                              )} */}
                              {brandShow && (
                                <CreateSelectBrandInput
                                  handleSelectBrand={handleBrandSelection}
                                  startReviewButtonDisabled={
                                    startReviewButtonDisabled
                                  }
                                  Category_id={selectedCategory}
                                  resetInput={resetInput}
                                />
                              )}

                              {webshopShow && (
                                <CreateWebshopInput
                                  handleSelectWebshop={handleWebshopSelection}
                                  startReviewButtonDisabled={
                                    startReviewButtonDisabled
                                  }
                                  resetInput={resetInput}
                                />
                              )}
                            </div>
                          }
                          {/* <div className="callout callout-error mt-1">
                        Complete all mandatory fields
                      </div> */}
                          {!startReviewButtonDisabled && (
                            <button
                              className="btn btn-primary w-100 mt-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#reviewProduct"
                              aria-expanded="false"
                              aria-controls="reviewProduct"
                              disabled={!isShow}
                              onClick={handleClickStartReview}
                            >
                              {t("AddReviewForBetaTesterStartYourReview")}
                            </button>
                          )}
                          <div
                            className={
                              collapseShow === true
                                ? "collapse mt-3 show"
                                : "collapse mt-3"
                            }
                            id="reviewProduct"
                          >
                            <div className="form__header">
                              <div className="d-flex align-items-center">
                                <div className="col-12 col-md-12 col-xl-9">
                                  <div className="form__header__note">
                                    <p className="lead">
                                      {t("AddReviewForBeaTesterMsgt2")}{" "}
                                      <span className=" text-success">
                                        {productReviewed}
                                      </span>
                                      ?<sup>*</sup>
                                    </p>
                                    <CreateStarsComponent
                                      updateRating={updateRating}
                                      resetInput={resetInput}
                                    ></CreateStarsComponent>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form__content">
                              <CreateContentReview
                                textAreaId="content"
                                onTitleChange={(value) => setTitle(value)}
                                onContentChange={(value) => setContent(value)}
                                resetInput={resetInput}
                              />
                              <CreateAddPhotos
                                updateImageUrls={updateImageUrls}
                                resetInput={resetInput}
                              />
                            </div>
                            <hr />
                            <CreateProofPurchaseInput
                              updateProofOfPurchase={updateProofOfPurchase}
                              resetInput={resetInput}
                            />

                            <p className="text-muted">
                              <p className="text-muted mt-2">
                                {t("AddReviewForBetaTesterMsg6")}
                              </p>
                            </p>

                            <div className="form__footer">
                              <div className="d-md-flex justify-content-between gap-3">
                                <div className="w-100">
                                  {proofOfPurchase && (
                                    <div class="callout callout-info mt-1">
                                      {t("AddReviewForBetaTesterInfos1")}
                                    </div>
                                  )}

                                  <button
                                    type="submit"
                                    className="btn-block btn btn-outline-primary w-100"
                                    onClick={() => {
                                      handleClickCollapseShow();
                                      handleClickCollapseShow2();
                                    }}
                                    // onMouseDown={handleClickCollapseShow2}
                                    data-bs-target="#reviewProduct" // Cible le même élément collapse pour le fermer
                                    disabled={
                                      proofOfPurchase &&
                                      rating > 0 &&
                                      title !== "" &&
                                      content !== ""
                                        ? false
                                        : true
                                    }
                                  >
                                    {t("AddReviewForBetaTesterSaveAdd")}
                                  </button>
                                </div>
                                <div class="orbymail text-center w-25 mx-auto">
                                  <div class="separator">
                                    {t("AddReviewForBetaTesterOr")}
                                  </div>
                                </div>
                                <div className="w-100">
                                  {proofOfPurchase && (
                                    <div class="callout callout-success mt-1">
                                      {t("AddReviewForBetaTesterInfos2")}
                                    </div>
                                  )}

                                  <button
                                    type="submit"
                                    className="btn-block btn btn-outline-success w-100 mb-3 mb-md-0"
                                    onClick={handleToggleMerchantProduct}
                                    disabled={
                                      proofOfPurchase &&
                                      rating > 0 &&
                                      title !== "" &&
                                      content !== ""
                                        ? false
                                        : true
                                    }
                                  >
                                    {t("AddReviewForBetaTesterSubmitReview")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      }
                    </div>
                  )}

                  {toggleMerchantProduct && (
                    <div className="mb-3" id="merchant">
                      {stepperStatus === 3 && (
                        <>
                          <h2>
                            {t("AddReviewForBetaTesterConratulationsMsg1")}
                          </h2>
                          <p>{t("AddReviewForBetaTesterConratulationsMsg2")}</p>
                          <hr className="my-4" />
                          <h2 className="mt-3">
                            {t("AddReviewForBetaTesterProductReviewSaved")}
                          </h2>
                          {messagesAdded.length > 0 && (
                            <div className="list-group mb-3">
                              {messagesAdded &&
                                messagesAdded.map((message, index) => (
                                  <div
                                    key={index}
                                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                  >
                                    <h5 className="mb-0">{message}</h5>
                                    <span className="text-success p-0">
                                      <i className="flaticon-check"></i>
                                    </span>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                      <form onSubmit={handleSubmitMerchant}>
                        <div className="mt-3" id="reviewmerchant">
                          {messagesAddedMerchant.length > 0 ? (
                            <>
                              <h2 className="text-muted">
                                {t("AddReviewForBetaTesterMerchantReviewSaved")}
                              </h2>
                              <div className="list-group mb-3">
                                <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                  <h5 className="mb-0">
                                    {messagesAddedMerchant}
                                  </h5>
                                  <span className="text-success p-0">
                                    <i className="flaticon-check"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="form__footer mt-5">
                                <div className="d-md-flex justify-content-between gap-3">
                                  <a
                                    className="btn-block btn btn-outline-primary w-100 mb-3"
                                    href="/add-review-create-for-beta"
                                  >
                                    {t("AddReviewForBetaTesterAddNewReview")}
                                  </a>
                                  <a
                                    className="btn-block btn btn-primary w-100 mb-3"
                                    href="/account"
                                  >
                                    {t("AddReviewForBetaTesterGodashboard")}
                                  </a>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <h2 className="text-success">
                                {t("AddReviewForBetaTesterWriteMerchantReview")}
                              </h2>

                              <div className="form__header">
                                <div className="d-flex align-items-center">
                                  <div className="col-12 col-md-12 col-xl-9">
                                    <div className="form__header__note">
                                      <p className="lead">
                                        {t("AddReviewForBeaTesterMsgt2")}{" "}
                                        <span className="text-success">
                                          {webshop}
                                        </span>
                                        ?<sup>*</sup>
                                      </p>
                                      <CreateStarsComponent
                                        updateRating={updateRating}
                                      ></CreateStarsComponent>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form__content">
                                <CreateContentReview
                                  textAreaId="content"
                                  onTitleChange={(value) => setTitle(value)}
                                  onContentChange={(value) => setContent(value)}
                                />
                                <CreateExperienceDateInput
                                  onChangeExperienceDate={
                                    handleExperienceDateChange
                                  }
                                />
                              </div>

                              <p className="text-muted mt-2">
                                {t("AddReviewForBetaTesterMsg6")}
                              </p>
                              <div className="form__footer">
                                <div className="d-grid gap-2">
                                  <button
                                    type="submit"
                                    className="btn-block btn btn-primary btn-lg text-uppercase text-right"
                                    disabled={
                                      rating > 0 &&
                                      title !== "" &&
                                      content !== "" &&
                                      experienceDate !== ""
                                        ? false
                                        : true
                                    }
                                  >
                                    {t("AddReviewForBetaTesterPublish")}
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#6f00ff" size={50} />
      </section>
    </>
  );
}

export default AddNewReviewBetaTesterPage;
