import React, { useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPasswordPage() {
  const axios = useAxios();

  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      email: values.email,
    };

    axios
      .post("/userprofile/resetpassword", dataUser)
      .then((res) => {
        //.log(res);

        toast.success("data saved sucessfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.href = "/update-email-confirmation";
      })
      .catch((err) => {
        //.log(err);
        toast.error("server url don't not exist", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <>
      <section class="py-5 form">
        <form action="" onSubmit={handleSubmit}>
          <div class="container">
            <div class="row d-flex align-items-center">
              <div class="col-11 col-lg-8 pt-3 mx-auto text-center">
                <h3>Can't log in?</h3>
              </div>
              <div class="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
                <div class="form__account">
                  <div class="row d-flex flex-column align-items-center">
                    <div class="col-12 col-md-6 g-0 mx-auto">
                      <div class="orbymail text-center">
                        <div class="separator">
                          We'll send you a recovery link to
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="mb-3">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          title="Email"
                          placeholder="Email *"
                          name="email"
                          required=""
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>

                      <hr />
                    </div>

                    <div class="col-12 col-md-6 mx-auto">
                      <div class="form__footer">
                        <div class="d-grid gap-2">
                          <button
                            type="submit"
                            class="btn-block btn btn-success text-white btn-lg"
                            id="signinbutton"
                          >
                            Send recovery link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}

export default ResetPasswordPage;
