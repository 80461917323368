import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import coinimg from "../../assets/coinvalid.png";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ReviewValid() {
  const { t, i18n } = useTranslation();

  const [colors, setColors] = useState([
    "#02a68a",
    "#789456",
    "#333333",
    "#002d6b",
  ]);
  const [userId, setUserId] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  const axios = useAxios();

  useEffect(() => {
    const invitationUrl = "https://api.veritatrust.com/preview/" + id;
    axios
      .get("/data/invitationdata-completed/?invitationUrl=" + invitationUrl)
      .then((res) => {
        if (res == null) navigate("/404", { replace: true });

        //.log(res);

        setUserId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[1]
            .split("=")[1]
        );
        let userid = res.data.invitation_url_complete
          .split("?")[1]
          .split("&")[1]
          .split("=")[1];
        setUrlLink("/confirmaaccount/" + id);
        axios
          .get("/users/" + userid)
          .then((res) => {
            if (res.data.hasPassword) setUrlLink("/signin/");
            else setUrlLink("/confirmaaccount/" + id);
          })
          .catch((err) => {
            //.log(err);
          });
      })
      .catch((err) => {
        //.log(err);
      });
  }, []);

  useEffect(() => {
    startCelebration();
  }, []);

  const [isCelebrating, setIsCelebrating] = useState(false);

  const startCelebration = () => {
    setIsCelebrating(true);

    setTimeout(() => {
      setIsCelebrating(false);
    }, 5000); // set the duration of the animation to 5 seconds
  };

  return (
    <section className="py-5 form valid">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-11 col-lg-8 py-3 mx-auto position-relative">
            <ul className="step-reviews nav text-center">
              <li className="nav-item w-33 step-1 done">
                <span className="badge rounded-circle">1</span>
                <p className="mb-0">{t("arianeMerchant")}</p>
              </li>
              <li className="nav-item w-33 step-2 done">
                <span className="badge rounded-circle">2</span>
                <p className="mb-0" aria-current="page">
                  {t("arianeProduct")}
                </p>
              </li>
              <li className="nav-item w-33 step-3 active">
                <span className="badge rounded-circle">3</span>
                <p className="mb-0">{t("arianeReview")}</p>
              </li>
            </ul>

            <div id="line">
              <div id="line-progress"></div>
            </div>
          </div>
          <div className="col-11 col-lg-8 py-4 px-lg-4 mx-auto result-ok">
            <div className="form__header">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-xl-9 text-center">
                  <img
                    className="align-self-center mb-4"
                    src={coinimg}
                    alt="Product name"
                  />
                  <p className="lead text-green">
                    {t("validpageCongrat")}
                    <br />
                    {t("validpageDesc")}
                  </p>
                  <p className="">
                    {t("validpagePara")} <strong> tokens 🥇</strong>.
                  </p>
                  <p className="lead text-green d-none">
                    Get more reward and review more.
                  </p>
                  <div class="btn-group" role="group" aria-label="Add review">
                    <a href="#" class="btn btn-outline-success d-none">
                      Write new review
                    </a>
                    <Link to={urlLink} class="btn btn-outline-success">
                      {t("validpageCta")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCelebrating && (
        <Confetti numberOfPieces="200" colors={colors} tweenDuration={5000} />
      )}
    </section>
  );
}

export default ReviewValid;
