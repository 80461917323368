import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import moment from "moment";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import PopoverVerified from "../PopoverVerified";
import NoPicture from "../../assets/no-picture.png";
import { Trans, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";

function UserReviewComponent({ data }) {
  let srcvalue = "star" + data.rating.toString();
  let srcimg = star1;
  let statusClass = "pending";
  let statusSymbolClass = "flaticon-time-left flaticon-pending";
  let classBadgeReview = "badge bg-success";
  const [merchantName, setMerchantName] = useState("");
  const [hashtransaction, setHashtransaction] = useState("");

  const axios = useAxios();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      if (data.review_type === "merchant_review") {
        const response = await axios.get(
          "/merchant_profiles/findmerchant/" + data.product_name
        );
        setMerchantName(response.data?.name);
      }
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    let fetchHashTransaction = async () => {
      axios
        .get(`/data/transaction-get/${data.job_id}`)
        .then((response) => {
          setHashtransaction(response.data.hash_transaction);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchHashTransaction();
  }, []);

  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;

    default:
  }

  if (data.statu === "pending") {
    statusClass = "pending";
    statusSymbolClass = "flaticon-time-left flaticon-pending";
  } else {
    statusClass = "complete";
    statusSymbolClass = "flaticon-cube-3d flaticon-complete";
  }

  if (data.review_type === "merchant_review") {
    classBadgeReview =
      "certified small btn btn-light text-primary d-inline px-1 rounded-3 ms-lg-3 my-1 my-lg-0 d-block d-lg-inline-block";
  } else {
    classBadgeReview =
      "certified small btn btn-light text-success d-inline px-1 rounded-3 ms-lg-3 my-1 my-lg-0 d-block d-lg-inline-block";
  }

  let productRoute = "/productreview/" + data.id;
  let merchantRoute = "/merchantreview/" + data.id;

  return (
    <>
      <div className={"review star-" + data.rating + " d-flex py-3 mb-4"}>
        <div className="product">
          <h3 className="product__name h6 mb-1">
            {data.review_type === "merchant_review"
              ? merchantName
              : data.product_name}
          </h3>
          <h4 className="product__name lead mb-2">
            <Link
              to={
                data.review_type == "product_review"
                  ? "/" + t("lang") + productRoute
                  : "/" + t("lang") + merchantRoute
              }
            >
              <p className="mb-0">
                <strong>
                  {data.title.length > 125
                    ? data.title.substring(0, 125) + "..."
                    : data.title}{" "}
                </strong>
              </p>
            </Link>
          </h4>
          <div className="notation d-md-flex align-items-center">
            <img
              className="mb-2 mb-md-0 me-lg-3"
              src={srcimg}
              alt="notation"
              width="100"
              height="40"
            />{" "}
            <PopoverVerified hash_transaction={hashtransaction} />
            <span className={classBadgeReview}>
              {data.review_type.replace("_", " ")}
            </span>
            <p className="mb-0 text-primary ms-auto">
              {moment(data.Created).calendar()}
            </p>
          </div>
        </div>
        <div className="logo ms-auto">
          <img
            className="review__img__profil"
            src={data?.image_video?.split(",")[0] || NoPicture}
            alt="Product name"
            height="100"
            width="100"
          />
        </div>
      </div>
    </>
  );
}

export default UserReviewComponent;
