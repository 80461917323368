import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

function Categories({ lang }) {
  const { t } = useTranslation();

  const categorylist = [
    [
      "1445-food-drink",
      "flaticon flaticon-healthy-food me-3",
      t("AddReviewForBetaTesterCategoryFoodsAndDrunk"),
    ],
    [
      "1-animals",
      "flaticon flaticon-pet me-3",
      t("AddReviewForBetaTesterCategoryAnimals"),
    ],
    [
      "141-high-tech",
      "flaticon flaticon-responsive me-3",
      t("AddReviewForBetaTesterCategoryHighTech"),
    ],
    [
      "1300-home-diy",
      "flaticon flaticon-furnitures me-3",
      t("AddReviewForBetaTesterCategoryHomeDIY"),
    ],
    [
      "783-culture",
      "flaticon flaticon-books me-3",
      t("AddReviewForBetaTesterCategoryCulture"),
    ],
    [
      "537-childcare",
      "flaticon flaticon-baby-products me-3",
      t("AddReviewForBetaTesterCategoryChilcare"),
    ],
    [
      "111-companies-b2b",
      "flaticon flaticon-prototype me-3",
      t("AddReviewForBetaTesterCategoryCompanies"),
    ],
    [
      "1029-sports-leisure",
      "flaticon flaticon-sport me-3",
      t("AddReviewForBetaTesterCategorySportLeisure"),
    ],
    [
      "1239-video-games-toys",
      "flaticon flaticon-toys me-3",
      t("AddReviewForBetaTesterCategoryVideogames"),
    ],
    [
      "2528-vehicles",
      "flaticon flaticon-electric-scooter me-3",
      t("AddReviewForBetaTesterCategoryVehicles"),
    ],
    [
      "469-health-beauty",
      "flaticon flaticon-cosmetics me-3",
      t("AddReviewForBetaTesterCategoryHealthBeauty"),
    ],
    [
      "1604-fashion-accessories",
      "flaticon flaticon-hood me-3",
      t("AddReviewForBetaTesterCategoryFashionAccess"),
    ],
  ];

  return (
    <>
      <section className="bg-success home__categorie">
        <div className="container py-5">
          <div className="row mb-4">
            <div className="col-lg-8">
              <h4 className="display-6 text-white">{t("HomepageExplore")}</h4>
            </div>
            <div className="col-lg-4 text-end">
              <Link
                to={"/" + t("lang") + "/c"}
                className="btn btn-primary mt-3"
              >
                {t("HomepageAllcategories")}
              </Link>
            </div>
          </div>
          <ul className="row text-white category">
            {categorylist.map((categoryname, index) => (
              <li key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                <Link
                  className="p-1 p-md-2 p-lg-3 bg-white text-primary rounded-5"
                  to={"/" + t("lang") + "/c/" + categoryname[0]}
                >
                  <i className={categoryname[1]}></i> {categoryname[2]}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}

export default Categories;
