import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import io from "socket.io-client";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import NotificationItem from "../../components/NotificationItem";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function NotificationPage() {
  const axios = useAxios();
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();
  let socket;

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
      }
      socket = io("https://api.veritatrust.com"); // Remplacez par l'URL correcte
      // Vérifier si la connexion WebSocket est établie
      socket.on("connect", () => {
        console.log("WebSocket Client Connected successfully lorem ipsum ");
        console.log("Socket ID:", socket.id);
        socket.emit("register", response.data["id"]);
        // Écouter l'événement "notification" pour recevoir les notifications
      });

      socket.on("notification", (notification) => {
        setNotifications((prevNotifications) => [
          notification[0],
          ...prevNotifications,
        ]);
        console.log(notification);
      });

      const res = await axios.get("/notification/" + response.data["id"]);

      if (res.data.length > 0) {
        setNotifications(res.data);
      }
    };
    fetchReviews();
    // Nettoyer les événements WebSocket lors du démontage du composant
    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("notification");
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // handles functions for filter

  const handleAllNotifications = async () => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
      }
      const res = await axios.get("/notification/" + response.data["id"]);

      if (res.data.length > 0) {
        setNotifications(res.data);
      }
    };
    fetchReviews();
  };

  const handleUnreadNotifications = async () => {
    setNotifications(
      notifications.filter((notification) => notification.isViewed === "0")
    );
  };

  return (
    <>
      <DashboardDiv titlepage={t("AccountNotifications")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    class="mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <h2>{t("AccountNotifications")}</h2>
                    <input
                      type="radio"
                      class="btn-check"
                      name="reviews"
                      id="all-notifications"
                      autocomplete="off"
                      defaultChecked
                    />
                    <label
                      class="btn btn-outline-success rounded-5 me-2"
                      for="all-notifications"
                      onClick={handleAllNotifications}
                    >
                      {t("AccountNotificationsFilterAll")}
                    </label>
                    <input
                      type="radio"
                      class="btn-check"
                      name="reviews"
                      id="unread-notifications"
                      autocomplete="off"
                    />
                    <label
                      className="btn btn-outline-success rounded-5"
                      for="unread-notifications"
                      onClick={handleUnreadNotifications}
                    >
                      {t("AccountNotificationsFilterUnread")}
                    </label>

                    <div className="list mt-3">
                      {notifications &&
                        notifications.map((notif, index) => (
                          <div>
                            <NotificationItem key={index} data={notif} />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotificationPage;
