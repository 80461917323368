import { useState, useEffect, useContext } from "react";
import "../../styles/style.css";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLayoutEffect } from "react";

function ProfileComponentCommunity({
  ReviewNumber,
  FollowerNumber,
  FollowingNumber,
  levelclass,
}) {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <>
      <div
        className="community mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="rewards row">
          <div className="col-4 col-md-4 mb-3 mb-md-0 order-4 order-md-1">
            <div className={levelclass}>
              <div className="icone wiggle">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="card__title">{ReviewNumber}</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                {t("AccountCommunityReviews")}
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 mb-3 mb-md-0 order-2">
            <div className="card text-center d-flex flex-column approved">
              <div className="icone">
                <i className="flaticon-rating"></i>
              </div>
              <h3 className="card__title">{FollowerNumber}</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                {t("AccountCommunityFollowers")}
              </p>
            </div>
          </div>
          <div className="col-4 col-md-4 mb-3 mb-md-0 order-3">
            <div className="card text-center d-flex flex-column approved">
              <div className="icone">
                <i className="flaticon-rating-1"></i>
              </div>
              <h3 className="card__title">{FollowingNumber}</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                {t("AccountCommunityFollowing")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileComponentCommunity;
