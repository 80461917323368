// Composant React
import React from "react";
import ReactMarkdown from "react-markdown";
import { useState, useEffect } from "react";

const ConditionsDeVente = () => {
  //.log(conditionsDeVente); // Vérifiez le contenu du fichier dans la //

  const [markdown, setMarkdown] = useState("");
  const Base_url = "http://dev.veritatrust.com/MarkDowns";

  useEffect(() => {
    fetch(`${Base_url}/conditiondevente.md`)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <>
      <section class="py-5">
        <div class="container user_profil">
          <div class="row d-flex align-items-center">
            <div class="col-11 pt-3 mx-auto text-center">
              <h1 class="display-6 text-primary">About Veritatrust</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 mb-md-3 justify-content-center">
                  <li class="breadcrumb-item">
                    <a href="./">
                      <i class="flaticon-home"></i> Home
                    </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section class="page_content bg-success pb-5">
        <div class="container">
          <div class="row">
            <div class="col-11 col-md-12 col-lg-10 py-4 mx-auto">
              <div class="Content mb-4 bg-white shadow rounded-5 p-4">
                <ReactMarkdown>{markdown}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConditionsDeVente;
