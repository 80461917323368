import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function NavListAdmin() {
  const location = useLocation();

  const axios = useAxios();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const handleLogout = () => {
    // dispatch(logout());

    axios.get("/logout").then((res) => {
      window.location.href = "/";
    });

    //window.location.reload(true);
  };

  return (
    <div
      className="sticky-top bg-white p-3 mb-3 rounded-5 shadow widget enterprise aos animated aos-init aos-animate"
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
    >
      <nav>
        <h3 className="lead">Navigation</h3>
        <ul className="list-unstyled user-menu">
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-dashboard` ||
                location.pathname.includes("review-moderation")
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-dashboard`}
            >
              {t("AccountDashboard")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-allreviews`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-allreviews`}
            >
              All reviews
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-products` ||
                location.pathname.includes("admin-product")
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-products`}
            >
              New products
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-webshop` ||
                location.pathname.includes("admin-one-webshop")
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-webshop`}
            >
              Webshop
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-brands` ||
                location.pathname.includes("admin-brand")
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-brands`}
            >
              Brands
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-merchantusers` ||
                location.pathname.includes("admin-merchantuser")
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-merchantusers`}
            >
              Merchant users
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-vouchers-transaction`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-vouchers-transaction`}
            >
              Vouchers transaction
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-subscriptions`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-subscriptions`}
            >
              Subscriptions
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === `/${t("lang")}/admin-support`
                  ? "active"
                  : ""
              }
              to={`/${t("lang")}/admin-support`}
            >
              Support
            </Link>
          </li>
        </ul>
        <button className="btn btn-secondary" onClick={handleLogout}>
          Logout
        </button>
      </nav>
    </div>
  );
}

export default NavListAdmin;
