function EmailUpdateConfirm() {
  return (
    <>
      <section className="py-5 form valid">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-11 col-lg-8 py-4 px-lg-4 mx-auto result-ok">
              <div className="form__header">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-12 col-md-8 col-xl-9 text-center">
                    <img
                      className="align-self-center mb-4"
                      src="http://dev.veritatrust.com/assets/img/coin.png"
                      alt="Product name"
                    />
                    <p className="lead text-green">
                      Your email has been updated successfully!
                    </p>
                    <p className="">
                      Last step: Please check your mailbox to confirm your email
                      address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailUpdateConfirm;
