import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";
import SearchProduct from "../SearchProduct";

function SearchBar({ propsLang }) {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const axios = useAxios();

  const [searchclass, setSearchClass] = useState("dropdown-menu");
  const { t } = useTranslation();
  const { lang } = useParams();

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const handleClick = (event) => {
    if (event.target.closest(".search-bar")) {
      setSearchVisible(!searchVisible);
    }
  };

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm.length >= 3) {
      axios
        .get(`/search?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm.length >= 3) {
      setSearchClass("dropdown-menu show");
    } else {
      setSearchClass("dropdown-menu");
    }
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div className="header-search">
        <div className="search-bar">
          <form
            className="search"
            role="search"
            action={"/" + window.localStorage.getItem("langPage") + "/search"}
            autocomplete="off"
            onClick={handleClick}
          >
            <input
              className="form-control"
              placeholder={t("HeaderSearchbar")}
              id="search-1"
              data-bs-toggle="modal"
              data-bs-target="#SearchModal"
              type="search"
              name="q"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className="overlay_dark"></div>
          </form>
        </div>

        <div className="dropdown search">
          <ul className={searchclass}>
            {searchResults.slice(0, 7).map((product, index) => (
              <li key={index} className="search_item">
                <SearchProduct
                  product_name={product.product_name}
                  product_id={product.id}
                  type={product.type}
                  propsNbReviews={product.ReviewsNumber}
                  propsRM={product.ReviewMean}
                  propsLocation="searchBar"
                  propsLang={t("lang")}
                />
              </li>
            ))}
            {searchResults.length > 6 && (
              <div className="px-1 py-1">
                <a
                  href={`/${t("lang")}/search?q=${searchTerm}`}
                  className=" btn btn-primary w-100"
                >
                  {t("Display_all_results")}
                </a>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default SearchBar;
