import { createPopper } from "@popperjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import NoPicture from "../../assets/no-picture.png";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";

import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { connect } from "react-redux";
import { setMerchantProfileValue, setSharedValue } from "../../actions/types";

function MerchantDiv({
  propsRM,
  setSharedValue,
  merchantProfileValue,
  setMerchantProfileValue,
}) {
  let { website } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [merchantdata, setmerchantdata] = useState(null);

  const [selectedCategoriesMsg, setSelectedCategoriesMsg] = useState([
    merchantdata?.category_1,
    merchantdata?.category_2,
    merchantdata?.category_3,
  ]);
  const [categories, setCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);

  const axios = useAxios();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const ratingName = ["Very bad", "Bad", "Good", "Very good", "Excellent"];
  let srcimg = star1;
  let srcvalue = "star" + parseInt(propsRM).toString();
  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
    default:
  }

  useEffect(() => {
    axios
      .get(`/merchant_profiles/${website}`)
      .then((response) => {
        setSearchResults(response.data);
        setSharedValue(response.data.ReviewsNumber);
        setmerchantdata(response.data);
        return axios.get(`/merchant_profiles/${website}`);
      })
      .then((response) => {
        setmerchantdata(response.data);
        setMerchantProfileValue(response.data);
        setSelectedCategoriesMsg([
          response.data.category_1,
          response.data.category_2,
          response.data.category_3,
        ]);
        const categoriesMsg = [
          response.data.category_1,
          response.data.category_2,
          response.data.category_3,
        ];
        return Promise.all(
          categoriesMsg.map((categoryId) => {
            if (categoryId) {
              return axios.get(
                `/data/categories/get-category-id/${categoryId}`
              );
            } else {
              return Promise.resolve(null);
            }
          })
        );
      })
      .then((categoriesInfo) => {
        const filteredCategoriesInfo = categoriesInfo.filter(
          (categoryInfo) => categoryInfo !== null
        );
        if (window.localStorage.getItem("langPage") === "en") {
          setCategories(
            filteredCategoriesInfo.map(
              (response) => response.data.category_name
            )
          );
        } //[`category_name_${window.localStorage.getItem("langPage")}`]
        else {
          setCategories(
            filteredCategoriesInfo.map(
              (response) =>
                response.data[
                  `category_name_${window.localStorage.getItem("langPage")}`
                ]
            )
          );
        }
        setCategoriesId(
          filteredCategoriesInfo.map(
            (response) => response.data.google_category_id
          )
        );
      })
      .catch((error) => {
        //.error(error);
      });
  }, [, lang]);

  const showPopover = () => {
    setPopoverVisible(true);
  };

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  const updatePopover = () => {
    if (target && popover) {
      createPopper(target, popover, {
        placement: "top",
      });
    }
  };
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [popover, setPopover] = useState(null);
  const popoverRef = useRef(null);

  const handleTargetRef = (element) => {
    setTarget(element);
    updatePopover();
  };

  const handlePopoverRef = (element) => {
    setPopover(element);
    updatePopover();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        hidePopover();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  return (
    <section className="profil-header">
      <div className="container">
        <div className="row mt-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="flaticon-home"></i>
                  {t("ReviewMerchantPageWriteHome")}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/c">{t("ReviewMerchantPageCategory")}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link
                  className="badge text-white bg-primary me-2"
                  to={
                    "/" +
                    window.localStorage.getItem("langPage") +
                    "/c/" +
                    categoriesId[0] +
                    "-" +
                    categories[0]
                  }
                >
                  {categories[0]}
                </Link>

                <Link
                  className="badge text-white bg-primary me-2"
                  to={
                    "/" +
                    window.localStorage.getItem("langPage") +
                    "/c/" +
                    categoriesId[1] +
                    "-" +
                    categories[1]
                  }
                >
                  {categories[1]}
                </Link>
                <Link
                  className="badge text-white bg-primary"
                  to={
                    "/" +
                    window.localStorage.getItem("langPage") +
                    "/c/" +
                    categoriesId[2] +
                    "-" +
                    categories[2]
                  }
                >
                  {categories[2]}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <div
          className="row d-flex align-items-center mb-3 aos animated aos-init aos-animate"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <div className="col-lg-8 pt-2 pb-1 pb-lg-5 d-grid info-mark">
            <div className="logo">
              {merchantdata && (
                <img src={merchantdata.logo || NoPicture} alt="merchant logo" />
              )}
            </div>
            <div className="brand">
              {true && (
                <>
                  {merchantdata && <h1>{merchantdata.name}</h1>}

                  <small className="text-muted">
                    <strong>{searchResults.ReviewsNumber}</strong>{" "}
                    {t("ReviewMerchantPageReviews")} •{" "}
                    {searchResults.ReviewMean > 0
                      ? ratingName[parseInt(searchResults.ReviewMean) - 1]
                      : ""}
                  </small>
                </>
              )}
              <div className="form__header__note">
                <div className="form__star mb-3 d-flex justify-content-start mx-auto ms-md-0">
                  <img
                    alt="Noté sur 5 étoiles"
                    src={srcimg}
                    width="120"
                    height="70"
                  />
                  {true && (
                    <p className="note lead my-auto ms-3 text-success">
                      {searchResults.ReviewsNumber > 0
                        ? searchResults.ReviewMean.toFixed(1)
                        : ""}
                    </p>
                  )}
                </div>
                {merchantdata?.status === "1" && (
                  <p
                    className="certified small bg-primary text-white d-inline py-2 px-3 rounded-3 shadow me-2"
                    data-bs-html="true"
                    data-bs-trigger="focus"
                    data-bs-toggle="popover"
                    data-bs-placement="bottom"
                    data-bs-custom-class="veritatrust-popover"
                    ref={handleTargetRef}
                    onClick={showPopover}
                  >
                    <i className="flaticon-check me-1"></i>{" "}
                    {t("ReviewMerchantPageVerifiedCompany")}
                  </p>
                )}
                <a
                  className="certified small bg-success text-white d-inline py-2 px-3 rounded-3 shadow"
                  href={"/" + t("lang") + "/evaluate/" + website}
                >
                  {t("ReviewMerchantPageWriteReview")}
                </a>
                {popoverVisible && (
                  <div
                    ref={handlePopoverRef}
                    className="popover veritatrust-popover"
                    data-bs-placement="bottom"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ zIndex: 1000 }}
                  >
                    <div className="popover-body">
                      <p>
                        La transparence nous tient à cœur. Pour aider les
                        consommateurs à prendre des décisions d'achats plus
                        éclairées, nous affichons les informations que cette
                        entreprise a choisi de vérifier.
                      </p>
                      <p>
                        Toutes les entreprises qui ont revendiqué leur profil
                        Trustpilot peuvent suivre une procédure de vérification
                        pour obtenir ce badge. <a href="#">En savoir plus</a>
                      </p>
                      <ul>
                        <li>Preuve d'identité</li>
                        <li>Informations de contact</li>
                        <li> Propriété du nom de domaine</li>
                        <li>enregistré Compte bancaire</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="link-brand">
              <a
                href={"https://" + website}
                title={website}
                className="link"
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <p className="lead p-3 pe-5 rounded-4">
                  <i className="flaticon-global-network me-1"></i> {website}
                  <span className="mt-1">
                    {t("ReviewMerchantPageVisitTheWebsite")}
                  </span>
                  <i className="flaticon-fleche-angulaire-pointant-vers-la-droite"></i>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  sharedValue: state.auth.sharedValue,
  merchantProfileValue: state.auth.merchantProfileValue,
});

const mapDispatchToProps = {
  setSharedValue,
  setMerchantProfileValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDiv);
