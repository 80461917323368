import React from "react";
import { Link } from "react-router-dom";

function VoucherItem({ website, urlImage, voucherId }) {
  return (
    <div class="col-sm-6 col-lg-4 mb-3">
      <div class="card text-center d-flex flex-column align-items-center shadow position-relative">
        <div class="w-100 d-flex flex-column align-items-center gap-1">
          <div class="logo p-2 gap-4 mt-2">
            <img src={urlImage} alt="Metamask" height="75" width="75" />
          </div>
          <Link
            class="lien_global mb-2"
            to={
              "/" +
              window.localStorage.getItem("langPage") +
              "/account/vouchers/" +
              voucherId
            }
          >
            <p class="">{website}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VoucherItem;
