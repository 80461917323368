import { useAxios } from "../../utils/hook/useAxios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
// import AOS from "aos";
// import "aos/dist/aos.css";
import QRCodeImage from "../QRCodeImage";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function SecureAccount() {
  const axios = useAxios();
  const { t } = useTranslation();

  const [qrCode, setQrCode] = useState(null);
  const [token, setToken] = useState(null);
  const [otpauthUrl, setotpauthUrl] = useState(null);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");
  const [base32Secret, setBase32Secret] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState(
    t("AccountProfileCopyFullCode")
  );
  const [isenabled, setIsenabled] = useState(0);
  const [id, setId] = useState(0);
  const { i18n } = useTranslation();
  const { lang } = useParams();

  //Définition de state pour la formulaire
  const [values, setValues] = useState({
    userId: "",
    token: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleEnable2fa = (userid) => {
    axios
      .post("/enable-2fa", { userId: userid })
      .then((res) => {
        //.log(res.data.qrcode);
        setQrCode(res.data.qrcode);
        setToken(res.data.secret);
        setBase32Secret(res.data.secret);
        setotpauthUrl(res.data.url);

        // Set the QR code data URL to the state variable
        setQrCodeDataUrl(qrCodeDataUrl);

        //.log(res.data.url);
      })
      .catch((err) => {
        //.log(err);
      });
  };

  const handleDisable2fa = (userid) => {
    axios
      .post("/disable-2fa", { userId: userid })
      .then((res) => {
        //.log(res.data.qrcode);
        setIsenabled(false);
        window.location.reload();
      })
      .catch((err) => {
        //.log(err);
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(token);
    setCopyButtonText(t("AccountProfileCopiedCode"));
  };

  useEffect(() => {
    let fetchUserData = async () => {
      const response = await axios.get("/");
      //.log(response);
      if (response.data.twoFactorAuthEnabled)
        setIsenabled(response.data.twoFactorAuthEnabled);
      if (response.data.id) setId(response.data.id);
    };
    fetchUserData();
  }, [isenabled]);

  // Comportement submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      userId: id,
      token: values.token,
    };

    axios
      .post("/verify-otp", dataUser)
      .then((res) => {
        //.log(res);

        if (res.message === "failure") {
          toast.success("Invalid Code", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("2FA Authentication activated successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsenabled(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        //.log(err);
        toast.error("Invalid Code", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    i18n.changeLanguage(lang);

    // AOS.init({
    //   duration: 1000,
    // });
  }, []);

  return (
    <>
      <div
        className="personal-data mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="title">
          <p className="lead me-auto">{t("AccountProfileSecure")}</p>
        </div>
        <div className="row">
          <div className="col-md-10">
            <p>{t("AccountProfileSecureDescription")}</p>
          </div>
          <div className="col-md-2 text-end icones">
            {isenabled === 0 ? (
              <button
                type="button"
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#autheconnector"
                onClick={() => handleEnable2fa(id)}
              >
                {t("AccountProfileSecureActivateButton")}
              </button>
            ) : (
              <>
                <i className="flaticon-cyber-security text-success"></i>
                <button
                  className="btn text-warning"
                  title="Remove your authenticator connexion"
                  onClick={() => handleDisable2fa(id)}
                >
                  <i className="flaticon-remove"></i>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {isenabled === 0 && (
        <>
          <div
            className="modal fade"
            id="autheconnector"
            tabindex="-1"
            aria-labelledby="modaltitle"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="modaltitle">
                    {t("AccountProfileAuthenticator")}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="text-center">
                      <p className="lead mb-1">
                        <strong>1</strong> - {t("AccountProfileScanQrCode")}
                      </p>
                      {/* <img className="img-fluid mb-3" src={qrCode} alt="qrcode" /> */}
                      <QRCodeImage base32Secret={base32Secret} />

                      <p className="lead mb-1">{t("AccountProfileCopyCode")}</p>
                      <p className="mb-1 text-break col-8 mx-auto text-success">
                        {token != null ? token : ""}
                      </p>

                      <div>
                        <button
                          className="btn btn-primary mb-1"
                          onClick={handleCopy}
                        >
                          {copyButtonText}
                        </button>
                      </div>
                      <hr className="my-4 col-4 mx-auto" />
                      <p className="lead mb-1">
                        <strong>2</strong> -{" "}
                        {t("AccountProfileEnterPrivateCode")}
                      </p>
                      <form action="" onSubmit={handleSubmit}>
                        <input
                          className="form-control form-control-lg mb-2"
                          type="text"
                          class="form-control"
                          id="token"
                          title="token"
                          placeholder="token *"
                          name="token"
                          required=""
                          value={values.token}
                          onChange={handleChange}
                        />
                        <button
                          type="submit"
                          class="btn-block btn btn-success text-white btn-lg"
                          id="signinbutton"
                        >
                          {t("AccountProfilePrivateCode")}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SecureAccount;
