import React, { useState } from "react";
import Confetti from "react-confetti";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

function ValidReview() {
  const [colors, setColors] = useState([
    "#02a68a",
    "#789456",
    "#333333",
    "#002d6b",
  ]);
  /* const [userId, setUserId] = useState("");
    const {id} = useParams();
    const [url, setUrl] = useState("");

    const axios = useAxios();
    const navigate = useNavigate();
    useEffect(() => {

        axios.get('/data/invitationdata/?invitationUrl=api.veritatrust.com/mreview/'+id).then((res) => {
    
          if(res == null)
                navigate('/404', { replace: true }) 
 
          console.log(res)
          
          setUserId(res.data.invitation_url_complete.split('?')[1].split('&')[1].split('=')[1]);
          setUrl("https://api.veritatrust.com/user-dashboard/"+res.data.invitation_url_complete.split('?')[1].split('&')[1].split('=')[1]);
            
          }).catch((err) => {
              console.log(err)
         }) 
      
         
    }, []); */

  return (
    <section className="py-5 form valid">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-11 col-lg-8 py-3 mx-auto position-relative">
            <ul className="step-reviews nav text-center">
              <li className="nav-item w-33 step-1 done">
                <span className="badge rounded-circle">1</span>
                <p className="mb-0">
                  Merchant <br />
                  review
                </p>
              </li>
              <li className="nav-item w-33 step-2 done">
                <span className="badge rounded-circle">2</span>
                <p className="mb-0">
                  Product <br />
                  review
                </p>
              </li>
              <li className="nav-item w-33 step-3 active">
                <span className="badge rounded-circle">3</span>
                <p className="mb-0" aria-current="page">
                  Submitted
                  <br />
                  reviews
                </p>
              </li>
            </ul>
            <div id="line">
              <div id="line-progress"></div>
            </div>
          </div>
          <div className="col-11 col-lg-8 py-4 px-lg-4 mx-auto result-ok">
            <div className="form__header">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-xl-9 text-center">
                  <img
                    className="align-self-center mb-4"
                    src="http://dev.veritatrust.com/assets/img/coin.png"
                    alt="Product name"
                  />
                  <p className="lead text-green">
                    Congratulations!
                    <br />
                    Your review is validated
                  </p>
                  <p className="">
                    You just got rewarded with <strong> tokens 🥇</strong>.
                  </p>
                  {/**   <p className="lead text-green">Get more reward and review more.</p>
                                <div class="btn-group" role="group" aria-label="Add review">
                                    <a href="#" class="btn btn-outline-success d-none">Write new review</a>
                                    <a href="#" class="btn btn-outline-success">Check my rewards</a>
                                    </div>
                            */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confetti numberOfPieces="200" colors={colors} tweenDuration="5000" />
    </section>
  );
}

export default ValidReview;
