import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import UserBadge from "../../components/UserBadge";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

export const UserValueContext = createContext();

function BadgePage() {
  const axios = useAxios();
  const [reviewNumber, setReviewNumber] = useState(0);
  const [levelclass, setLevelclass] = useState(
    "card text-center d-flex flex-column level0"
  );
  const [UserRewardData, setUserRewardData] = useState({
    fName: "",
    lName: "",
    totalReward: 0,
    approuvedRaward: 0,
    pendingReward: 0,
    levelAccount: 0,
    fLevelAccount: 1,
    reviewNb: 0,
  });

  const [user, setUser] = useState({});
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
      }
      setUser(response.data);
      const res = await axios.get("/lreview-dashboard/" + response.data["id"]);

      if (res.data.length > 0) {
        // setTotalRewards(12);
        setReviewNumber(
          res.data.filter((item) => item.status === "published").length
        );
        const rewardData = {
          fName: res.data[0].first_name,
          lName: res.data[0].last_name,
          totalReward: res.data[0].total,
          approuvedRaward: res.data[0].total_published,
          pendingReward: res.data[0].total_pending,
          levelAccount: res.data[0].level_account,
          fLevelAccount: res.data[0].level_account + 1,
          reviewNb: res.data.length,
        };

        setUserRewardData(rewardData);

        switch (res.data[0].level_account) {
          case 0:
            setLevelclass("card text-center d-flex flex-column level0");
            break;
          case 1:
            setLevelclass("card text-center d-flex flex-column level1");
            break;
          case 2:
            setLevelclass("card text-center d-flex flex-column level2");
            break;
          case 3:
            setLevelclass("card text-center d-flex flex-column level3");
            break;
          case 4:
            setLevelclass("card text-center d-flex flex-column level4");
            break;
          default:
        }
      }
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <UserValueContext.Provider value={user}>
      <DashboardDiv titlepage={t("AccountBadgesTitle")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <UserBadge
                    data={UserRewardData}
                    dataLenght={reviewNumber}
                  ></UserBadge>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserValueContext.Provider>
  );
}
const mapStateToProps = (state) => ({
  totalRewards: state.auth.totalRewards,
});

export default connect(mapStateToProps)(BadgePage);
