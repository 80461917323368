import React, { createContext, useEffect, useState } from "react";
import DashboardDiv from "../../components/DashboardDiv";
import NavList from "../../components/NavList";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import SupportMessageItem from "../../components/SupportMessageItem";
import "../../styles/style.css";

export const SharedValueContext = createContext();

function SupportPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { i18n } = useTranslation();
  const { lang } = useParams();

  const { t } = useTranslation();
  const axios = useAxios();
  const [allTransactions, setAllTransactions] = useState([]);
  const [visibleTransactions, setVisibleTransactions] = useState(10); // Nombre de transactions initialement <visibles></visibles>
  let socket;

  const loadMore = () => {
    setVisibleTransactions(
      (prevVisibleTransactions) => prevVisibleTransactions + 5
    ); // Charger plus de transactions (par exemple, 5 de plus)
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
    let fetchReviews = async () => {
      let response = await axios.get("/");

      socket = io("https://api.veritatrust.com"); // Remplacez par l'URL correcte

      socket.on("connect", () => {
        console.log("WebSocket Client Connected successfully lorem ipsum ");
        console.log("Socket ID:", socket.id);
        socket.emit("register", response.data["id"]);
        // Écouter l'événement "notification" pour recevoir les notifications
      });

      socket.on("supportmessage", (supportmessage) => {
        setAllTransactions((prevNotifications) => [
          supportmessage,
          ...prevNotifications,
        ]);
        console.log(supportmessage);
      });

      const resp = await axios.get(
        "/supportmessages/userId/" + response.data["id"]
      );
      setAllTransactions(resp.data.sort((a, b) => b.id - a.id));
    };
    fetchReviews();

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DashboardDiv titlepage="Support" />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="support mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="title">
                      <p className="lead me-auto">{t("AccountSupportTitle")}</p>
                    </div>
                    <form className="form__content">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          aria-label="Select support service"
                        >
                          <option selected="">
                            {t("AccountSupportScrollingMenu1")}
                          </option>
                          <option value="1">
                            {t("AccountSupportScrollingMenu2")}
                          </option>
                          <option value="2">
                            {t("AccountSupportScrollingMenu3")}
                          </option>
                          <option value="3">
                            {t("AccountSupportScrollingMenu4")}
                          </option>
                          <option value="4">
                            {t("AccountSupportScrollingMenu5")}
                          </option>
                          <option value="5">
                            {t("AccountSupportScrollingMenu6")}
                          </option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder={t("AccountSupportFormTitle")}
                          aria-label="Subject"
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="10"
                          placeholder={t("AccountSupportFormMessage")}
                          aria-label="Your message for support"
                        ></textarea>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12 col-md-4">
                          <label className="add__photo rounded-3 border w-100 text-center d-flex">
                            <div className="m-auto d-flex align-items-center d-lg-block">
                              <i className="flaticon-galerie"></i>
                              <div className="">
                                + {t("AccountSupportFormFile")}
                              </div>
                              <input
                                accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                                type="file"
                                className="d-none"
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <button type="submit" className="btn btn-primary w-100">
                          {t("AccountSupportFormSubmit")}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="claim__system support mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="title">
                      <p className="lead me-auto">
                        {t("AccountSupportTicketsTitle")}
                      </p>
                    </div>
                    <table className="table mb-3 table__message table-hover">
                      <thead>
                        <tr>
                          <th className="claim-date" scope="col">
                            {t("AccountSupportTicketsDate")}
                          </th>
                          <th className="claim-type" scope="col">
                            {t("AccountSupportTicketsSubject")}
                          </th>
                          <th className="claim-mount" scope="col">
                            {t("AccountSupportTicketsTypeIssue")}
                          </th>
                          <th className="claim-statut" scope="col">
                            {t("AccountSupportTicketsStatus")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {allTransactions
                          .slice(0, visibleTransactions)
                          .map((report) => (
                            <SupportMessageItem
                              propsData={report}
                            ></SupportMessageItem>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SupportPage;
