import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function AdminCreateSelectSubCategoryInput({
  handleSelectSubCategory,
  startReviewButtonDisabled,
  category_parent_idSelected,
  resetInput,
  SubcategoryDefault,
}) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  const [searchTerm, setSearchTerm] = useState(SubcategoryDefault);
  const [show, setShow] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [categoryIdSelected, SetCategoryIdSelected] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    if (resetInput) {
      setSearchTerm("");
      // Réinitialise l'état de l'input en cas de resetInput
    }
  }, [resetInput]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);

    // Send a request to the server to search for categories matching the input value
    if (inputValue.length >= 1) {
      axios
        .get(
          `/search-categories-item/${category_parent_idSelected}?q=${inputValue}&lang=${lang}`
        )
        .then((response) => {
          setSearchResults(response.data);
          setShow(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSearchResults([]);
      setShow(false);
    }
  };

  const handleSelectCategoryInput = (suggestion) => {
    setSearchResults([]);
    setSearchTerm(
      suggestion[`category_name_${lang}`] || suggestion.category_name
    );
    handleSelectSubCategory(suggestion);
    SetCategoryIdSelected(suggestion.google_category_id);
    setShow(false);
  };

  const axios = useAxios();

  return (
    <>
      <div className="mb-md-3">
        <label htmlFor="categoryProduct" className="col-form-label">
          {t("AddReviewForBetaTesterSubCat")}
          <sup>*</sup>
        </label>
        <div className="dropdown">
          <input
            type="text"
            className="form-control"
            id="categoryProduct"
            required
            name="categoryProduct"
            onChange={handleInputChange}
            placeholder={t("AddReviewForBetaTesterPlaceHolderSubcat")}
            value={searchTerm}
            autoComplete="off"
            disabled={startReviewButtonDisabled}
            onBlur={() => {
              setTimeout(() => setShow(false), 200);
            }}
            onFocus={() => {
              setShow(true);
            }}
          />
          {searchTerm.length >= 2 && (
            <ul className={show ? "dropdown-menu show" : "dropdown-menu"}>
              {searchResults.map((suggestion, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => handleSelectCategoryInput(suggestion)}
                  >
                    {suggestion[`category_name_${lang}`] ||
                      suggestion.category_name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminCreateSelectSubCategoryInput;
