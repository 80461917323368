import { useContext, useEffect, useState } from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
//import AOS from "aos";
// import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { setTotalRewards } from "../../actions/types";
import { SharedValueContext } from "../../pages/RewardPage";

function Rewards() {
  const NEWBIE = 5;
  const BRONZE = 20;
  const SILVER = 50;
  const GOLD = 99;
  const [UserRewardData, setUserRewardData] = useState({
    totalReward: 0,
    approuvedRaward: 0,
    pendingReward: 0,
    levelAccount: 1,
    fLevelAccount: 2,
    reviewNb: 0,
  });

  const [levelname, setLevelName] = useState("Newbie");
  const [levelclass, setLevelclass] = useState(
    "card text-center d-flex flex-column level0"
  );
  const [reviewNumberNextLevel, setReviewNumberNextLevel] = useState(20);
  const { sharedValue, walletId, user } = useContext(SharedValueContext);
  const [ConversionCurrency, setConversionCurrency] = useState(0);

  const axios = useAxios();
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  // useEffect(() => {
  //   AOS.init({
  //     //duration: 1000,
  //   });
  // });

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");
      let USERID = null;
      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        USERID = retrievedObject["id"];
        response.data = retrievedObject;
      }
      const res = await axios.get("/lreview-dashboard/" + response.data["id"]);
      const res2 = await axios.get(
        "/CashFlowRewardsRoutes/" + response.data["id"]
      );

      if (res.data.length > 0 && res2.data) {
        const rewardData = {
          totalReward: res2.data.TotalCashflow + res2.data.TotalAmountPending,
          approuvedRaward: res2.data.TotalCashflow,
          pendingReward: res2.data.TotalAmountPending,
          levelAccount: res.data[0].level_account,
          fLevelAccount: res.data[0].level_account + 1,
          reviewNb: res.data.length,
        };

        setUserRewardData(rewardData);
        // setTotalRewards(res.data[0].total_published);

        //.log(UserRewardData);

        switch (res.data[0].level_account) {
          case 0:
            setLevelName("Newbie");
            break;
          case 1:
            setLevelName("Bronze");
            break;
          case 2:
            setLevelName("Silver");
            break;
          case 3:
            setLevelName("Gold");
            break;
          case 4:
            setLevelName("Platinum");
            break;
          default:
        }

        switch (res.data[0].level_account) {
          case 0:
            setLevelclass("card text-center d-flex flex-column level0");
            break;
          case 1:
            setLevelclass("card text-center d-flex flex-column level1");
            break;
          case 2:
            setLevelclass("card text-center d-flex flex-column level2");
            break;
          case 3:
            setLevelclass("card text-center d-flex flex-column level3");
            break;
          case 4:
            setLevelclass("card text-center d-flex flex-column level4");
            break;
          default:
        }

        switch (res.data[0].level_account) {
          case 0:
            setReviewNumberNextLevel(NEWBIE - res.data[0].total_published);
            break;
          case 1:
            setReviewNumberNextLevel(BRONZE - res.data[0].total_published);
            break;
          case 2:
            setReviewNumberNextLevel(SILVER - res.data[0].total_published);
            break;
          case 3:
            setReviewNumberNextLevel(GOLD - res.data[0].total_published);
            break; // il te reste reviewNumberNexLevel pour atteindre le niveau supérieur
          default:
        }
      }

      try {
        // Remplacez VOTRE_CLÉ_API par votre clé API CoinMarketCap
        const response = await axios.get("/coinmarketcap");
        const data = response.data;
        const conversionCurrencyValue = data.data.HMT.quote.USD.price;
        setConversionCurrency(conversionCurrencyValue);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchReviews();
  }, []);

  return (
    <>
      <div
        className="personal-data mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="rewards row">
          <div className="col-md-4 mb-3 mb-md-0 order-4 order-md-1">
            <div className={levelclass}>
              <div className="icone wiggle">
                <i className="flaticon-logo-level"></i>
              </div>
              <h3 className="card__title">{levelname}</h3>
              <p className="mt-auto border-top pt-1 mb-1">
                {t("AccountRewardsWidgetLevel")}
                {t("AccountRewardsWidgetReviews", {
                  NumberReviews: reviewNumberNextLevel,
                })}
              </p>
            </div>
          </div>
          <div className="col-6 col-md-4 mb-3 mb-md-0 order-2">
            <div className="card text-center d-flex flex-column approved">
              <p className="state">{t("AccountRewardsWidgetApproved")}</p>
              <div className="icone">
                <i className="flaticon-cube-3d"></i>
              </div>
              <h3 className="card__title">
                {UserRewardData.totalReward === 0
                  ? 0
                  : UserRewardData.approuvedRaward}{" "}
                VT
              </h3>
              {UserRewardData.totalReward === 0 && (
                <p className="mt-auto border-top pt-1 mb-1">
                  (≃ 0.0 HMT / $0.0)
                </p>
              )}

              {UserRewardData.totalReward > 0 && (
                <p className="mt-auto border-top pt-1 mb-1">
                  (≃ {sharedValue} HMT / ${sharedValue * ConversionCurrency})
                </p>
              )}
            </div>
          </div>
          <div className="col-6 col-md-4 mb-3 mb-md-0 order-3">
            <div className="card text-center d-flex flex-column pending">
              <p className="state">{t("AccountRewardsWidgetPending")}</p>
              <div className="icone">
                <i className="flaticon-time-left"></i>
              </div>
              <h3 className="card__title">
                {UserRewardData.totalReward === 0
                  ? 0
                  : UserRewardData.pendingReward}{" "}
                VT
              </h3>
              {UserRewardData.totalReward === 0 && (
                <p className="mt-auto border-top pt-1 mb-1">
                  (≃ 0,0 HMT / $0.00)
                </p>
              )}

              {UserRewardData.totalReward > 0 && (
                <p className="mt-auto border-top pt-1 mb-1">
                  (≃ {UserRewardData.pendingReward} HMT / $
                  {UserRewardData.pendingReward * ConversionCurrency})
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  totalRewards: state.auth.totalRewards,
});

const mapDispatchToProps = {
  setTotalRewards,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
