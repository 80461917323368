import React, { useState } from "react";
import Filtering from "../../components/Filtering";
import ProductDiv from "../../components/ProductDiv";
import ProductReview from "../../components/ProductReview";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";
// import { useDispatch } from "react-redux";
// import { setNbReviews, setRM } from "../../actions/types";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((review, index) => (
          <div>
            <ProductReview key={index} data={review} />
          </div>
        ))}
    </>
  );
}

function PaginationComponent({ itemsPerPage, items }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    // console.log(currentItems);
    setItemOffset(newOffset);
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("page", selectedPage + 1);
    const newUrl = window.location.pathname + "?" + queryParams.toString();
    window.history.pushState({}, "", newUrl);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <nav className="mt-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination pagination-lg justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          disabledLinkClassName="disabled"
          activeLinkClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
        />
      </nav>
    </>
  );
}

function ReviewProduct(sharedValue) {
  const { product_name } = useParams();
  const [stars, setStars] = useState(0);
  const [reviewsNumber, setReviewNumber] = useState(0);
  const [rm, setRm] = useState("");
  const axios = useAxios();
  // const dispatch = useDispatch();

  const [ratingProps, setRatingProps] = useState({
    excellent: 100,
    very_good: 0,
    good: 0,
    bad: 0,
    very_bad: 0,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setStars(params.get("stars"));
    axios
      .get(`/products/getone/${product_name.toString().split("-")[0]}`)
      .then((response) => {
        axios
          .put(`/products/admin-update/${response.data.id}`, {
            PageViewsNb: response.data.PageViewsNb + 1,
          })
          .then((response) => {})
          .catch((error) => {
            // Gérez les erreurs selon vos besoins
          });
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour du produit :", error);
        // Gérez les erreurs selon vos besoins
      });
  }, []);

  const [reviews, setReviews] = useState([]);
  const [reviewsCopy, setReviewsCopy] = useState([]);
  const [ratings, setRatings] = useState("");

  useEffect(() => {
    let fetchReviews = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ratings = urlParams.get("stars");

      // console.log("product_name", product_name);

      axios
        .get(
          `/organic-product-review/${
            product_name.toString().split("-")[0]
          }?stars=${ratings || ""}`
        )
        .then((response) => {
          setReviews(response.data);
          setReviewsCopy(response.data);
          setRatingProps(getRatingProprtion(response.data));
          if (response.data.length > 0) {
            setReviewNumber(response.data.length);
            setRm(response.data[0].RM);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchReviews();
  }, [ratings]);

  function getRatingProprtion(datareviews) {
    let proportion = {
      excellent: 0,
      very_good: 0,
      good: 0,
      bad: 0,
      very_bad: 0,
    };

    for (var i = 0; i < datareviews.length; i++) {
      switch (datareviews[i].rating) {
        case 5:
          proportion.excellent = proportion.excellent + 1;
          break;
        case 4:
          proportion.very_good = proportion.very_good + 1;
          break;
        case 3:
          proportion.good = proportion.good + 1;
          break;
        case 2:
          proportion.bad = proportion.bad + 1;
          break;
        case 1:
          proportion.very_bad = proportion.very_bad + 1;
          break;
        default:
      }
    }

    proportion.excellent =
      ((proportion.excellent * 100) / datareviews.length) | 0;
    proportion.very_good =
      ((proportion.very_good * 100) / datareviews.length) | 0;
    proportion.good = ((proportion.good * 100) / datareviews.length) | 0;
    proportion.bad = ((proportion.bad * 100) / datareviews.length) | 0;
    proportion.very_bad =
      ((proportion.very_bad * 100) / datareviews.length) | 0;

    return proportion;
  }

  const [selectedLanguage, setSelectedLanguage] = useState("");
  useEffect(() => {
    // Your useEffect logic here that should be triggered when selectedLanguage changes
    const reviews_copy = reviewsCopy;
    setReviews(
      reviews_copy.filter((review) => review.lang_id == selectedLanguage)
    );

    if (selectedLanguage === "") {
      setReviews(reviewsCopy);
    }
    updateUrlWithLangId(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  function updateUrlWithLangId(langId) {
    // Récupérer l'URL actuelle
    let currentUrl = window.location.href;

    // Créer un nouvel objet URLSearchParams pour gérer les paramètres
    const urlParams = new URLSearchParams(window.location.search);

    // Mettre à jour ou ajouter le paramètre lang_id
    if (langId) {
      urlParams.set("lang_id", langId);
    } else {
      // Si langId est vide, supprimer le paramètre lang_id s'il existe
      urlParams.delete("lang_id");
    }

    // Construire la nouvelle URL
    const paramString = urlParams.toString();
    const newUrl =
      currentUrl.split("?")[0] + (paramString ? "?" + paramString : "");

    // Remplacer l'URL actuelle avec la nouvelle
    window.history.replaceState({ path: newUrl }, "", newUrl);
  }

  return (
    <>
      <ProductDiv propsRM={rm} sharedValue={sharedValue} />

      <section className="bg-success profil-main">
        <div className="container py-2 py-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <Filtering
                onFilterChange={(ratings) => setRatings(ratings)}
                PropsPropotion={ratingProps}
                onLanguageChange={handleLanguageChange}
              />

              <div className="reviews-list">
                <div>
                  <PaginationComponent
                    itemsPerPage={5}
                    items={reviews}
                  ></PaginationComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  sharedValue: state.auth.sharedValue,
});

export default connect(mapStateToProps)(ReviewProduct);
