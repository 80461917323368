import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function DashboardDiv({ titlepage }) {
  const [typeheader, setTypeheader] = useState(null);
  const [subpage, setSubpage] = useState(null);
  useEffect(() => {
    const Url = window.location.href;
    setTypeheader(Url.split("/")[3]);

    if (Url.split("/")[4] === "settings") setSubpage("Profile");
    else if (Url.split("/")[4] === "rewards") setSubpage("Rewards");
    else if (Url.split("/")[4] === "reviews") setSubpage("Reviews");
    else if (Url.split("/")[4] === "community") setSubpage("Community");
    else if (Url.split("/")[4] === "badges") setSubpage("Badges");
    else if (Url.split("/")[4] === "support") setSubpage("Support");
    else if (Url.split("/")[4] === "faq") setSubpage("FAQ");
    else if (Url.split("/")[4] === "notifications") setSubpage("Notifications");
    else setSubpage("Dashboard");
  });
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <section className="py-lg-5">
      <div className="container user_profil">
        <div className="row d-flex align-items-center">
          <div className="col-11 pt-lg-3 py-1 mx-auto">
            <h1 className="display-6 text-primary">{titlepage}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 mb-md-3">
                <li className="breadcrumb-item">
                  <a href="./">
                    <i className="flaticon-home"></i> {t("AccountBreadcrumb1")}
                  </a>
                </li>
                <li className="breadcrumb-item">{t("AccountBreadcrumb2")}</li>

                <li className="breadcrumb-item active" aria-current="page">
                  {subpage}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardDiv;
