import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import vtcoin from "../../assets/vt-coin.png";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function LastReview({ data, index }) {
  let srcvalue = "star" + data.rating.toString();
  let srcimg = star1;
  let statusClass = "pending";
  let statusSymbolClass = "flaticon-time-left flaticon-pending";
  let classBadgeReview = "badge bg-success";
  const [merchantName, setMerchantName] = useState("");
  const axios = useAxios();

  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;

    default:
  }

  if (data.statu === "pending") {
    statusClass = "pending";
    statusSymbolClass = "flaticon-time-left flaticon-pending";
  } else if (data.statu === "deleted" || data.statu === "failed") {
    statusClass = "rejected";
    statusSymbolClass = "flaticon-cross-button flaticon-rejected";
  } else if (data.statu === "moderation") {
    statusClass = "complete";
    statusSymbolClass = "flaticon-time-left flaticon-complete";
  } else {
    statusClass = "complete";
    statusSymbolClass = "flaticon-cube-3d flaticon-complete";
  }

  if (data.review_type === "merchant_review") {
    classBadgeReview = "badge bg-primary";
  } else {
    classBadgeReview = "badge bg-success";
  }

  let productRoute = "/productreview/" + data.id;
  let merchantRoute = "/merchantreview/" + data.id;

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      if (data.review_type === "merchant_review") {
        const response = await axios.get(
          "/merchant_profiles/findmerchant/" + data.product_name
        );
        if (response.data?.website.length > 0)
          setMerchantName(response.data?.website);
        else setMerchantName(response.data?.name);
      }
    };
    fetchReviews();
  }, [data.id]);

  return (
    <>
      <tr
        className="aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <td data-label="Date" scope="row">
          <p className="mb-0">
            {moment(data.Created)
              .local(window.localStorage.getItem("langPage"))
              .calendar()}
          </p>
          <button
            className="btn btn-light btn-sm"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + index}
            aria-expanded="false"
            aria-controls={index}
          >
            {t("AccountYourLastReviewsMoreDetails")}
          </button>
        </td>
        <td data-label="Details">
          <div className="d-flex flex-column">
            <p className="mb-2">
              <span>
                <strong>
                  {data.review_type === "product_review"
                    ? data.product_name
                    : merchantName}{" "}
                </strong>
              </span>
              <span className={classBadgeReview}>
                {data.review_type === "merchant_review"
                  ? t("AccountYourLastReviewsTableMerchantReview")
                  : t("AccountYourLastReviewsTableProductReview")}
              </span>
            </p>
            <span className="small d-none">{data.orderId}</span>
            <div className="state_review">
              <img
                className="mb-2 mb-md-0"
                src={srcimg}
                alt="notation"
                width="100"
                height="40"
              />{" "}
              - <span>{data.rating}/5</span>
              <p className="mb-0 position-relative" style={{ float: "right" }}>
                {" "}
                {data.hash_transaction ? (
                  <a
                    className="link-newtab position-relative ml-2 p-2"
                    href={`${data.hash_transaction}`}
                    target="_blank"
                    rel="noreferrer"
                    title={t("PopoverVerifedBlockchainMsg2")}
                  >
                    <span className={statusClass + " position-relative "}>
                      <i className={statusSymbolClass}></i> {data.status}{" "}
                    </span>
                  </a>
                ) : (
                  <span className={statusClass + " ml-2 p-2"}>
                    <i className={statusSymbolClass}></i> {data.status}
                  </span>
                )}{" "}
                {data.nftTransaction && (
                  <a
                    className="link-newtab position-relative ml-2 p-2"
                    href={`${data.nftTransaction}`}
                    target="_blank"
                    rel="noreferrer"
                    title={t("PopoverVerifedBlockchainMsg2")}
                  >
                    <span className={statusClass + " position-relative "}>
                      <i className="flaticon-nft"></i> {data.status}{" "}
                    </span>
                  </a>
                )}
              </p>
            </div>
            {data.statu === "published" ||
            data.statu === "failed" ||
            data.statu === "nft_created" ||
            data.statu === "nft_failed" ? (
              <Link
                to={
                  data.review_type == "product_review"
                    ? "/" + t("lang") + productRoute
                    : "/" + t("lang") + merchantRoute
                }
              >
                <p className="text-primary mb-2">
                  <strong>
                    {data.title.length > 125
                      ? data.title.substring(0, 125) + "..."
                      : data.title}{" "}
                  </strong>
                </p>
              </Link>
            ) : (
              <p className="text-primary mb-2">
                <strong>
                  {data.title.length > 125
                    ? data.title.substring(0, 125) + "..."
                    : data.title}{" "}
                </strong>
              </p>
            )}
          </div>
          <div className="collapse" id={index}>
            <p className="text-muted">{data.content}</p>
            <div className="row">
              {data.image_video &&
                data.image_video.split(",").map((image, index) => (
                  <div className="col-md-3 mb-2">
                    <img className="img-fluid" src={image} alt="" />
                  </div>
                ))}
            </div>
          </div>
        </td>

        <td data-label="Reward" className="text-right">
          <p className="mb-0 d-flex flex-column align-items-center">
            <span> {data.review_reward} VT</span>
            <img
              className="img-reward"
              src={vtcoin}
              alt="Veritatrust"
              height="30"
              width="30"
            />
          </p>
        </td>
      </tr>
    </>
  );
}

export default LastReview;
