import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";

function DashboardDivAdmin({ titlepage }) {
  const [typeheader, setTypeheader] = useState(null);
  const [subpage, setSubpage] = useState(null);
  const axios = useAxios();
  useEffect(() => {
    const Url = window.location.href;
    setTypeheader(Url.split("/")[4]);

    if (Url.split("/")[5] === "allreviews") setSubpage("All reviews");
    else if (Url.split("/")[5] === "newproducts") setSubpage("New products");
    else if (Url.split("/")[5] === "webshops") setSubpage("Webshops");
    else if (Url.split("/")[5] === "brands") setSubpage("Brands");
    else if (Url.split("/")[5] === "support") setSubpage("Support");
    else if (Url.split("/")[5] === "support") setSubpage("Support");
    else setSubpage("Dashboard");
  });
  const { t } = useTranslation();
  const [user, setUser] = useState(null);

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/admin");
      setUser(response.data);
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <section className="py-lg-5">
      <div className="container user_profil">
        <div className="row d-flex align-items-center">
          <div className="col-11 pt-lg-3 py-1 mx-auto">
            <h1 className="display-6 text-primary">
              {titlepage}
              <span class="badge bg-success moderator">{user?.role}</span>
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 mb-md-3">
                <li className="breadcrumb-item">
                  <a href="./">
                    <i className="flaticon-home"></i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">Dashboard</li>

                <li className="breadcrumb-item active" aria-current="page">
                  {subpage}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardDivAdmin;
